import React, { Fragment, useEffect } from 'react';
import { array, func, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  PAGE_ID_BG_CREDIT_CARD,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import Spinner from 'shared/components/Spinner/simpleSpinner';
import { useConstructor } from '../../../../utilities/hooks';
import MyAccountMobileBackHeader from '../MyAccountPage/MyAccountBackHeader/MyAccountMobileBackHeader';
import { getBGcardForMyAccount } from './actions';
import { getContentForMyAccount, setResolvedPage } from '../../actions';
import { getTitleText } from '../../helpers';
import { RESOLVED_BG_CREDIT_CARD_PAGE } from '../../constants';
import NoBGcard from './NoBGcard/NoBGcard';
import BGcardContainer from './BGcardContainer/BGcardContainer';
import './BgCreditCardPage.scss';

const RenderBgCardContainer = ({ myAccountBGcard }) =>
  !isEmpty(myAccountBGcard) ? <BGcardContainer /> : <NoBGcard />;

const BgCreditCardPage = ({
  getBGcardForMyAccount,
  getContentForMyAccount,
  myAccountBGcard,
  myAccountContent,
  setPageId,
  setResolvedPage,
  isLoading,
}) => {
  const title = getTitleText(
    myAccountContent ? myAccountContent['CREDITCARD-TITLE'] : null,
  );

  useConstructor(() => {
    setPageId(PAGE_ID_BG_CREDIT_CARD);
  });

  useEffect(() => {
    setResolvedPage(RESOLVED_BG_CREDIT_CARD_PAGE);
    getContentForMyAccount();
    getBGcardForMyAccount();
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <Fragment>
      <MyAccountMobileBackHeader title={title} />
      <h1 className="my-account-page__main-header desktop-tablet-only">
        {title}
      </h1>
      {!isEmpty(myAccountContent) ? (
        <RenderBgCardContainer myAccountBGcard={myAccountBGcard} />
      ) : null}
    </Fragment>
  );
};

BgCreditCardPage.propTypes = {
  getBGcardForMyAccount: func.isRequired,
  getContentForMyAccount: func.isRequired,
  myAccountBGcard: array.isRequired,
  myAccountContent: object.isRequired,
  setPageId: func.isRequired,
  setResolvedPage: func.isRequired,
  isLoading: bool.isRequired,
};

const mapStateToProps = state => ({
  myAccountContent: get(state, 'myaccount.myAccountContent', true),
  myAccountBGcard: get(state, 'myaccount.myAccountBGcard', true),
  isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
  isLoading:
    state?.api?.bg_card?.loading || state?.api?.content?.loading || false,
});

const mapDispatchToProps = {
  getContentForMyAccount,
  getBGcardForMyAccount,
  setPageId,
  setResolvedPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BgCreditCardPage);
