import get from 'lodash/get';
import { ecmCanPersonalize } from 'ecm/utilities';
import { types as AppTypes } from './app-actions';

const defaultState = {
  componentInFocus: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case AppTypes.LOSE_FOCUS: {
      const newState =
        state.componentInFocus === action.currentComponentInFocus
          ? ''
          : state.componentInFocus;
      return {
        ...state,
        componentInFocus: newState,
      };
    }
    case AppTypes.PUT_IN_FOCUS: {
      return {
        ...state,
        componentInFocus: action.componentInFocus,
      };
    }
    default:
      return state;
  }
};

const initialUserState = {
  ucid: '',
  rid: '',
  revisitUser: false,
};

export const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case AppTypes.SET_USER:
      return {
        ...state,
        ...action.user,
      };
    case AppTypes.SET_REVISIT_USER:
      return {
        ...state,
        revisitUser: action.revisitUser,
      };
    default:
      return state;
  }
};

const defaultCookieState = {
  enableStubService: false,
  enablePromoPreview: false,
};
export const cookiesReducer = (
  state = defaultCookieState,
  { type, cookies, abTestIds = [] },
) => {
  if (type === AppTypes.COOKIES_RECEIVED) {
    const abTestsWithCookies = {};
    for (let i = 0; i < abTestIds.length; i++) {
      if (cookies[abTestIds[i].abTestCookieName]) {
        abTestsWithCookies[abTestIds[i].abTestCookieName] =
          cookies[abTestIds[i].abTestCookieName];
      }
    }
    return {
      enableStubService: cookies.ENABLE_STUB_SERVICE || false,
      enablePromoPreview:
        cookies.PR_PR === 'true' || cookies.PR_PR === true || false,
      ...abTestsWithCookies,
    };
  }

  return state;
};

export const togglesReducer = (state = {}) => state;

export const serviceTogglesReducer = (state = {}) => state;

const initialSessionState = {
  JSESSIONID: '',
  referer: '',
};

export const sessionReducer = (state = initialSessionState, action) => {
  switch (action.type) {
    case AppTypes.SET_SESSION:
      return {
        ...state,
        ...action.session,
      };
    case AppTypes.PARSE_PERSONALIZE: {
      const personalize = ecmCanPersonalize(action.payload);
      return {
        ...state,
        personalize,
      };
    }
    case AppTypes.REQUEST_RECEIVED: {
      const {
        request: { headers },
      } = action.payload;
      const callMetadataService = get(headers, 'showproductdata') === 'true';
      return {
        ...state,
        callMetadataService,
      };
    }
    default:
      return state;
  }
};

const initialLocaleState = {
  countryCode: 'US',
  currencyCode: 'USD',
  localeUrl: '/en-us/',
  selectedCountryCode: '',
  selectedCurrencyCode: '',
  selectedLocaleUrl: '',
  currentCountryCode: 'US',
};

export const countrySelectorReducer = (state = initialLocaleState, action) => {
  switch (action.type) {
    case AppTypes.SET_LOCALE:
      return {
        ...state,
        ...action.locale,
      };
    default:
      return state;
  }
};

const initialStrategyState = {
  enabled: false,
  iplaValue: '',
};

export const productListingAdReducer = (
  state = initialStrategyState,
  action,
) => {
  switch (action.type) {
    case AppTypes.PLA_COOKIE_RECEIVED: {
      return {
        ...state,
        enabled: action.payload.ipla && action.payload.ipla.startsWith('DTT'),
        iplaValue: action.payload.ipla,
      };
    }
    default:
      return state;
  }
};

const initialABTESTState = {
  session: '',
  tntId: '',
  mboxData: '',
};

export const abTestSessionReducer = (state = initialABTESTState, action) => {
  switch (action.type) {
    case AppTypes.AB_TEST_SESSION_DATA_RECEIVED:
      return {
        ...state,
        mboxData: action.mboxCookie,
      };
    default:
      return state;
  }
};
