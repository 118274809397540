import httpWithLogging from 'universal/http-client';
import { getIdTokenAndUserInfo } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import {
  LOADING_ORDERS,
  RESOLVED_ORDERS,
  REJECTED_ORDERS,
  REJECTED_ORDER_DETAILS,
  LOADING_ORDER_DETAILS,
  RESOLVED_ORDER_DETAILS,
  RESOLVED_CANCEL_ORDER,
  REJECTED_CANCEL_ORDER,
  LOADING_CANCEL_ORDER,
} from '../../constants';

export function getOrdersForMyAccount() {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 8000);

    dispatch({ type: LOADING_ORDERS });

    return getIdTokenAndUserInfo(
      amplifyConfig,
      isNewCartCheckoutEnabled(state),
      true,
      disableATGToggle,
    )
      .then(info => {
        const ucaId = info?.userInfo?.attributes?.sub || null;
        const idToken = info?.tokenInfo?.idToken || null;
        const headers = {
          ucaId,
          authorization: idToken,
        };
        const callString = `${NMConfig.API_MY_ACCOUNT_ORDERS}`;

        return requestApi.post(
          callString,
          {
            pagination: {
              from: 0,
              size: 10000,
            },
            sort: {
              sortType: 'desc',
              sortBy: 'orderDate',
            },
          },
          { headers },
        );
      })
      .then(res => {
        if (res && res.data) {
          return dispatch({
            type: RESOLVED_ORDERS,
            payload: res.data,
          });
        } else {
          return dispatch({ type: REJECTED_ORDERS });
        }
      })
      .catch(() => dispatch({ type: REJECTED_ORDERS }));
  };
}

export function getOrdersDetails(id) {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 8000);
    dispatch({ type: LOADING_ORDER_DETAILS });
    getIdTokenAndUserInfo(
      amplifyConfig,
      isNewCartCheckoutEnabled(state),
      true,
      disableATGToggle,
    )
      .then(info => {
        const ucaId = info?.userInfo.attributes.sub || null;
        const idToken = info?.tokenInfo?.idToken || null;
        const headers = {
          ucaId,
          authorization: idToken,
          'X-NMG-OH-PRD': true,
          orderid: id,
        };

        const callString = `${NMConfig.API_MY_ACCOUNT_ORDERS}`;
        return requestApi.get(callString, { headers });
      })
      .then(res => {
        if (res && res.data) {
          dispatch({
            type: RESOLVED_ORDER_DETAILS,
            payload: res.data,
          });
        } else {
          dispatch({ type: RESOLVED_ORDER_DETAILS });
        }
      })
      .catch(() => dispatch({ type: REJECTED_ORDER_DETAILS }));
  };
}

export function cancelOrder(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const cancelOrderV3Toggle = state.toggles.ORDER_CANCEL_V3;
    const { amplifyConfig } = state;
    const { cancelOrders } = state.myaccount;
    const requestApi = httpWithLogging(state, 8000);
    dispatch({ type: LOADING_CANCEL_ORDER });
    getIdTokenAndUserInfo(
      amplifyConfig,
      isNewCartCheckoutEnabled(state),
      true,
      disableATGToggle,
    )
      .then(info => {
        const ucaId = info?.userInfo.attributes.sub || null;
        const idToken = info?.tokenInfo?.idToken || null;
        const headers = {
          ucaId,
          authorization: idToken,
          orderId: payload.orderId,
        };
        const callString = `${NMConfig.API_CANCEL_ORDERS}`;
        const body = cancelOrderV3Toggle
          ? {
              orderId: payload.orderId,
              items: [
                {
                  reason: payload.reason,
                  itemId: payload.itemId,
                },
              ],
            }
          : {
              reason: payload.reason,
              itemId: payload.itemId,
            };

        return requestApi.post(callString, body, { headers });
      })
      .then(res => {
        if (res && res.status === 200) {
          dispatch({
            type: RESOLVED_CANCEL_ORDER,
            payload: cancelOrders.concat([payload.itemId]),
          });
        } else {
          dispatch({
            type: RESOLVED_CANCEL_ORDER,
            payload: cancelOrders,
          });
        }
      })
      .catch(() => dispatch({ type: REJECTED_CANCEL_ORDER }));
  };
}
