import Button from '@bgo-ui/common/client/common/components/Button/button';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import closeIcon from 'assets/images/icons-close.svg';
import minusIcon from 'assets/images/icons-minus.svg';
import plusIcon from 'assets/images/icons-plus.svg';
import SubmitButton from '../../../../common/Forms/FormButtons/SubmitButton/SubmitButton';
import {
  ACTIVATE,
  ACTIVATED,
  BG_CREDIT_CARD,
  ENTER_NOW,
  ERROR_FETCHING,
  INCIRCLE_ENTER_PHONE_TXT,
  memberBenefitsData,
  MEMBER_BENEFITS,
  SEE_FULL_TERMS,
  STATUS_INFO,
  UNLOCK_HIGHER_DESC,
  VIEW_DETAILS,
  LEARN_MORE,
  WELCOME_TXT,
  YOUR_BENEFITS,
  YOUR_STATUS,
  IN_CIRCLE_APPLY_NOW_LINK,
  ULTIMATE_INSIDER_TITLE,
  ULTIMATE_INSIDER_SUB_TITLE,
  ALREADY_MEMBER_TITLE,
  ALREADY_MEMBER_SUB_TITLE,
  IN_CIRCLE_LEVELS_TITLE,
  IN_CIRCLE_LEVELS_ANNUAL_SPEND,
  IN_CIRCLE_LEVELS_CARDHOLDER_BENEFITS,
  PLAN_ONLINE_PURCHASE_TITLE,
  PLAN_ONLINE_PURCHASE_SUB_TITLE,
  IN_CIRCLE_CONCIERGE_PAGE_PATH,
} from './constants';
import formatDateTime, { LoyaltyTierMap } from './helpers';

export const IncirclePhone = ({ openPhoneModal }) => (
  <div className="incircle-phone-msg">
    {INCIRCLE_ENTER_PHONE_TXT}
    <span onClick={openPhoneModal} onKeyUp={openPhoneModal}>
      {ENTER_NOW}
    </span>
  </div>
);

export const ContentSpot = ({ userName, content }) => (
  <div className="content-spot-con">
    <div className="spot-header">
      {WELCOME_TXT}, {userName?.toUpperCase()}
    </div>
    <span>{content}</span>
  </div>
);

export const Status = ({
  loyaltySummary: { tierCode, lastUpdatedTime },
  IncircleImg,
}) => (
  <div className="status-con">
    <img src={IncircleImg} alt="logoAlt" />
    <span className="status-title">
      {YOUR_STATUS}: {LoyaltyTierMap[tierCode]}
    </span>
    <div className="info-time">
      {STATUS_INFO} {formatDateTime(lastUpdatedTime)}
    </div>
  </div>
);

export const ExtraBenefits = ({ data }) => {
  const extraBenefits = [];
  for (let i = 0; i < data.length; i += 2) {
    const benefit = (
      <div>
        <span>{data[i]}</span>
        <span>{i + 1 < data.length ? data[i + 1] : null}</span>
      </div>
    );
    extraBenefits.push(benefit);
  }
  return <div className="extra-benefits-con">{extraBenefits}</div>;
};

export const YourBenefits = ({
  benefits,
  tierCode,
  handleFlyout,
  flyoutsData,
  bgoConciergeToggle,
}) => {
  const tierBenefits = benefits[tierCode]?.benefits;
  return (
    <div className="benefits-con">
      <h2>{YOUR_BENEFITS}</h2>
      {benefits[tierCode]?.tierDescription && (
        <p
          className="description text-gray"
          dangerouslySetInnerHTML={{
            __html: benefits[tierCode]?.tierDescription,
          }}
        />
      )}
      {benefits[tierCode]?.extraBenefits && (
        <ExtraBenefits data={benefits[tierCode]?.extraBenefits} />
      )}
      {tierBenefits?.map(benefit => (
        <div className="benefit" key={benefit?.title?.replaceAll(' ', '-')}>
          <div className="benefit-icon">
            <img src={benefit?.icon} alt="point" />
          </div>
          <div className="benefit-content">
            <span className="title">{benefit?.title}</span>
            <span
              dangerouslySetInnerHTML={{
                __html: benefit?.desc,
              }}
            />
            &nbsp;
            {benefit?.flyoutKey && flyoutsData[(benefit?.flyoutKey)] && (
              <span
                className="view-details"
                onClick={() => handleFlyout(benefit?.flyoutKey)}
                aria-hidden="true"
              >
                {VIEW_DETAILS}
              </span>
            )}
            {bgoConciergeToggle && benefit?.learnMore && (
              <a href={IN_CIRCLE_CONCIERGE_PAGE_PATH} className="view-details">
                {LEARN_MORE}
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export const TermsAndConditions = ({ terms }) => (
  <div className="terms text-gray">
    <div className="bold-title">{terms?.title}</div>
    <p
      dangerouslySetInnerHTML={{
        __html: terms?.desc,
      }}
    />
  </div>
);

export const FlyOut = ({
  flyOutMargin,
  flyoutRef,
  flyoutsData,
  activeFlyout,
  handleFlyout,
  handleDoublePoints = () => {},
  isDOCActivated,
  isBtnLoading,
  isError,
}) => (
  <div>
    <div
      className={classNames('flyout', { 'flyout--open': activeFlyout })}
      style={{ marginTop: flyOutMargin }}
      ref={flyoutRef}
    >
      {activeFlyout && (
        <div className="content">
          <div
            className="close-icon"
            onClick={() => handleFlyout(false)}
            aria-hidden="true"
            id="closeOverlayIcon"
          >
            <img src={closeIcon} alt="Close" alr="Close" />
          </div>
          <div
            className="flyout-title"
            dangerouslySetInnerHTML={{
              __html: flyoutsData[activeFlyout].title,
            }}
          />
          <div
            className="flyout-content"
            dangerouslySetInnerHTML={{
              __html: flyoutsData[activeFlyout].desc,
            }}
          />
          {activeFlyout === 'doublePoints' &&
            (isError ? (
              <p>{ERROR_FETCHING}</p>
            ) : (
              <SubmitButton
                caption={isDOCActivated ? ACTIVATED : ACTIVATE}
                additionalClasses="activate-button"
                onClick={() => handleDoublePoints()}
                isSubmitting={isBtnLoading}
                disabled={isDOCActivated}
              />
            ))}
          <div
            className="flyout-terms"
            dangerouslySetInnerHTML={{
              __html: flyoutsData[activeFlyout].terms,
            }}
          />
        </div>
      )}
    </div>
    <div
      className={classNames('flyout-overlay', {
        'flyout-overlay--open': activeFlyout,
      })}
      onClick={() => handleFlyout(false)}
      onKeyDown={() => handleFlyout(false)}
    />
  </div>
);

export const BgCreditCard = ({ desc }) => (
  <div className="bg-cc">
    <h2>{BG_CREDIT_CARD}</h2>
    <div
      dangerouslySetInnerHTML={{
        __html: desc,
      }}
    />
    <a
      href="https://bergdorfgoodman.capitalone.com/#/sign-in"
      target="_blank"
      rel="noreferrer"
    >
      <Button value="Manage your card" className="manage-card" />
    </a>
  </div>
);

export const MemberBenefits = ({ hasNoTableMargin }) => {
  const [columnHeaderSpanData, setColumnHeaderSpanData] = useState([]);
  useEffect(() => {
    // Function to update number based on viewport width
    const updateColSpanData = () => {
      if (window.innerWidth <= 768) {
        setColumnHeaderSpanData(memberBenefitsData?.columnHeaderSpanMobile);
      } else {
        setColumnHeaderSpanData(memberBenefitsData?.columnHeaderSpan);
      }
    };
    // Initial update
    updateColSpanData();
    // Event listener for window resize
    window.addEventListener('resize', updateColSpanData);
    // Clean up event listener
    return () => {
      window.removeEventListener('resize', updateColSpanData);
    };
  }, []);
  const {
    columnHeaders,
    tableData,
    rowHeaders,
    rowHeaderSpan,
  } = memberBenefitsData;
  return (
    <div className="member-benefits-con">
      <h2>{MEMBER_BENEFITS}</h2>
      <div className="table-container">
        <table
          style={{
            borderCollapse: 'collapse',
            ...(!hasNoTableMargin
              ? {
                  margin: '10px',
                }
              : {}),
          }}
        >
          <tbody>
            <tr>
              <th className="column-header" aria-label="empty-corner-header" />
              {Array.from({ length: 6 }).map((_, colIndex) => (
                <th
                  key={`colHeader-${columnHeaders[colIndex]}`}
                  className="circles-header"
                >
                  <div className="vertical">{columnHeaders[colIndex]}</div>
                  <p>{columnHeaderSpanData[colIndex]}</p>
                </th>
              ))}
            </tr>
            {tableData.map((rowData, rowIndex) => (
              <tr className="row-data">
                <th key={`rowHeader-${rowIndex}`}>
                  {rowHeaders[rowIndex]}
                  <div>{rowHeaderSpan[rowIndex]}</div>
                </th>
                {rowData.map((cellContent, colIndex) => (
                  <td key={`cell-${rowIndex}-${colIndex}`}>
                    <div className="col-data">
                      {cellContent === true ? (
                        <div className="member-benefit" />
                      ) : (
                        <span>{cellContent}</span>
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p>
        {UNLOCK_HIGHER_DESC} <a href="/e/loyalty-terms">{SEE_FULL_TERMS}</a>
      </p>
    </div>
  );
};

export const UltimateInsider = ({
  features,
  isLoggedIn,
  openApplyNowModal,
}) => {
  return (
    <div className="ultimate-insider">
      <h2 className="ultimate-insider__title">{ULTIMATE_INSIDER_TITLE}</h2>
      <h3 className="ultimate-insider__sub-title">
        {ULTIMATE_INSIDER_SUB_TITLE}
      </h3>
      <div className="ultimate-insider__features">
        {features?.map(feature => (
          <div
            className="ultimate-insider__features__item"
            key={`ultimate-insider-feature-${feature.id}`}
          >
            <div className="ultimate-insider__features__item__icon">
              <img src={feature.icon} alt={feature.title} />
            </div>
            <div className="ultimate-insider__features__item__content">
              <h4 className="ultimate-insider__features__item__content__title">
                {feature.title}
              </h4>
              <p className="ultimate-insider__features__item__content__desc">
                {feature.desc}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="ultimate-insider__cta">
        {isLoggedIn ? (
          <a href={IN_CIRCLE_APPLY_NOW_LINK} target="_blank" rel="noreferrer">
            <Button value="Apply Now" className="apply-now" />
          </a>
        ) : (
          <Button
            value="Apply Now"
            className="apply-now"
            onClick={openApplyNowModal}
          />
        )}
      </div>
    </div>
  );
};

export const AlreadyMember = () => {
  const handleSignIn = () => {
    window.location.href = '/e/login?returnToURL=/e/myaccount/bgcreditcard';
  };

  return (
    <div className="already-member">
      <div className="already-member__content">
        <h2 className="already-member__content__title">
          {ALREADY_MEMBER_TITLE}
        </h2>
        <h3 className="already-member__content__sub-title">
          {ALREADY_MEMBER_SUB_TITLE}
        </h3>
      </div>
      <div className="already-member__cta">
        <Button
          secondary
          value="Sign in"
          className="sign-in"
          onClick={handleSignIn}
        />
      </div>
    </div>
  );
};

export const PlanOnlinePurchase = () => {
  return (
    <div className="plan-online-purchase">
      <div className="plan-online-purchase__content">
        <h2 className="plan-online-purchase__content__title">
          {PLAN_ONLINE_PURCHASE_TITLE}
        </h2>
        <h3 className="plan-online-purchase__content__sub-title">
          {PLAN_ONLINE_PURCHASE_SUB_TITLE}
        </h3>
      </div>
    </div>
  );
};

export const InCircleLevels = ({ levels }) => {
  const [openId, setOpenId] = useState('');

  const handleToggle = id => {
    setOpenId(openId !== id ? id : '');
  };

  return (
    <div className="in-circle-levels">
      <h2 className="in-circle-levels__title">{IN_CIRCLE_LEVELS_TITLE}</h2>
      <InCircleAccordion>
        {levels?.map(level => (
          <InCircleAccordionItem
            key={`in-circle-accordion-item-${level.id}`}
            id={level.id}
            title={level.title}
            isOpen={openId === level.id}
            toggle={handleToggle}
          >
            <InCircleLevelContentList level={level} />
          </InCircleAccordionItem>
        ))}
      </InCircleAccordion>
    </div>
  );
};

const InCircleAccordion = ({ children }) => {
  return <ul className="in-circle-accordion">{children}</ul>;
};

const InCircleAccordionItem = ({ id, title, isOpen, toggle, children }) => {
  return (
    <li className="in-circle-accordion__item">
      <div
        className={classNames('in-circle-accordion__item__title', {
          'in-circle-accordion__item__title--is-closed': !isOpen,
        })}
      >
        <h3 className="in-circle-accordion__item__title__content">{title}</h3>
        <div
          className="in-circle-accordion__item__title__icon"
          onClick={() => toggle(id)}
        >
          {isOpen ? (
            <img src={minusIcon} alt="Close" alr="Close" />
          ) : (
            <img src={plusIcon} alt="Open" alr="Open" />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="in-circle-accordion__item__content">{children}</div>
      )}
    </li>
  );
};

const InCircleLevelContentList = ({ level }) => {
  const renderExclusiveBenefits = level => {
    return (
      <div
        className="in-circle-level-content-list__item"
        key={`in-circle-level-${level.id}-exclusive-benefit`}
      >
        <p className="in-circle-level-content-list__item__title">
          {level.exclusiveBenefits.title}
        </p>
        <p className="in-circle-level-content-list__item__desc">
          {level.exclusiveBenefits.desc}
        </p>
        <ul className="in-circle-level-content-list__item__list">
          {level.exclusiveBenefits.list.map(item => (
            <li
              className="in-circle-level-content-list__item__list__item"
              key={`in-circle-level-${level.id}-exclusive-benefit-${item.id}`}
            >
              {item.content}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="in-circle-level-content-list">
      <div
        className="in-circle-level-content-list__item"
        key={`in-circle-level-${level.id}-annualSpend`}
      >
        <p className="in-circle-level-content-list__item__title">
          {IN_CIRCLE_LEVELS_ANNUAL_SPEND}: {level.annualSpend}
        </p>
        <p className="in-circle-level-content-list__item__desc">
          {IN_CIRCLE_LEVELS_CARDHOLDER_BENEFITS}
        </p>
      </div>
      {level.exclusiveBenefits && renderExclusiveBenefits(level)}
      {level?.benefits?.map(levelBenefits => (
        <div
          className="in-circle-level-content-list__item"
          key={`in-circle-level-${level.id}-benefit-${levelBenefits.id}`}
        >
          <p className="in-circle-level-content-list__item__title">
            {levelBenefits.title}
          </p>
          <p className="in-circle-level-content-list__item__desc">
            {levelBenefits.desc}
          </p>
        </div>
      ))}
      {level.tip && (
        <div
          className="in-circle-level-content-list__item"
          key={`in-circle-level-${level.id}-tip`}
        >
          <p className="in-circle-level-content-list__item__tip">{level.tip}</p>
        </div>
      )}
    </div>
  );
};

export const InCircleTerms = ({ terms }) => {
  return (
    <div className="in-circle-terms">
      <h3 className="in-circle-terms__title">{terms.title}</h3>
      <p
        className="in-circle-terms__desc"
        dangerouslySetInnerHTML={{
          __html: terms.desc,
        }}
      />
      <p className="in-circle-terms__desc">{terms.validity}</p>
    </div>
  );
};
