import isEmpty from 'lodash/isEmpty';
import httpWithLogging from 'universal/http-client';
import {
  CLOSE_MY_ACCOUNT_MOBILE_NAV,
  LOADING_CONTENT,
  OPEN_MY_ACCOUNT_MOBILE_NAV,
  REJECTED_CONTENT,
  RESOLVED_CONTENT,
} from './constants';
import { parseCopyData } from '../../utilities/copy-helpers';

export function openMobileNav() {
  return dispatch => dispatch({ type: OPEN_MY_ACCOUNT_MOBILE_NAV });
}

export function closeMobileNav() {
  return dispatch => dispatch({ type: CLOSE_MY_ACCOUNT_MOBILE_NAV });
}

/**
 * @function getContentForMyAccount
 * @returns {Promise}
 */
export function getContentForMyAccount() {
  return (dispatch, getState) => {
    const state = getState();
    const content = state?.myaccount?.myAccountContent || {};
    if (isEmpty(content)) {
      const isMobile = state?.device?.isMobilePhone || false;
      const requestApi = httpWithLogging(state, 8000);
      const callString = `${NMConfig.API_MY_ACCOUNT_CONTENT}/${
        isMobile ? 'app' : 'desktop'
      }`;
      dispatch({ type: LOADING_CONTENT });
      return requestApi
        .get(callString, {})
        .then(res => {
          if (res && res.data) {
            dispatch({
              type: RESOLVED_CONTENT,
              payload: parseCopyData(res.data),
            });
          } else {
            dispatch({ type: REJECTED_CONTENT });
          }
        })
        .catch(() => dispatch({ type: REJECTED_CONTENT }));
    } else {
      return Promise.resolve();
    }
  };
}

const handleLoad = (dispatch, pageType) => {
  dispatch({ type: pageType });
  removeEventListener('load', handleLoad);
};

export function setResolvedPage(pageType) {
  return (dispatch, getState) => {
    const state = getState();
    const isDomLoadComplete = state?.page?.isDomLoadComplete || {};

    if (isDomLoadComplete) {
      return dispatch({ type: pageType });
    } else {
      addEventListener('load', handleLoad.bind(null, dispatch, pageType));
    }
    return undefined;
  };
}
