import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getRewards } from 'shared/actions/actions-rewards';
import { getLoyaltyProfileId } from '@bgo-ui/common/client/utilities/utilities-loyalty';
import { isNewMyAccountEnabled } from 'bgo-ui-home/src/client/components/myaccount/utilities/is-new-myaccount';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import { getUserInfo } from 'client-utils/utilities-amplify';
import { localStorage } from 'window-or-global';
import reactCookie from 'react-cookie';
import BrandLogo from './BrandLogo';
import MiniHub from './MiniHub/miniHub';
import MiniCart from './MiniCart/miniCart';
import MiniCartDT from './MiniCartDT/MiniCartDT';
import {
  ConnectedSignInRegister,
  ConnectedYourAccount,
} from './AccountHeaderMenuItem/AccountHeaderMenuItem';
import MobileNav from '../Navigation/MobileNav/MobileNav';
import './header.scss';
import SearchButton from './Search/SearchButton/searchButton';
import SearchPanel from './Search/SearchPanel/searchPanel';

const Header = ({
  user,
  isNewMyAccountEnabled,
  isFullWidth,
  browser,
  isDomestic,
  pageId,
  getRewards,
  isNewCartCheckoutEnabled,
  internationalToggle,
  headerLinkFixEnabled,
  amplifyConfig,
  userSession,
  disableATGToggle,
  bgoLoyaltyToggle,
  loyMiniHubToggle,
}) => {
  const [isUserSignIn, setUserSignIn] = useState(false);
  const [authInfoReceived, setAuthInfoReceived] = useState(false);
  const [isOpenMiniHub, setIsOpenMiniHub] = useState(false);
  const [loyaltyProfileId, setLoyaltyProfileId] = useState('');

  const isLoyaltyEnabledAccount = async () => {
    if (bgoLoyaltyToggle && loyMiniHubToggle) {
      const id = await getLoyaltyProfileId(user, amplifyConfig);
      setLoyaltyProfileId(id);
    }
  };

  const handleMiniHubToggle = () => {
    setIsOpenMiniHub(prevState => !prevState);
  };

  const isDTminiCart =
    isNewCartCheckoutEnabled &&
    ((internationalToggle && isDomestic) || !internationalToggle);

  useEffect(() => {
    const getCustomerID = data => {
      if (data && data.logged_in_status === 'true') {
        return data.web_id;
      } else {
        let ucaValue = reactCookie.load('guestID') || '';
        if (ucaValue === '') {
          const userDataKey =
            localStorage &&
            Object.keys(localStorage).filter(item =>
              item.startsWith('guestUser.'),
            )[0];
          const guestUser = userDataKey && userDataKey.split('.')[1];
          ucaValue = guestUser || '';
        }
        return ucaValue;
      }
    };
    const cstmr = {
      customerId: getCustomerID(userSession) || '',
      cmdId: '',
      isLoggedin: userSession.logged_in_status || 'false',
      isGuest:
        userSession && userSession.logged_in_status === 'false'
          ? 'true'
          : 'false',
    };
    reactCookie.save('cstmr', JSON.stringify(cstmr), { path: '/' });
    if (headerLinkFixEnabled) {
      getUserInfo(amplifyConfig, isNewCartCheckoutEnabled, disableATGToggle)
        .then(user => {
          if (user) {
            setUserSignIn(true);
            if (isNewMyAccountEnabled) {
              getRewards();
            }
          } else {
            setUserSignIn(false);
          }
        })
        .catch(() => setUserSignIn(false))
        .finally(() => setAuthInfoReceived(true));
    } else {
      const localStorageKeys = localStorage && Object.keys(localStorage);
      localStorageKeys &&
        localStorageKeys.forEach(key => {
          if (
            key.startsWith('CognitoIdentityServiceProvider.') &&
            key.endsWith('accessToken')
          ) {
            setUserSignIn(true);
            if (isNewMyAccountEnabled) {
              getRewards();
            }
          }
        });
    }

    isLoyaltyEnabledAccount();
  }, []);

  const renderHeaderMenu = isUserSignIn ? (
    <ConnectedYourAccount
      firstName={user.name}
      isFullWidth={isFullWidth}
      handleMiniHubToggle={handleMiniHubToggle}
      loyaltyProfileId={loyaltyProfileId}
    />
  ) : (
    <ConnectedSignInRegister isFullWidth={isFullWidth} />
  );
  const isClient = typeof window !== 'undefined';

  return (
    <React.Fragment>
      <div className="header-wrapper">
        <div id="header-section" className="header-container columns">
          <div className="headerLeftElements column is-4">
            <MobileNav />
            <div className="search-minicart-wrapper">
              <SearchButton />
            </div>
          </div>
          <BrandLogo isFullWidth={isFullWidth} />
          {/* <div className="grid-10 hide-on-tablet hide-on-mobile"></div> */}
          <div
            className={`header-section__right-aligned column is-4 ${
              typeof browser !== 'undefined' &&
              ['edge', 'ie'].indexOf(browser.toLowerCase()) !== -1
                ? ' isIe9Above'
                : ''
            }`}
          >
            <div className="header-signIn-section">
              {!headerLinkFixEnabled &&
                isDomestic &&
                isClient &&
                renderHeaderMenu}
              {headerLinkFixEnabled &&
                isDomestic &&
                isClient &&
                authInfoReceived &&
                renderHeaderMenu}
              {pageId !== 'PAGE_ID_INTL_CART' && (
                <div className="header-utility-menu">
                  {isDTminiCart ? <MiniCartDT /> : <MiniCart />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loyaltyProfileId && (
        <MiniHub
          loyaltyProfileId={loyaltyProfileId}
          isOpenMiniHub={isOpenMiniHub}
          handleMiniHubToggle={handleMiniHubToggle}
        />
      )}
      <SearchPanel />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  browser: state.device.browser,
  exposedMobileSearchBar:
    state.toggles.EXPOSE_MOBILE_SEARCH_BAR && state.locale.countryCode === 'US',
  isFullWidth: state.toggles.HOMEPAGE_FULL_WIDTH,
  enableFavorites: state.toggles.HEADER_FAVORITES,
  isNewMyAccountEnabled: isNewMyAccountEnabled(state),
  isNewCartCheckoutEnabled: isNewCartCheckoutEnabled(state),
  internationalToggle: get(state, 'toggles.INTERNATIONAL', false),
  isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
  pageId: get(state, 'page.pageId', ''),
  headerLinkFixEnabled: state?.toggles?.HEADER_SIGNIN_LINK_FIX || false,
  amplifyConfig: state.amplifyConfig,
  userSession: state?.utag?.userSession || {},
  disableATGToggle: get(state, 'toggles.DISABLE_ATG_CALLS', false),
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  loyMiniHubToggle: state?.toggles?.LOY_MINI_HUB || false,
});

const mapDispatchToProps = {
  getRewards,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
