import React, { useEffect, useCallback } from 'react';
import { array, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import {
  PAGE_ID_PAYMENT_DETAILS,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import Spinner from 'shared/components/Spinner/simpleSpinner';
import { getPaymentForMyAccount } from './actions';
import { getContentForMyAccount, setResolvedPage } from '../../actions';
import { RESOLVED_PAYMENT_DETAILS_PAGE } from '../../constants';
import PaymentList from './PaymentList/PaymentList';
import './PaymentDetailsPage.scss';

/**
 * @function PaymentDetailsPage
 * @param {Function} props.getContentForMyAccount
 * @param {Function} props.getPaymentForMyAccount
 * @param {Function} props.setResolvedPage
 * @param {Array|Bool} props.myAccountPayment
 * @param {Function} props.setPageId
 * @returns {React.ReactElement}
 */
const PaymentDetailsPage = ({
  getContentForMyAccount,
  getPaymentForMyAccount,
  setResolvedPage,
  myAccountPayment,
  setPageId,
  isLoading,
}) => {
  useCallback(() => {
    setPageId(PAGE_ID_PAYMENT_DETAILS);
  }, []);

  useEffect(() => {
    setResolvedPage(RESOLVED_PAYMENT_DETAILS_PAGE);
    getContentForMyAccount();
    getPaymentForMyAccount();
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="payment-details">
      <PaymentList payments={myAccountPayment} />
    </div>
  );
};

PaymentDetailsPage.propTypes = {
  getContentForMyAccount: func.isRequired,
  getPaymentForMyAccount: func.isRequired,
  setResolvedPage: func.isRequired,
  myAccountPayment: array.isRequired,
  setPageId: func.isRequired,
  isLoading: bool.isRequired,
};

const mapStateToProps = state => ({
  myAccountPayment: state?.myaccount?.myAccountPayment || [],
  isLoading:
    state?.api?.payment?.loading || state?.api?.content?.loading || false,
});

const mapDispatchToProps = {
  getContentForMyAccount,
  getPaymentForMyAccount,
  setResolvedPage,
  setPageId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentDetailsPage);
