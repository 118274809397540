import get from 'lodash/get';
import { OPEN_MODAL } from '@bgo-ui/common/client/common/components/Modal/actions';
import {
  RESOLVED_ADDRESS,
  RESOLVED_CONTENT,
  RESOLVED_PAYMENT,
  RESOLVED_BG_CARD,
  RESOLVED_USER_INFO,
  RESOLVED_ADD_ADDRESS,
  SET_ADDRESS_ID,
  CLOSE_MY_ACCOUNT_MOBILE_NAV,
  OPEN_MY_ACCOUNT_MOBILE_NAV,
  ACCOUNT_DETAILS_OPEN_EDIT,
  ACCOUNT_DETAILS_CLOSE_EDIT,
  RESOLVED_UPDATE_BASIC_ATTRIBUTES,
  LOADING_UPDATE_BASIC_ATTRIBUTES,
  REJECTED_UPDATE_BASIC_ATTRIBUTES,
  REJECTED_CHANGE_PASSWORD,
  RESOLVED_CHANGE_PASSWORD,
  LOADING_CHANGE_PASSWORD,
  genericError,
  RESOLVED_ORDERS,
  RESOLVED_ADDITIONAL_USER_INFO,
  RESOLVED_UPDATE_ADDITIONAL_USER_INFO,
  REJECTED_UPDATE_ADDITIONAL_USER_INFO,
  RESOLVED_SEND_VERIFICATION_CODE,
  REJECTED_SEND_VERIFICATION_CODE,
  LOADING_SEND_VERIFICATION_CODE,
  RESOLVED_ORDER_DETAILS,
  LOADING_ORDER_DETAILS,
  RESOLVED_ITEM_SELECT,
  RESOLVED_CANCEL_ORDER,
  RESOLVED_LOYALTY_SUMMARY,
  REJECTED_LOYALTY_SUMMARY,
  LOADING_LOYALTY_SUMMARY,
  LOADING_LOYALTY_DOC,
  RESOLVED_LOYALTY_DOC,
  REJECTED_LOYALTY_DOC,
  LOADING_ACTIVATE_DOC,
  RESOLVED_ACTIVATE_DOC,
  REJECTED_ACTIVATE_DOC,
} from './constants';

const defaultState = {
  myAccountContent: {},
  myAccountDetails: {
    currentEditSection: null,
    updateBasicAttributesSuccess: false,
    updateBasicAttributesError: null,
    updateAdditionalInfoError: null,
    changePasswordError: null,
    changePasswordSuccess: false,
    isLoaded: false,
    verificationCode: {
      success: false,
      error: false,
    },
    fields: {
      birthday: '',
      email: '',
      givenName: '',
      familyName: '',
      mostlyInterestedIn: '',
      phoneNumber: '',
    },
  },
  myAccountPayment: [],
  myAccountAddress: [],
  myAccountBGcard: [],
  myAccountOrders: [],
  mobileNavOpened: false,
  orderDetails: {},
  cancelOrders: [],
  isLoading: false,
  loyaltySummary: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_CONTENT:
      return {
        ...state,
        myAccountContent: action.payload,
      };
    case RESOLVED_PAYMENT:
      return {
        ...state,
        myAccountPayment: action.payload,
      };
    case RESOLVED_ADDRESS:
      return {
        ...state,
        myAccountAddress: action.payload,
      };
    case RESOLVED_BG_CARD:
      return {
        ...state,
        myAccountBGcard: action.payload,
      };
    case RESOLVED_ADD_ADDRESS:
      return {
        ...state,
        myAccountAddress: [...state.myAccountAddress, action.payload],
      };
    case RESOLVED_USER_INFO:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          isLoaded: true,
          fields: {
            ...state.myAccountDetails.fields,
            email: action.payload.attributes.email,
            firstName: action.payload.attributes.given_name,
            lastName: action.payload.attributes.family_name,
            phoneNumber: action.payload.attributes.phone_number,
          },
        },
      };
    case ACCOUNT_DETAILS_OPEN_EDIT:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          currentEditSection: action.payload,
          updateBasicAttributesError: null,
          updateAdditionalInfoError: null,
        },
      };
    case ACCOUNT_DETAILS_CLOSE_EDIT:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          currentEditSection: null,
        },
      };
    case LOADING_UPDATE_BASIC_ATTRIBUTES:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          updateBasicAttributesError: null,
        },
      };
    case RESOLVED_UPDATE_BASIC_ATTRIBUTES:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          currentEditSection: null,
          updateBasicAttributesError: null,
          updateBasicAttributesSuccess: true,
          fields: {
            ...state.myAccountDetails.fields,
            ...action.payload,
          },
        },
      };
    case REJECTED_UPDATE_BASIC_ATTRIBUTES:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          updateBasicAttributesError: get(
            action,
            'payload.error',
            genericError,
          ),
        },
      };
    case OPEN_MODAL:
      if (action.payload.type === 'ChangePasswordModal') {
        return {
          ...state,
          myAccountDetails: {
            ...state.myAccountDetails,
            verificationCode: defaultState.myAccountDetails.verificationCode,
            changePasswordError: null,
            changePasswordSuccess: false,
          },
        };
      } else if (action.payload.type === 'ChangeEmailModal') {
        return {
          ...state,
          myAccountDetails: {
            ...state.myAccountDetails,
            verificationCode: defaultState.myAccountDetails.verificationCode,
            updateBasicAttributesSuccess: false,
            updateBasicAttributesError: null,
          },
        };
      } else {
        return state;
      }
    case LOADING_SEND_VERIFICATION_CODE:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          verificationCode: defaultState.myAccountDetails.verificationCode,
        },
      };
    case RESOLVED_SEND_VERIFICATION_CODE:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          verificationCode: {
            success: true,
            error: false,
          },
        },
      };
    case REJECTED_SEND_VERIFICATION_CODE:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          verificationCode: {
            success: false,
            error: true,
          },
        },
      };
    case LOADING_CHANGE_PASSWORD:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          changePasswordError: null,
        },
      };
    case RESOLVED_CHANGE_PASSWORD:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          currentEditSection: null,
          changePasswordError: null,
          changePasswordSuccess: true,
        },
      };
    case REJECTED_CHANGE_PASSWORD:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          changePasswordError: get(action, 'payload.error', genericError),
        },
      };
    case RESOLVED_ADDITIONAL_USER_INFO:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          currentEditSection: null,
          updateAdditionalInfoError: null,
          fields: {
            ...state.myAccountDetails.fields,
            ...action.payload,
          },
        },
      };
    case RESOLVED_UPDATE_ADDITIONAL_USER_INFO:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          currentEditSection: null,
          updateAdditionalInfoError: null,
          fields: {
            ...state.myAccountDetails.fields,
            ...action.payload,
          },
        },
      };
    case REJECTED_UPDATE_ADDITIONAL_USER_INFO:
      return {
        ...state,
        myAccountDetails: {
          ...state.myAccountDetails,
          updateAdditionalInfoError: genericError,
        },
      };
    case OPEN_MY_ACCOUNT_MOBILE_NAV:
      return {
        ...state,
        mobileNavOpened: true,
      };
    case CLOSE_MY_ACCOUNT_MOBILE_NAV:
      return {
        ...state,
        mobileNavOpened: false,
      };
    case SET_ADDRESS_ID: {
      return {
        ...state,
        addressId: action.payload,
      };
    }
    case RESOLVED_ORDERS:
      return {
        ...state,
        myAccountOrders: action.payload,
      };
    case RESOLVED_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: action.payload,
        isLoading: false,
      };
    case RESOLVED_CANCEL_ORDER:
      return {
        ...state,
        cancelOrders: action.payload,
        isLoading: false,
      };
    case RESOLVED_ITEM_SELECT:
      return {
        ...state,
        orderDetails: action.payload,
        isLoading: false,
      };
    case LOADING_ORDER_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_LOYALTY_SUMMARY:
      return {
        ...state,
        isLoading: true,
      };
    case RESOLVED_LOYALTY_SUMMARY:
      return {
        ...state,
        loyaltySummary: action.payload,
        isLoading: false,
      };
    case REJECTED_LOYALTY_SUMMARY:
      return {
        ...state,
        loyaltySummary: {
          tryAgainLater: true,
        },
        isLoading: false,
      };
    case LOADING_LOYALTY_DOC:
      return {
        ...state,
        isFetchingDOCData: true,
        isError: false,
      };
    case RESOLVED_LOYALTY_DOC:
      return {
        ...state,
        loyaltySummary: {
          ...state.loyaltySummary,
          isDOCActivated: action.payload,
        },
        isFetchingDOCData: false,
        isError: false,
      };
    case REJECTED_LOYALTY_DOC:
      return {
        ...state,
        isFetchingDOCData: false,
        isError: true,
      };
    case LOADING_ACTIVATE_DOC:
      return {
        ...state,
        isDOCActivating: true,
      };
    case RESOLVED_ACTIVATE_DOC:
      return {
        ...state,
        isDOCActivating: false,
        loyaltySummary: {
          ...state.loyaltySummary,
          isDOCActivated: true,
        },
      };
    case REJECTED_ACTIVATE_DOC:
      return {
        ...state,
        isDOCActivating: false,
      };
    default:
      return state;
  }
};
