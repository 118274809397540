import httpWithLogging from 'universal/http-client';
import { getIdToken } from 'client-utils/utilities-amplify';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import {
  LOADING_BG_CARD,
  RESOLVED_BG_CARD,
  REJECTED_BG_CARD,
  RESOLVED_LOYALTY_SUMMARY,
  LOADING_LOYALTY_SUMMARY,
  REJECTED_LOYALTY_SUMMARY,
  LOADING_LOYALTY_DOC,
  RESOLVED_LOYALTY_DOC,
  REJECTED_LOYALTY_DOC,
  LOADING_ACTIVATE_DOC,
  RESOLVED_ACTIVATE_DOC,
  REJECTED_ACTIVATE_DOC,
} from '../../constants';
import { isToday } from './NewBgCreditCardPage/helpers';

export function getBGcardForMyAccount() {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { session, amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 8000);

    dispatch({ type: LOADING_BG_CARD });

    getIdToken(amplifyConfig, isNewCartCheckoutEnabled(state), disableATGToggle)
      .then(tokenInfo => {
        const ucaProfileId = tokenInfo?.user?.sub;
        const headers = {
          accountId: disableATGToggle
            ? ucaProfileId
            : session?.DYN_USER_ID || '',
          authorization: tokenInfo.idToken,
        };
        const callString = `${NMConfig.API_MY_ACCOUNT_BG_CARD}`;
        return requestApi.get(callString, { headers });
      })
      .then(res => {
        if (res && res.data) {
          return dispatch({
            type: RESOLVED_BG_CARD,
            payload: res.data,
          });
        } else {
          return dispatch({ type: REJECTED_BG_CARD });
        }
      })
      .catch(() => dispatch({ type: REJECTED_BG_CARD }));
  };
}

export function getLoyaltySummary(loyaltyProfileId) {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 8000);

    dispatch({ type: LOADING_LOYALTY_SUMMARY });

    getIdToken(amplifyConfig, isNewCartCheckoutEnabled(state), disableATGToggle)
      .then(tokenInfo => {
        const ucaId = tokenInfo?.user?.sub;
        const callString = `${NMConfig.API_LOYALTY_SUMMARY}`;
        const headers = { ucaId, authorization: tokenInfo.idToken };
        const body = { ucaId, loyaltyProfileId };
        return requestApi.post(callString, body, { headers });
      })
      .then(res => {
        if (res && res.data) {
          return dispatch({
            type: RESOLVED_LOYALTY_SUMMARY,
            payload: res.data,
          });
        } else {
          return dispatch({ type: REJECTED_LOYALTY_SUMMARY });
        }
      })
      .catch(() => {
        return dispatch({ type: REJECTED_LOYALTY_SUMMARY });
      });
  };
}

export function getDateOfChoosing() {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_LOYALTY_DOC });
    const state = getState();
    const { amplifyConfig } = state;
    const requestURI = `${NMConfig.API_LOYALTY_DOC}`;
    const requestApi = httpWithLogging(state, 10000);
    return getIdToken(
      amplifyConfig,
      isNewCartCheckoutEnabled(state),
      true,
      true,
    )
      .then(info => {
        const ucaId = info?.user?.sub || null;
        const headers = { ucaId, authorization: info.idToken };
        return requestApi.get(requestURI, { headers });
      })

      .then(successResponse => {
        const shouldDisableActivation =
          successResponse?.data?.docFlag ||
          (!successResponse?.data?.docFlag &&
            isToday(successResponse?.data?.docDate?.[0]));
        return Promise.all([
          dispatch({
            type: RESOLVED_LOYALTY_DOC,
            payload: shouldDisableActivation,
          }),
        ]);
      })
      .catch(() => {
        dispatch({ type: REJECTED_LOYALTY_DOC });
      });
  };
}

export function activateDoublePoint() {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_ACTIVATE_DOC });
    const state = getState();
    const { amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 10000);
    dispatch({ type: LOADING_ACTIVATE_DOC });
    getIdToken(amplifyConfig, isNewCartCheckoutEnabled(state), true)
      .then(tokenInfo => {
        const ucaId = tokenInfo?.user?.sub;
        const requestURI = `${NMConfig.API_LOYALTY_DOC}`;
        const headers = { ucaId, authorization: tokenInfo.idToken };
        const body = {
          docDate: new Date().toISOString().slice(0, 10),
          source: 'BGONLINE',
        };
        return requestApi.put(requestURI, body, { headers });
      })
      .then(successResponse => {
        return Promise.all([
          dispatch({
            type: RESOLVED_ACTIVATE_DOC,
            payload: successResponse?.data,
          }),
        ]);
      })
      .catch(() => {
        dispatch({ type: REJECTED_ACTIVATE_DOC });
      });
  };
}
