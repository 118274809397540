import React from 'react';
import Heading from './components/Heading/heading';
import './productDetails.scss';

const ProductDetails = ({
  product = {},
  narvarTest = {},
  allowAffirm = false,
}) => {
  return (
    <div className="product-details__Optimized">
      <div className="product-details__Optimized__heading">
        <Heading
          product={product}
          narvarTest={narvarTest}
          allowAffirm={allowAffirm}
        />
      </div>
    </div>
  );
};

export default ProductDetails;
