import { openModal } from '@bgo-ui/common/client/common/components/Modal/actions';
import httpWithLogging from '@bgo-ui/common/universal/http-client';
import { getIdTokenAndUserInfo } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import {
  ACCOUNT_DETAILS_CLOSE_EDIT,
  ACCOUNT_DETAILS_OPEN_EDIT,
  LOADING_CHANGE_PASSWORD,
  LOADING_USER_INFO,
  LOADING_UPDATE_BASIC_ATTRIBUTES,
  REJECTED_CHANGE_PASSWORD,
  REJECTED_UPDATE_BASIC_ATTRIBUTES,
  REJECTED_USER_INFO,
  RESOLVED_CHANGE_PASSWORD,
  RESOLVED_UPDATE_BASIC_ATTRIBUTES,
  RESOLVED_USER_INFO,
  LOADING_ADDITIONAL_USER_INFO,
  RESOLVED_ADDITIONAL_USER_INFO,
  REJECTED_ADDITIONAL_USER_INFO,
  LOADING_UPDATE_ADDITIONAL_USER_INFO,
  RESOLVED_UPDATE_ADDITIONAL_USER_INFO,
  REJECTED_UPDATE_ADDITIONAL_USER_INFO,
  LOADING_SEND_VERIFICATION_CODE,
  RESOLVED_SEND_VERIFICATION_CODE,
  REJECTED_SEND_VERIFICATION_CODE,
  genericError,
  INVALID_TOTP_MSG,
  verificationCodeDisplayError,
  EMAIL_ALREADY_EXISTS_MSG,
} from '../../constants';

export function openAccountDetailsEdit(sectionName) {
  return dispatch =>
    dispatch({
      type: ACCOUNT_DETAILS_OPEN_EDIT,
      payload: sectionName,
    });
}

export function closeAccountDetailsEdit() {
  return dispatch => dispatch({ type: ACCOUNT_DETAILS_CLOSE_EDIT });
}

export function getAccountDetailsForMyAccount() {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { amplifyConfig } = state;

    dispatch({ type: LOADING_USER_INFO });
    dispatch({ type: LOADING_ADDITIONAL_USER_INFO });

    const requestApi = httpWithLogging(state, 8000);

    return getIdTokenAndUserInfo(
      amplifyConfig,
      isNewCartCheckoutEnabled(state),
      true,
      disableATGToggle,
    )
      .then(info => {
        dispatch({
          type: RESOLVED_USER_INFO,
          payload: info?.userInfo || null,
        });

        const ucaId = info?.userInfo?.attributes?.sub || null;
        const idToken = info?.tokenInfo?.idToken || null;
        const headers = {
          ucaId,
          authorization: idToken,
        };
        const callString = `${NMConfig.API_MY_ACCOUNT_ADDITIONAL_INFO}`;

        return requestApi.get(callString, { headers });
      })
      .then(res => {
        if (res && res.data) {
          const payload = {
            birthday: res?.data?.birthday?.value || '',
            mostlyInterestedIn: res?.data?.mostlyInterestedIn?.value || '',
          };
          return dispatch({
            type: RESOLVED_ADDITIONAL_USER_INFO,
            payload,
          });
        } else {
          return dispatch({ type: REJECTED_ADDITIONAL_USER_INFO });
        }
      })
      .catch(() => {
        dispatch({ type: REJECTED_USER_INFO });
        return dispatch({ type: REJECTED_ADDITIONAL_USER_INFO });
      });
  };
}

export function editAccountDetails(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const updatePhoneNumber = state.toggles.LOY_PHONE_UPDATE;
    const { amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 8000);

    dispatch({ type: LOADING_UPDATE_BASIC_ATTRIBUTES });

    return getIdTokenAndUserInfo(
      amplifyConfig,
      isNewCartCheckoutEnabled(state),
      true,
      disableATGToggle,
    )
      .then(info => {
        const accountId =
          info?.userInfo?.attributes?.preferred_username || null;
        const idToken = info?.tokenInfo?.idToken || null;
        const headers = {
          accountId,
          authorization: idToken,
        };
        const body = {
          ...payload,
        };
        const callString = updatePhoneNumber
          ? `${NMConfig.API_MY_ACCOUNT_UPDATE_PHONE_NUMBER}`
          : `${NMConfig.API_MY_ACCOUNT_UPDATE_BASIC_ATTRIBUTES}`;
        return requestApi.post(callString, body, { headers });
      })
      .then(res => {
        if (res && res.status === 200) {
          return dispatch({
            type: RESOLVED_UPDATE_BASIC_ATTRIBUTES,
            payload,
          });
        } else {
          return dispatch({
            type: REJECTED_UPDATE_BASIC_ATTRIBUTES,
            payload: {
              error: genericError,
            },
          });
        }
      })
      .catch(err => {
        let error = genericError;
        const message = err?.response?.data?.message;

        if (message === INVALID_TOTP_MSG) {
          error = verificationCodeDisplayError;
        } else if (message === EMAIL_ALREADY_EXISTS_MSG) {
          error = message;
        }
        return dispatch({
          type: REJECTED_UPDATE_BASIC_ATTRIBUTES,
          payload: {
            error,
          },
        });
      });
  };
}

export const openChangeEmailModal = () => {
  return dispatch =>
    dispatch(
      openModal({
        type: 'ChangeEmailModal',
        className: 'change-email-modal',
        modalFix: true,
        closeDisabled: true,
      }),
    );
};

export const openChangePasswordModal = () => {
  return dispatch =>
    dispatch(
      openModal({
        type: 'ChangePasswordModal',
        className: 'change-password-modal',
        modalFix: true,
        closeDisabled: true,
      }),
    );
};

export function sendVerificationCode(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 8000);
    dispatch({ type: LOADING_SEND_VERIFICATION_CODE });
    getIdTokenAndUserInfo(
      amplifyConfig,
      isNewCartCheckoutEnabled(state),
      true,
      disableATGToggle,
    )
      .then(info => {
        const accountId =
          info?.userInfo?.attributes?.preferred_username || null;
        const idToken = info?.tokenInfo?.idToken || null;
        const headers = {
          accountId,
          authorization: idToken,
        };
        const callString = `${NMConfig.API_MY_ACCOUNT_SEND_VERIFICATION_CODE}`;
        return requestApi.get(callString, { headers });
      })
      .then(res => {
        if (res && res.status === 200) {
          dispatch({
            type: RESOLVED_SEND_VERIFICATION_CODE,
            payload,
          });
        } else {
          dispatch({ type: REJECTED_SEND_VERIFICATION_CODE });
        }
      })
      .catch(() => {
        dispatch({ type: REJECTED_SEND_VERIFICATION_CODE });
      });
  };
}

export function changeAccountPassword(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 8000);
    dispatch({ type: LOADING_CHANGE_PASSWORD });
    getIdTokenAndUserInfo(
      amplifyConfig,
      isNewCartCheckoutEnabled(state),
      true,
      disableATGToggle,
    )
      .then(info => {
        const accountId =
          info?.userInfo?.attributes?.preferred_username || null;
        const idToken = info?.tokenInfo?.idToken || null;
        const headers = {
          accountId,
          authorization: idToken,
        };
        const body = {
          ...payload,
        };
        const callString = `${NMConfig.API_MY_ACCOUNT_CHANGE_PASSWORD}`;
        return requestApi.post(callString, body, { headers });
      })
      .then(res => {
        if (res && res.status === 200) {
          dispatch({
            type: RESOLVED_CHANGE_PASSWORD,
            payload,
          });
        } else {
          dispatch({
            type: REJECTED_CHANGE_PASSWORD,
            payload: {
              error: genericError,
            },
          });
        }
      })
      .catch(err => {
        let error = genericError;
        if (err?.response?.data?.message === INVALID_TOTP_MSG) {
          error = verificationCodeDisplayError;
        }
        dispatch({
          type: REJECTED_CHANGE_PASSWORD,
          payload: {
            error,
          },
        });
      });
  };
}

export function updateAdditionalAccountInfo(payload, callback = null) {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { amplifyConfig } = state;
    dispatch({ type: LOADING_UPDATE_ADDITIONAL_USER_INFO });
    const requestApi = httpWithLogging(state, 8000);
    getIdTokenAndUserInfo(
      amplifyConfig,
      isNewCartCheckoutEnabled(state),
      true,
      disableATGToggle,
    )
      .then(info => {
        const ucaId = info?.userInfo?.attributes?.sub || null;
        const idToken = info?.tokenInfo?.idToken || null;
        const headers = {
          ucaId,
          authorization: idToken,
        };
        const body = {};
        Object.entries(payload).forEach(item => {
          body[item[0]] = { value: item[1], updatedTs: Date.now() };
        });
        const callString = `${NMConfig.API_MY_ACCOUNT_ADDITIONAL_INFO}`;
        return requestApi.put(callString, body, { headers });
      })
      .then(res => {
        if (res && res.status === 200) {
          dispatch({
            type: RESOLVED_UPDATE_ADDITIONAL_USER_INFO,
            payload,
          });
          if (callback && callback.redirect) {
            callback.redirect();
          }
        } else {
          dispatch({ type: REJECTED_UPDATE_ADDITIONAL_USER_INFO });
          if (callback && callback.redirect) {
            callback.redirect();
          }
        }
      })
      .catch(() => {
        dispatch({ type: REJECTED_UPDATE_ADDITIONAL_USER_INFO });
        if (callback && callback.redirect) {
          callback.redirect();
        }
      });
  };
}
