import React from 'react';
import { connect } from 'react-redux';
import { SubTitle2 } from 'bgo-common/Styleguide/Typography';
import get from 'lodash/get';
import partition from 'lodash/partition';
import SalePriceWithPromotion from 'shared/components/SalePriceWithPromotion/salePriceWithPromotion';
import './heading.scss';
import ProductHeading from './productHeading';
import AffirmCta from '../../../../../AffirmCta/AffirmCta';

// eslint-disable-next-line no-unused-vars
const Heading = ({
  product,
  narvarTest,
  allowAffirm = false,
  chanelPdpAffirm,
}) => {
  const { price = {}, isZeroDollarProduct = false } = product;
  const { promotions = [] } = product;
  const { isChanel = false } = product;

  const filterPromotions = promotions => {
    return promotions
      ? partition(promotions, { showPromoPrice: true })
      : [[], []];
  };

  const [promotionWithPrice] = filterPromotions(promotions);
  const promotionAdornmentColor = get(promotions, ['0', 'priceAdornmentColor']);

  const itemPromotionalPrice = price.promotionalPrice;
  const parentheticalCharge = get(product, 'details.parentheticalCharge');
  return (
    <div className="product-heading">
      <ProductHeading product={product} />
      <div className="product-heading__price">
        {!isZeroDollarProduct && (
          <SalePriceWithPromotion
            price={price}
            promotionAdornmentColor={promotionAdornmentColor}
            promotions={promotionWithPrice}
            promotionalPrice={itemPromotionalPrice}
          />
        )}
      </div>

      {chanelPdpAffirm
        ? !isChanel && allowAffirm && <AffirmCta product={product} />
        : allowAffirm && <AffirmCta product={product} />}

      {parseInt(parentheticalCharge, 10) ? (
        <div className="shipping-price">
          <SubTitle2>Shipping: ${parentheticalCharge}</SubTitle2>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  chanelPdpAffirm: get(state, 'toggles.CHANEL_PDP_AFFIRM', ''),
});

export default connect(mapStateToProps)(Heading);
