import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';
import Measure from 'react-measure';
import { isMobile } from 'client-utils/utilities-page';
import { referralSourceClicked } from 'client/common/actions/actions-page';
import { isGiftCategory } from 'client-utils/utilities-navigation';
import {
  loseFocus,
  putComponentInFocus,
} from 'shared/components/App/app-actions';
import { DESIGNER_CAT_ID, CUSP_CAT_ID } from 'storefront/components/constants';
import { ENTER_KEYCODE, ESC_KEYCODE } from 'client-utils/keyCodes';
import { EyeBrow1 } from '@bgo-ui/common/Styleguide/Typography';
import StandardDrawer from './StandardDrawer/standardDrawer';
import DesignersDrawer from './DesignersDrawer/designersDrawer';
import CuspDrawer from './CuspDrawer/cuspDrawer';
import FullWidthDrawer from './FullWidthDrawer/fullWidthDrawer';
import ContentItem from '../../../../client/cms/components/contentItem/contentItem';
import { determineAlignmentStyle } from './drawerPositioningHelper';
import './drawer.scss';

const selectDrawerTypeBasedOnId = (siloId, drawerProps) => {
  switch (siloId) {
    case DESIGNER_CAT_ID: {
      return DesignersDrawer({ ...drawerProps });
    }
    case CUSP_CAT_ID: {
      return CuspDrawer({ ...drawerProps });
    }
    default:
      return StandardDrawer({ ...drawerProps });
  }
};

export const getPromoContent = (
  silo,
  isDrawerPromoFromAEM,
  isCmsDrawerAssets,
) => {
  if (silo.attributes) {
    if (isCmsDrawerAssets && silo.attributes.cms) {
      return (
        <ContentItem cmsContentItem={silo.attributes.cms} placement="Main" />
      );
    }
    if (!isCmsDrawerAssets && isDrawerPromoFromAEM) {
      if (
        silo.attributes.aem &&
        silo.attributes.aem.rows[0].columns[0].componentType ===
          'adaptive-image'
      ) {
        const dataObj = silo.attributes.aem.rows[0].columns[0];
        return (
          <div className="flyout-promo-wrapper">
            <a className="clear-link" href={dataObj.properties.linkurl}>
              <picture>
                <source
                  media="(min-width: 1025px)"
                  srcSet={`${dataObj.properties.images.desktop.imagesrc}`}
                />
                <img
                  loading="lazy"
                  className="flyout-promo-img"
                  alt={
                    dataObj.properties.title
                      ? dataObj.properties.title
                      : 'BG promo image'
                  }
                  src={`${dataObj.properties.images.desktop.imagesrc}`}
                />
              </picture>
            </a>
          </div>
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerWidth: 0,
    };
    this.siloHoverDelaytimer = null;
    this.setWidth = this.setWidth.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps === this.props) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    this.siloHoverDelaytimer = null;
  }

  setWidth(dimensions) {
    this.setState({ drawerWidth: dimensions.width });
  }

  render() {
    const DRAWER_PAGE_TYPE = 'drawer';
    const {
      renderDrawersOnDesktopOnly,
      isDomLoadComplete,
      silo,
      openDrawer,
      closeDrawer,
      componentInFocus,
      renderDrawersOnDomLoad,
    } = this.props;
    const isSubCategoryAvailable = !isEmpty(silo.categories);
    const positioning = {
      drawerWidth: this.state.drawerWidth,
      navBarLeft: this.props.navBarLeft,
      navBarRight: this.props.navBarRight,
      siloLeft: this.siloRef ? this.siloRef.getBoundingClientRect().left : 0,
      siloRight: this.siloRef ? this.siloRef.getBoundingClientRect().right : 0,
    };

    const alignmentStyle = determineAlignmentStyle(positioning);
    const { setWidth } = this;

    const classNameWithDrawerId = `.drawer-container-${silo.id}`;
    const isDrawerOpen = componentInFocus === classNameWithDrawerId;

    const toggleDrawer = e => {
      if (isDrawerOpen) {
        closeDrawer(classNameWithDrawerId);
        window.location = silo.url;
        e.preventDefault();
      } else {
        openDrawer(classNameWithDrawerId);
        e.preventDefault();
      }
    };

    const validateAndSetCookie = (e, siloName) => {
      if (e.target.localName === 'a' || e.target.localName === 'span') {
        referralSourceClicked(siloName, DRAWER_PAGE_TYPE);
      }
    };

    const onCategoryKeyDown = (
      e,
      isSubCategoryAvailable,
      classNameWithDrawerId,
    ) => {
      if (
        isSubCategoryAvailable &&
        e.which === ENTER_KEYCODE &&
        e.target.className === 'silo-link'
      ) {
        openDrawer(classNameWithDrawerId);
        e.preventDefault();
      } else if (e.which === ESC_KEYCODE) {
        closeDrawer(classNameWithDrawerId);
      }
    };

    const onFocusOut = e => {
      const currentParent = e.currentTarget
        ? e.currentTarget.querySelector('.silo-group')
        : null;
      const allFocusableElements = currentParent
        ? currentParent.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
          )
        : [];
      const lastFocusableElement = allFocusableElements.length
        ? allFocusableElements[allFocusableElements.length - 1]
        : null;
      if (lastFocusableElement === e.target) {
        closeDrawer(this.props.componentInFocus);
      }
    };
    const drawerProps = {
      silo,
      style: alignmentStyle,
      closeFunction: () => closeDrawer(classNameWithDrawerId),
      delayHoverOnSilos: false,
      getPromoContent: () =>
        getPromoContent(
          silo,
          this.props.isDrawerPromoFromAEM,
          this.props.isCmsDrawerAssets,
        ),
      getPlpSinglePage: {
        isPLPSinglePageOn: this.props.isPLPSinglePageOn,
        templateType: this.props.templateType,
        router: this.props.router,
      },
    };
    const drawer =
      silo.id !== DESIGNER_CAT_ID
        ? FullWidthDrawer({ ...drawerProps })
        : selectDrawerTypeBasedOnId(silo.id, drawerProps);
    const openDrawerIfSubCategoryIsAvailableAndDrawerIsClosed = () => {
      clearTimeout(this.siloHoverDelaytimer);
      isSubCategoryAvailable && !isDrawerOpen
        ? openDrawer(classNameWithDrawerId)
        : false;
    };
    const closeDrawerIfAlreadyOpen = () => {
      if (isDrawerOpen) {
        const { timeout = 750 } = this.props.siloDrawerHoverIntent;
        this.siloHoverDelaytimer = setTimeout(() => {
          closeDrawer(classNameWithDrawerId);
        }, timeout);
      }
    };

    const renderNow = () => {
      const ignoreDomLoadComplete = true;
      let returnVal = renderDrawersOnDomLoad
        ? isDomLoadComplete
        : ignoreDomLoadComplete;
      if (returnVal && renderDrawersOnDesktopOnly) {
        returnVal = !isMobile();
      }
      return returnVal;
    };

    return (
      <div
        ref={ref => {
          this.siloRef = ref;
        }}
        className={classNames(
          'make-relative',
          `drawer-container-${silo.id}`,
          { active: isDrawerOpen },
          {
            'gift-image-border-new': isGiftCategory(
              silo,
              this.props.giftSiloToggle,
            ),
          },
        )}
        onMouseEnter={openDrawerIfSubCategoryIsAvailableAndDrawerIsClosed}
        onMouseLeave={closeDrawerIfAlreadyOpen}
        onClick={e => validateAndSetCookie(e, silo.name)}
        onKeyDown={e =>
          onCategoryKeyDown(e, isSubCategoryAvailable, classNameWithDrawerId)}
        onBlur={onFocusOut}
      >
        <a
          className={classNames('silo-link', {
            'clickable-silo':
              this.props.siloClickableToggle || silo.isPromoSilo,
            'silo-link__gift-image-link-new': isGiftCategory(
              silo,
              this.props.giftSiloToggle,
            ),
          })}
          itemProp="url"
          role="button"
          aria-expanded={isDrawerOpen}
          href={silo.url}
          data-siloid={silo.id}
          onTouchEnd={toggleDrawer}
        >
          <span itemProp="name">
            <EyeBrow1>{silo.name.toLowerCase()}</EyeBrow1>
          </span>
        </a>
        {renderNow() && (
          <Measure
            whitelist={['width']}
            includeMargin={false}
            onMeasure={setWidth}
          >
            {drawer}
          </Measure>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  navBarLeft: state.navigation.position.navBarLeft,
  navBarRight: state.navigation.position.navBarRight,
  componentInFocus: state.app.componentInFocus,
  giftSiloToggle: state.toggles.GIFT_SILO_IMAGE,
  haveAppSetDomLoadComplete: get(
    state.toggles,
    'HAVE_APP_SET_DOMLOADCOMPLETE',
    false,
  ),

  renderDrawersOnDomLoad: get(
    state.toggles,
    'SHOW_DRAWERS_ON_DOMLOAD_PHASE3',
    false,
  ),
  renderDrawersOnDesktopOnly: get(
    state.toggles,
    'RENDER_DRAWERS_ON_DESKTOP_ONLY',
    false,
  ),
  isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
  siloDrawerHoverIntent: get(state, 'navigation.siloDrawerHoverIntent', {}),
  isCmsDrawerAssets: get(state, 'toggles.CMS_DRAWER_ASSETS', false),
  isDrawerPromoFromAEM: get(state, 'toggles.DRAWER_PROMO_ASSET_FROM_AEM', true),
  improvePerformanceOfStyleToggle: get(
    state,
    'toggles.IMPROVE_PERFORMANCE_OF_STYLE',
    false,
  ),
  isPLPSinglePageOn: get(state, 'toggles.SINGLE_PAGE_PLP_NAVIGATION', false),
  templateType: get(state, 'productListPage.products.templateType', ''),
  siloClickableToggle: get(state, 'toggles.SILO_CLICKABLE_TOGGLE', true),
});

const mapDispatchToProps = {
  closeDrawer: loseFocus,
  openDrawer: putComponentInFocus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Drawer);
