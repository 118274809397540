import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import * as PropTypes from 'prop-types';
import { getOrdersForMyAccount } from './actions';
import Image from '../../../../../../../bgo-ui-pdp/src/client/components/pdp/components/ProductPage/components/Image/image';
import './MyOrders.scss';

const orderDate = 'ORDER DATE: ';
const viewOrderDetails = 'Click to view more details';

const Order = ({ orderDetailsClick, orders }) => {
  const extraOrders = orders.slice(3);
  const previewOrders = orders.slice(0, 4);
  const orderStatuses = uniqBy(map(orders, 'orderStatus'));
  const status = 'Click to view more details';

  return (
    <div
      className="myorders-item "
      onClick={() => orderDetailsClick(previewOrders[0].orderId)}
      onKeyPress={() => orderDetailsClick(previewOrders[0].orderId)}
    >
      <div className="myOrderDetails">
        <p className="myOrderDate">
          {orderDate}
          {previewOrders[0].orderDate}
        </p>
        <p>{previewOrders[0].formattedOrderId}</p>

        {orderStatuses.length > 1 ? (
          <h6 className="orderStatus">{viewOrderDetails}</h6>
        ) : (
          <h6 className="orderStatus">{status}</h6>
        )}
      </div>
      <div className="img-container">
        {previewOrders.map((order, i) => {
          return (
            <div className=" img-wrapper">
              <Image src={order.product.imageUrl} />
              {i === 3 && extraOrders.length > 1 && (
                <h2 className="maskItemContainerText">+{extraOrders.length}</h2>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const MyOrders = ({ myAccountOrders, orderDetailsClick }) => {
  const totalOrders = orderBy(
    Object.values(groupBy(myAccountOrders, 'formattedOrderId')),
    '[0].orderDate',
    'desc',
  );
  return (
    <div className="orders">
      {!isEmpty(myAccountOrders) &&
        Object.values(totalOrders).map(orders => (
          <Order orderDetailsClick={orderDetailsClick} orders={orders} />
        ))}
    </div>
  );
};

const mapStateToProps = state => ({
  myAccountOrders: state?.myaccount?.myAccountOrders || true,
});

const mapDispatchToProps = {
  getOrdersForMyAccount,
};

MyOrders.defaultProps = {
  myAccountOrders: [],
};

MyOrders.propTypes = {
  myAccountOrders: PropTypes.array,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyOrders);
