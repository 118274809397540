import React, { Fragment, useEffect } from 'react';
import { func, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import {
  PAGE_ID_REWARDS,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import Spinner from 'shared/components/Spinner/simpleSpinner';
import { useConstructor } from '../../../../utilities/hooks';
import MyAccountMobileBackHeader from '../MyAccountPage/MyAccountBackHeader/MyAccountMobileBackHeader';
import { getContentForMyAccount, setResolvedPage } from '../../actions';
import { getTitleText } from '../../helpers';
import {
  RESOLVED_REWARDS_PAGE,
  RESOLVED_GIFTCARDS_PAGE,
} from '../../constants';
import GiftCards from './GiftCards/GiftCards';
import InCircleRewards from './InCircleRewards/InCircleRewards';

/**
 * @function MyRewardsPage
 * @param {Function} props.getContentForMyAccount
 * @param {Object} props.myAccountContent
 * @param {Function} props.setPageId
 * @param {Function} props.setResolvedPage
 * @returns {React.ReactElement}
 */
const MyRewardsPage = ({
  getContentForMyAccount,
  myAccountContent,
  setPageId,
  setResolvedPage,
  isLoading,
  bgoLoyaltyToggle,
}) => {
  useConstructor(() => {
    setPageId(PAGE_ID_REWARDS);
  });

  useEffect(() => {
    setResolvedPage(
      bgoLoyaltyToggle ? RESOLVED_GIFTCARDS_PAGE : RESOLVED_REWARDS_PAGE,
    );
    getContentForMyAccount();
  }, []);

  const titleParam = bgoLoyaltyToggle
    ? 'MYREWARDS-TITLE-LOYALTY'
    : 'MYREWARDS-TITLE';
  const title = getTitleText(
    myAccountContent ? myAccountContent[titleParam] : null,
  );

  return isLoading ? (
    <Spinner />
  ) : (
    <Fragment>
      <MyAccountMobileBackHeader title={title} />
      <h1 className="my-account-page__main-header desktop-tablet-only">
        {title}
      </h1>
      <GiftCards />
      {!bgoLoyaltyToggle && <InCircleRewards />}
    </Fragment>
  );
};

MyRewardsPage.propTypes = {
  getContentForMyAccount: func.isRequired,
  myAccountContent: object.isRequired,
  setPageId: func.isRequired,
  setResolvedPage: func.isRequired,
  isLoading: bool.isRequired,
};

const mapStateToProps = state => ({
  myAccountContent: state?.myaccount?.myAccountContent,
  isLoading:
    state?.api?.rewards?.loading || state?.api?.content?.loading || false,
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
});

const mapDispatchToProps = {
  getContentForMyAccount,
  setResolvedPage,
  setPageId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyRewardsPage);
