import React from 'react';
import { connect } from 'react-redux';
import InCircleLogoLanding from 'assets/images/InCircleLogoLanding.svg';
import IncircleConciergeLifetimeExperience from './components/IncircleConciergeLifetimeExperience';
import IncircleConciergeContactUs from './components/IncircleConciergeContactUs';
import IncircleConciergeBespokeExperiences from './components/IncircleConciergeBespokeExperiences';
import IncircleConciergeOurPartners from './components/IncircleConciergeOurPartners';
import IncircleConciergeTerms from './components/IncircleConciergeTerms';
import './BgIncircleConciergePage.scss';

const BgIncircleConciergePage = () => {
  return (
    <div className="bg-incircle-concierge-page">
      <section className="bg-incircle-concierge-page__header">
        <img src={InCircleLogoLanding} alt="InCircle" alr="InCircle" />
      </section>
      <section className="bg-incircle-concierge-page__content">
        <IncircleConciergeLifetimeExperience />
        <IncircleConciergeContactUs />
        <IncircleConciergeBespokeExperiences />
        <IncircleConciergeOurPartners />
        <IncircleConciergeTerms />
      </section>
    </div>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BgIncircleConciergePage);
