import {
  FAVORITES_LIMIT_EXCEEDED_MESSAGE,
  FAVORITE_MESSAGE,
  FAVORITE_UTAG_EVENT_NAME,
  FAVORITE_UTAG_ITEM_TYPE,
} from 'bgo-common/client/favorites/components/FavoritesPage/constants';
import {
  setRecentlyAddedItemCount,
  showMiniCart,
  updateMiniCartItemCount,
  showInternationalMiniCart,
} from 'bgo-common/components/Header/MiniCart/miniCart-actions';
import {
  updateDtMiniCart,
  showDtMinicart,
  startTimer,
} from 'bgo-common/components/Header/MiniCartDT/miniCartDT-actions';
import { PDP_YMAL_AB_TEST } from 'shared/actions/actions-page';
import screenReaderAnnouncer from 'bgo-common/components/ScreenReaderAlert/screenReaderAnnouncer-actions';
import * as pageUtils from 'client-utils/utilities-page';
import $ from 'jquery';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import log from 'server/utilities/logger';
import { localStorage } from 'window-or-global';
import { getDynamicImageURLForCustomizedProduct } from 'productpage/components/Monogram/dynamicImageUtils/getDynamicScene7ImageURL';
import getCustomizationOption from 'productpage/selectors/getCustomizationOption';
import getProductData from 'productpage/selectors/getProduct';
import { getBuckleFinishDisplayName } from 'productpage/selectors/getSelectedBuckleFinishIndex';
import getSelectedCustomizationAllTypesChoice from 'productpage/selectors/getSelectedCustomizationAllTypesChoice';
import getSelectedCustomizationAllTypesValue from 'productpage/selectors/getSelectedCustomizationAllTypesValue';
import getSelectedCustomizationChoice from 'productpage/selectors/getSelectedCustomizationChoice';
import getSizeSkus from 'productpage/selectors/getSizeSkus';
import { stringify } from 'query-string';
import reactCookie from 'react-cookie';
import { openModal } from 'shared/components/Modal/actions';
import httpWithLogging, {
  httpWithLoggingWithoutCustomHeaders,
} from 'universal/http-client';
import { buildCookieString } from 'universal/utilities-cookies';
import sumBy from 'lodash/sumBy';
import findIndex from 'lodash/findIndex';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import {
  getIdToken,
  getUserDataWithGuestIdentityOn,
} from '@bgo-ui/common/client/utilities/utilities-amplify';
import { updateUcaProfileCountCartItems } from '@bgo-ui/common/client/utilities/ucaProfileDataCookies';

import { MONOGRAM_HEADER, BUCKLE_FINISH } from '../../constants';

export const LOADING_SAMEDAYDELIVERY = 'LOADING_SAMEDAYDELIVERY';
export const RESOLVED_SAMEDAYDELIVERY = 'RESOLVED_SAMEDAYDELIVERY';
export const REJECTED_SAMEDAYDELIVERY = 'REJECTED_SAMEDAYDELIVERY';
export const LOADING_RECOMMENDATIONS = 'LOADING_RECOMMENDATIONS';
export const RESOLVED_RECOMMENDATIONS = 'RESOLVED_RECOMMENDATIONS';
export const REJECTED_RECOMMENDATIONS = 'REJECTED_RECOMMENDATIONS';
export const RESET_RECOMMENDATIONS = 'RESET_RECOMMENDATIONS';
export const LOADING_PRODUCT = 'LOADING_PRODUCT';
export const RESOLVED_PRODUCT = 'RESOLVED_PRODUCT';
export const REJECTED_PRODUCT = 'REJECTED_PRODUCT';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const LOADING_RELATED_ITEMS = 'LOADING_RELATED_ITEMS';
export const RESOLVED_RELATED_ITEMS = 'RESOLVED_RELATED_ITEMS';
export const REJECTED_RELATED_ITEMS = 'REJECTED_RELATED_ITEMS';
export const RESET_RELATED_ITEMS = 'RESET_RELATED_ITEMS';
export const SET_SELECTED_SIZE_INDEX = 'SET_SELECTED_SIZE_INDEX';
export const SET_QL_SELECTED_SIZE_INDEX = 'SET_QL_SELECTED_SIZE_INDEX';
export const SET_QL_SELECTED_COLOR_INDEX = 'SET_QL_SELECTED_COLOR_INDEX';
export const SET_SELECTED_COLOR_INDEX = 'SET_SELECTED_COLOR_INDEX';
export const SET_SELECTED_COLOR_CODE = 'SET_SELECTED_COLOR_CODE';
export const SET_HOVERED_COLOR_INDEX = 'SET_HOVERED_COLOR_INDEX';
export const SET_HOVERED_SIZE_INDEX = 'SET_HOVERED_SIZE_INDEX';
export const INCREASE_PRODUCT_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_PRODUCT_QUANTITY = 'DECREASE_QUANTITY';
export const UPDATE_PRODUCT_QUANTITY = 'UPDATE_QUANTITY';
export const SET_DELIVERY_DATE = 'SET_DELIVERY_DATE';
export const ZOOM_PRODUCT_IMAGE = 'ZOOM_PRODUCT_IMAGE';
export const ZOOM_PRODUCT_IMAGE_QL = 'ZOOM_PRODUCT_IMAGE_QL';
export const UNSET_PRODUCT_VIDEO = 'UNSET_PRODUCT_VIDEO';
export const SET_PRODUCT_VIDEO = 'SET_PRODUCT_VIDEO';
export const SET_ACTIVE_MEDIA_INDEX = 'SET_ACTIVE_MEDIA_INDEX';
export const ADD_TO_BAG_ERROR = 'ADD_TO_BAG_ERROR';
export const ADD_TO_OUTFITTING_QL_BAG_ERROR = 'ADD_TO_OUTFITTING_QL_BAG_ERROR';
export const ADD_PRODUCT_TO_BAG = 'ADD_PRODUCT_TO_BAG';
export const PLA_ADD_PRODUCT_TO_BAG = 'PLA_ADD_PRODUCT_TO_BAG';
export const QUICK_LOOK_ADD_PRODUCT_TO_BAG = 'QUICK_LOOK_ADD_PRODUCT_TO_BAG';
export const OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG =
  'OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG';
export const BOPS_ERROR = 'BOPS_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_OUTFITTING_QL_ERROR = 'CLEAR_OUTFITTING_QL_ERROR';
export const GET_STORES = 'GET_STORES';
export const CLEAR_STORES = 'CLEAR_STORES';
export const LOADING_OOS_RECOMMENDATIONS = 'LOADING_OOS_RECOMMENDATIONS';
export const RESOLVED_OOS_RECOMMENDATIONS = 'RESOLVED_OOS_RECOMMENDATIONS';
export const REJECTED_OOS_RECOMMENDATIONS = 'REJECTED_OOS_RECOMMENDATIONS';
export const RESET_OOS_RECOMMENDATIONS = 'RESET_OOS_RECOMMENDATIONS';
export const LOADING_ADD_TO_BAG = 'LOADING_ADD_TO_BAG';
export const RESOLVED_ADD_TO_BAG = 'RESOLVED_ADD_TO_BAG';
export const REJECTED_ADD_TO_BAG = 'REJECTED_ADD_TO_BAG';
export const SET_REPLENISH_INTERVAL = 'SET_REPLENISH_INTERVAL';
export const SET_BOPS_ERROR_FOR_REPLENISHMENT =
  'SET_BOPS_ERROR_FOR_REPLENISHMENT';
export const SET_PRODUCT_DETAIL_FAVORITE_UTAG_DATA =
  'SET_PRODUCT_DETAIL_FAVORITE_UTAG_DATA';
export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';
export const SET_PERSONALIZATION = 'SET_PERSONALIZATION';
export const SET_SELECTED_CUSTOMIZATIONS = 'SET_SELECTED_CUSTOMIZATIONS';
export const SET_SELECTED_CUSTOMIZATIONS_ALL_TYPES =
  'SET_SELECTED_CUSTOMIZATIONS_ALL_TYPES';
export const SET_SELECTED_CUSTOMIZATION_OBJECT =
  'SET_SELECTED_CUSTOMIZATION_OBJECT';
export const SET_SELECTED_CUSTOMIZATIONS_TOUCHED_TRUE =
  'SET_SELECTED_CUSTOMIZATIONS_TOUCHED_TRUE';
export const SET_SELECTED_CUSTOMIZATIONS_SAVEDVALUES =
  'SET_SELECTED_CUSTOMIZATIONS_SAVEDVALUES';
export const SET_SELECTED_CUSTOMIZATIONS_SELECTEDVALUES_FROM_SAVEDVALUES =
  'SET_SELECTED_CUSTOMIZATIONS_SELECTEDVALUES_FROM_SAVEDVALUES';
export const SET_DYNAMIC_IMAGE_URL = 'SET_DYNAMIC_IMAGE_URL';
export const SET_PDP_PAGINATION_CONTEXT = 'SET_PDP_PAGINATION_CONTEXT';
export const RESOLVED_GROUP = 'RESOLVED_GROUP';
export const SET_HOVERED_BUCKLE_FINISH_INDEX =
  'SET_HOVERED_BUCKLE_FINISH_INDEX';
export const SET_SELECTED_BUCKLE_FINISH_INDEX =
  'SET_SELECTED_BUCKLE_FINISH_INDEX';
export const SET_ACTIVE_PDP_TAB = 'SET_ACTIVE_PDP_TAB';
export const BOPS_NO_SELECTION_ERR_MSG_FOR_REPLENISHMENT =
  'BOPS_NO_SELECTION_ERR_MSG_FOR_REPLENISHMENT';
export const LOADING_STYLYZE_RECOMMENDATIONS =
  'LOADING_STYLYZE_RECOMMENDATIONS';
export const RESOLVED_STYLYZE_RECOMMENDATIONS =
  'RESOLVED_STYLYZE_RECOMMENDATIONS';
export const REJECTED_STYLYZE_RECOMMENDATIONS =
  'REJECTED_STYLYZE_RECOMMENDATIONS';
export const REJECTED_MINIFIED_PRODUCT = 'REJECTED_MINIFIED_PRODUCT';
export const RESOLVED_MINIFIED_PRODUCT = 'RESOLVED_MINIFIED_PRODUCT';
export const SET_ACTIVE_STYLE_BOARD = 'SET_ACTIVE_STYLE_BOARD';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SOURCE_QUICK_LOOK = 'SOURCE_QUICK_LOOK';
export const SOURCE_OUTFITTING_QUICK_LOOK = 'SOURCE_OUTFITTING_QUICK_LOOK';
export const RESET_QUICK_LOOK_PRODUCT = 'RESET_QUICK_LOOK_PRODUCT';
export const RESOLVED_QUICK_LOOK_PRODUCT = 'RESOLVED_QUICK_LOOK_PRODUCT';
export const RESOLVED_QUICK_LOOK_PRODUCT_PDP =
  'RESOLVED_QUICK_LOOK_PRODUCT_PDP';
export const RESET_QUICK_LOOK_PRODUCT_PDP = 'RESET_QUICK_LOOK_PRODUCT_PDP';
export const INCREASE_PRODUCT_QL_QUANTITY = 'INCREASE_QL_QUANTITY';
export const DECREASE_PRODUCT_QL_QUANTITY = 'DECREASE_QL_QUANTITY';
export const UPDATE_PRODUCT_QL_QUANTITY = 'UPDATE_QL_QUANTITY';
export const RESOLVED_WEARITWITH_PRODUCTS = 'RESOLVED_WEARITWITH_PRODUCTS';
export const PDP_LOAD_ANALYTICS = 'PDP_LOAD_ANALYTICS';
export const LOADING_STYLYZE_PRODUCT_RECOMMENDATIONS =
  'LOADING_STYLYZE_PRODUCT_RECOMMENDATIONS';
export const RESOLVED_STYLYZE_PRODUCT_RECOMMENDATIONS =
  'RESOLVED_STYLYZE_PRODUCT_RECOMMENDATIONS';
export const REJECTED_STYLYZE_PRODUCT_RECOMMENDATIONS =
  'REJECTED_STYLYZE_PRODUCT_RECOMMENDATIONS';
export const RESOLVED_STYLYZE_MINIFIED_PRODUCT =
  'RESOLVED_STYLYZE_MINIFIED_PRODUCT';
export const REJECTED_STYLYZE_MINIFIED_PRODUCT =
  'REJECTED_STYLYZE_MINIFIED_PRODUCT';
export const LOADING_YAML_PRODUCT_RECOMMENDATIONS =
  'LOADING_YAML_PRODUCT_RECOMMENDATIONS';
export const RESOLVED_YAML_PRODUCT_RECOMMENDATIONS =
  'RESOLVED_YAML_PRODUCT_RECOMMENDATIONS';
export const REJECTED_YAML_PRODUCT_RECOMMENDATIONS =
  'REJECTED_YAML_PRODUCT_RECOMMENDATIONS';
export const RESOLVED_YAML_MINIFIED_RECOMMENDATIONS =
  'RESOLVED_YAML_MINIFIED_RECOMMENDATIONS';
export const RESOLVED_MINIFIED_PRODUCT_INFO = 'RESOLVED_MINIFIED_PRODUCT_INFO';
export const REJECTED_MINIFIED_PRODUCT_INFO = 'REJECTED_MINIFIED_PRODUCT_INFO';
export const LOADING_PDP_RELATED_CATS = 'LOADING_PDP_RELATED_CATS';
export const RESOLVED_PDP_RELATED_CATS = 'RESOLVED_PDP_RELATED_CATS';
export const REJECTED_PDP_RELATED_CATS = 'REJECTED_PDP_RELATED_CATS';

export const LOADING_PDP_BROUGHT_CATS = 'LOADING_PDP_BROUGHT_CATS';
export const RESOLVED_PDP_BROUGHT_CATS = 'RESOLVED_PDP_BROUGHT_CATS';
export const REJECTED_PDP_BROUGHT_CATS = 'REJECTED_PDP_BROUGHT_CATS';

export const CREATE_APPLE_PAY_SESSION = 'CREATE_APPLE_PAY_SESSION';
export const APPLE_PAY_SESSION_SUCCESS = 'APPLE_PAY_SESSION_SUCCESS';
export const APPLE_PAY_SESSION_FAILED = 'APPLE_PAY_SESSION_FAILED';
export const APPLE_PAY_SESSION_USER_DATA = 'APPLE_PAY_SESSION_USER_DATA';
export const REJECTED_APPLE_PAY = 'REJECTED_APPLE_PAY';
export const CHECKING_IF_APPLE_ELIGIBLE = 'CHECKING_IF_APPLE_ELIGIBLE';
export const RESOLVED_APPLE_ELIGIBLE = 'RESOLVED_APPLE_ELIGIBLE';
export const APPLE_PAY_ADDING_TO_CART = 'APPLE_PAY_ADDING_TO_CART';
export const APPLE_PAY_ADD_TO_CART_SUCCESS = 'APPLE_PAY_ADD_TO_CART_SUCCESS';
export const APPLE_PAY_ADD_TO_CART_FAILED = 'APPLE_PAY_ADD_TO_CART_FAILED';

const generalAddToBagErrorMessage =
  'Sorry, we had a problem adding items to your shopping bag. Please refresh and try again or call 888-774-2424.';
const sourceApp = 'WB';

const getRequestQueryStringForStylyze = product => {
  const queryValues = [];
  const selectedColorIndex = product?.options?.selectedColorIndex || 0;
  const sizeSkus = getSizeSkus(product, selectedColorIndex);
  const selectedColor = sizeSkus[0]?.color?.name || '';
  const variantIds = product.id + (selectedColor ? `-${selectedColor}` : '');
  if (selectedColor) {
    queryValues.push(`variantIds=${variantIds}`);
  } else {
    queryValues.push(`prodIds=${product?.metadata?.pimStyle || ''}`);
  }
  return `?${queryValues.join('&')}`;
};

export function getMinifiedProductInfo(productIds) {
  return (dispatch, getState) => {
    const state = getState();
    const { session = '' } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: session?.JSESSIONID || '',
        DYN_USER_ID: session?.DYN_USER_ID || '',
        TLTSID: session?.TLTSID || '',
        DYN_USER_CONFIRM: session?.DYN_USER_CONFIRM || '',
        W2A: session?.W2A || '',
      }),
    };
    const requestApi = httpWithLogging(state, 8000);
    let requestURI = `${NMConfig.API_MINIFIED_PRODUCT_DETAIL_PDP_COMPOSITE}?productIds=${productIds}`;
    const currencyCode = state?.locale?.currencyCode || 'USD';
    const internationalToggle = state?.toggles?.INTERNATIONAL || false;
    if (internationalToggle) {
      if (currencyCode !== 'USD') {
        const currencyQuery = `&currency=${currencyCode}`;
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }
    return requestApi
      .get(requestURI, { headers })
      .then(successResponse => {
        const minifiedProductInfo =
          successResponse?.data?.minifiedProductInfo || [];
        dispatch({
          type: RESOLVED_MINIFIED_PRODUCT_INFO,
          payload: minifiedProductInfo,
        });
      })
      .catch(() =>
        dispatch({
          type: REJECTED_MINIFIED_PRODUCT_INFO,
        }),
      );
  };
}

const getRequestQueryStringForMinifiedInfo = (
  visuallySimilarLsbs,
  ymalEnable,
) => {
  let queryString = '';
  if (ymalEnable) {
    const ymalData = visuallySimilarLsbs || [];
    ymalData.forEach(item => {
      if (item) {
        queryString = `${queryString},${item.productid}`;
      }
    });
  } else {
    const variants = visuallySimilarLsbs[0]?.variants || [];
    variants.forEach(item => {
      if (item) {
        queryString = `${queryString},${item.variantId.split('-')[0]}`;
      }
    });
  }
  return queryString.replace(',', '');
};

export function getMinifiedProductInfoForStylyze(
  visuallySimilarLsbs,
  ymalEnable,
) {
  return (dispatch, getState) => {
    const state = getState();
    const queryStringProductIds = getRequestQueryStringForMinifiedInfo(
      visuallySimilarLsbs,
      ymalEnable,
    );
    const { session = '' } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: session?.JSESSIONID || '',
        DYN_USER_ID: session?.DYN_USER_ID || '',
        TLTSID: session?.TLTSID || '',
        DYN_USER_CONFIRM: session?.DYN_USER_CONFIRM || '',
        W2A: session?.W2A || '',
      }),
    };
    const requestApi = httpWithLogging(state, 8000);
    const compositeEnabled = state?.toggles?.PDP_COMPOSITE_MODE || false;
    let requestURI = compositeEnabled
      ? `${NMConfig.API_MINIFIED_PRODUCT_DETAIL_PDP_COMPOSITE}?productIds=${queryStringProductIds}`
      : `${NMConfig.API_MINIFIED_PRODUCT_DETAIL_PDP}?productIds=${queryStringProductIds}`;
    const currencyCode = state?.locale?.currencyCode || 'USD';
    const internationalToggle = state?.toggles?.INTERNATIONAL || false;
    if (internationalToggle) {
      if (currencyCode !== 'USD') {
        const currencyQuery = `&currency=${currencyCode}`;
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }
    return requestApi
      .get(requestURI, { headers })
      .then(successResponse => {
        const minifiedProductInfo =
          successResponse?.data?.minifiedProductInfo || [];
        if (ymalEnable) {
          const recommendationsData = minifiedProductInfo.map(item => {
            const stylyzeImgUrl = item?.media?.main?.dynamic?.url || '';
            return { ...item, stylyzeImgUrl };
          });
          const uniqueStylyzeRecommendations = recommendationsData.filter(
            (ele, ind) =>
              ind === recommendationsData.findIndex(elem => elem.id === ele.id),
          );
          dispatch({
            type: RESOLVED_YAML_MINIFIED_RECOMMENDATIONS,
            payload: uniqueStylyzeRecommendations,
          });
        } else {
          const variants = visuallySimilarLsbs[0]?.variants || [];
          const recommendationsData = minifiedProductInfo.map(item => {
            const obj = variants.find(
              el => el?.variantId.split('-')[0] === item?.id,
            );
            const stylyzeImgUrl = item?.media?.main?.dynamic?.url || '';
            const variantId = obj?.variantId || '';
            const urlPrefix = variantId.split('-')[1];
            return { ...item, stylyzeImgUrl, urlPrefix };
          });
          const uniqueStylyzeRecommendations = recommendationsData.filter(
            (ele, ind) =>
              ind === recommendationsData.findIndex(elem => elem.id === ele.id),
          );
          dispatch({
            type: RESOLVED_STYLYZE_MINIFIED_PRODUCT,
            payload: uniqueStylyzeRecommendations,
          });
        }
      })
      .catch(() =>
        dispatch({
          type: REJECTED_STYLYZE_MINIFIED_PRODUCT,
        }),
      );
  };
}

export function getStylyzeRecommendations(product) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_STYLYZE_PRODUCT_RECOMMENDATIONS });
    const state = getState();
    const queryString = getRequestQueryStringForStylyze(product);
    const requestApi = httpWithLogging(state, 8000);
    return requestApi
      .get(`${NMConfig.API_STYLYZE_RECOMMENDATIONS}${queryString}`)
      .then(successResponse => {
        const visuallySimilarLsbs =
          successResponse?.data?.visuallySimilarLsbs || [];
        if (!isEmpty(visuallySimilarLsbs)) {
          dispatch({ type: RESOLVED_STYLYZE_PRODUCT_RECOMMENDATIONS });
          getMinifiedProductInfoForStylyze(visuallySimilarLsbs)(
            dispatch,
            getState,
          );
        } else {
          log.error(
            `No recommendations were returned by stylyze for the product ${product?.id}`,
          );
        }
      })
      .catch(() =>
        dispatch({ type: REJECTED_STYLYZE_PRODUCT_RECOMMENDATIONS }),
      );
  };
}

export function getRecommendations(id) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_RECOMMENDATIONS });
    const state = getState();
    const { user, session } = state;
    const headers = {
      Cookie: buildCookieString({
        ...session,
        ...user,
      }),
    };
    const requestApi = httpWithLogging(state, 10000);
    let requestURI = `${NMConfig.API_RECOMMENDATIONS}?productId=${id}`;
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      if (currencyCode !== 'USD') {
        let currencyQuery = `?currency=${currencyCode}`;
        if (requestURI.indexOf('?') > -1 || requestURI.indexOf('&') > -1) {
          currencyQuery = `&currency=${currencyCode}`;
        }
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }
    return requestApi
      .get(requestURI, { headers })
      .then(successResponse =>
        dispatch({
          type: RESOLVED_RECOMMENDATIONS,
          payload: successResponse.data,
        }),
      )
      .catch(() => dispatch({ type: REJECTED_RECOMMENDATIONS }));
  };
}

export function getOOSRecommendations(id) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_OOS_RECOMMENDATIONS });
    const state = getState();
    const { user, session } = state;
    // const RECOMMENDATION_API_TIMEOUT = get(
    //   state,
    //   'apiTimeouts.RECOMMENDATION_API_TIMEOUT',
    // );
    const requestApi = httpWithLogging(state, 10000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
      }),
      PlacementType: 'oos',
      ucid: get(user, 'ucid', ''),
      rid: get(user, 'rid', 'US'),
    };
    let requestURI = `${NMConfig.API_ECMRECOMMENDATIONS}?productId=${id}`;
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      if (currencyCode !== 'USD') {
        let currencyQuery = `?currency=${currencyCode}`;
        if (requestURI.indexOf('?') > -1 || requestURI.indexOf('&') > -1) {
          currencyQuery = `&currency=${currencyCode}`;
        }
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }
    return requestApi
      .get(requestURI, { headers })
      .then(successResponse => {
        dispatch({
          type: RESOLVED_OOS_RECOMMENDATIONS,
          payload: successResponse.data,
        });
      })
      .catch(() => dispatch({ type: REJECTED_OOS_RECOMMENDATIONS }));
  };
}

const getRequestQueryStringForOutFitting = (product, state) => {
  const queryValues = [];
  const selectedColorIndex = get(product, 'options.selectedColorIndex');

  const sizeSkus = getSizeSkus(product, selectedColorIndex);
  const selectedColor = get(sizeSkus[0], 'color.name', '');
  const variantIds = product.id + (selectedColor ? `-${selectedColor}` : '');
  const outfittingQueryParams = get(
    state,
    'productCatalog.outfittingConf.config',
    '',
  );
  queryValues.push(`accId=${NMConfig.OUTFITTING_API_KEY}`);
  selectedColor
    ? queryValues.push(`variantIds=${variantIds}`)
    : queryValues.push(`prodIds=${get(product, 'metadata.pimStyle', '')}`);
  const queryString =
    queryValues.length > 0
      ? `?${queryValues.join('&')}&${outfittingQueryParams}`
      : `?${outfittingQueryParams}`;
  return queryString;
};

const getRequestQueryStringForMinifiedProductDetils = (
  csbList,
  activeStyleBoard,
) => {
  let queryString = '';
  const outfittingChildren = get(csbList[activeStyleBoard], 'children', []);
  outfittingChildren.forEach(item => {
    if (item) {
      queryString = `${queryString},${item.variantId.split('-')[0]}`;
    }
  });
  return queryString.replace(',', '');
};

export function getMinifiedProductResponseCall(csbList, id) {
  return (dispatch, getState) => {
    const state = getState();
    const activeStyleBoard =
      id || get(state, 'productCatalog.outfitting.activeStyleBoard');
    const currentMinifiedState = get(
      state,
      'productCatalog.outfitting.minifiedProductResponse',
      [],
    );
    const queryStringProductIds = getRequestQueryStringForMinifiedProductDetils(
      csbList,
      activeStyleBoard,
    );
    const { session = '' } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    const requestApi = httpWithLogging(state, 10000);
    const compositeEnabled = get(state, 'toggles.PDP_COMPOSITE_MODE', false);
    let requestURI = compositeEnabled
      ? `${NMConfig.API_MINIFIED_PRODUCT_DETAIL_PDP_COMPOSITE}?productIds=${queryStringProductIds}`
      : `${NMConfig.API_MINIFIED_PRODUCT_DETAIL_PDP}?productIds=${queryStringProductIds}`;
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      if (currencyCode !== 'USD') {
        let currencyQuery = `?currency=${currencyCode}`;
        if (requestURI.indexOf('?') > -1 || requestURI.indexOf('&') > -1) {
          currencyQuery = `&currency=${currencyCode}`;
        }
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }
    return requestApi
      .get(requestURI, { headers })
      .then(successResponse => {
        const styleBoardId = id || 0;
        let minifiedResponseStatus = false;
        const minifiedProductInfo = get(
          successResponse.data,
          'minifiedProductInfo',
          [],
        );
        if (styleBoardId === 0) {
          minifiedResponseStatus = !(
            minifiedProductInfo.length > 0 &&
            csbList.length > 0 &&
            csbList[styleBoardId].children &&
            csbList[styleBoardId].children.length === minifiedProductInfo.length
          );
        } else if (styleBoardId > 0) {
          minifiedResponseStatus = minifiedProductInfo.length < 5;
        }
        currentMinifiedState.push({
          data: successResponse.data,
          error: minifiedResponseStatus,
        });
        dispatch({
          type: RESOLVED_MINIFIED_PRODUCT,
          payload: {
            data: currentMinifiedState,
            styleBoard: styleBoardId,
            loaded: true,
          },
        });
      })
      .catch(() => dispatch({ type: REJECTED_MINIFIED_PRODUCT }));
  };
}

export function getOutFittingRecommendations(product) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_STYLYZE_RECOMMENDATIONS });
    const state = getState();
    const queryString = getRequestQueryStringForOutFitting(product, state);
    const requestApi = httpWithLogging(state, 10000);
    return requestApi
      .get(`${NMConfig.API_PRODUCT_OUTFITTING}${queryString}`)
      .then(successResponse => {
        dispatch({
          type: RESOLVED_STYLYZE_RECOMMENDATIONS,
          payload: successResponse.data,
        });
        getMinifiedProductResponseCall(successResponse.data.csbList)(
          dispatch,
          getState,
        );
      })
      .catch(() => dispatch({ type: REJECTED_STYLYZE_RECOMMENDATIONS }));
  };
}

const getRequestQueryString = (
  showPromoPreview,
  promos,
  id,
  focusProductId,
  profileId,
) => {
  const queryValues = [];

  if (focusProductId) queryValues.push(`focusId=${focusProductId}`);
  if (showPromoPreview) {
    if (promos && promos.length) {
      queryValues.push(
        `personalizedPromos=${promos}&promoPreview=${showPromoPreview}`,
      );
    } else {
      queryValues.push(`promoPreview=${showPromoPreview}`);
    }
  } else if (promos && promos.length) {
    queryValues.push(`personalizedPromos=${promos}`);
  }
  if (profileId) {
    queryValues.push(`profileId=${profileId}`);
  }

  const queryString = queryValues.length > 0 ? `?${queryValues.join('&')}` : '';
  return queryString;
};

export function getProduct(id, queryStringObj) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT });
    const state = getState();
    const { session, cookies, user } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        AGA: get(session, 'AGA', ''),
        WID: get(state, 'utag.userSession.web_id', ''),
        ucid: get(user, 'ucid', ''),
      }),
    };
    const { promos } = JSON.parse(get(session, 'dt_personalize_data', '{}'));
    const focusProductId = get(queryStringObj, 'focusProductId', '');
    const showPromoPreview = get(cookies, 'enablePromoPreview', false);
    const profileId = get(state, 'utag.userSession.web_id', '');

    const queryString = getRequestQueryString(
      showPromoPreview,
      promos,
      id,
      focusProductId,
      profileId,
    );
    const compositeEnabled = get(state, 'toggles.PDP_COMPOSITE_MODE', false);
    let requestURI = compositeEnabled
      ? `${NMConfig.API_PRODUCT_DETAIL_PDP_COMPOSITE}/${id}${queryString}`
      : `${NMConfig.API_PRODUCT_DETAIL_PDP}/${id}${queryString}`;
    const requestApi = httpWithLogging(state, 10000);
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      if (currencyCode !== 'USD') {
        let currencyQuery = `?currency=${currencyCode}`;
        if (requestURI.indexOf('?') > -1 || requestURI.indexOf('&') > -1) {
          currencyQuery = `&currency=${currencyCode}`;
        }
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }
    return requestApi
      .get(requestURI, { headers })
      .then(successResponse => {
        const product = successResponse.data || {};
        const isPdpConfigurator =
          state.productCatalog.pdpConfigurator &&
          state.productCatalog.pdpConfigurator.department_settings &&
          state.productCatalog.pdpConfigurator.department_settings[
            product.departmentCode
          ];
        const template = (isPdpConfigurator || {}).template || 'b';
        const type = product.isGroup ? RESOLVED_GROUP : RESOLVED_PRODUCT;
        dispatch({ type, payload: successResponse.data, template });
      })
      .catch(() => {
        dispatch({ type: REJECTED_PRODUCT });
      });
  };
}

export function getRelatedItems(productId, url, referer, userAgent) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_RELATED_ITEMS });
    const state = getState();
    const { JSESSIONID, DYN_USER_ID, TLTSID, W2A } = state.session;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID,
        DYN_USER_ID,
        TLTSID,
        W2A,
      }),
    };
    const query = stringify({
      prod_id: productId,
      ptype: 'product',
      ref: referer,
      user_agent: userAgent,
      url,
    });

    const requestApi = httpWithLogging(state, 10000);
    return requestApi
      .get(`${NMConfig.API_BLOOMREACH}/bloomreach/recommendations?${query}`, {
        headers,
      })
      .then(successResponse =>
        dispatch({
          type: RESOLVED_RELATED_ITEMS,
          payload: successResponse.data,
        }),
      )
      .catch(() => dispatch({ type: REJECTED_RELATED_ITEMS }));
  };
}

export function resetProduct() {
  return dispatch => {
    dispatch({ type: RESET_PRODUCT });
    dispatch({ type: RESET_RECOMMENDATIONS });
    dispatch({ type: RESET_OOS_RECOMMENDATIONS });
    dispatch({ type: RESET_RELATED_ITEMS });
  };
}

export function setPersonalization(productId, value) {
  return dispatch => {
    dispatch({ type: SET_PERSONALIZATION, payload: value, productId });
  };
}

export function setSelectedCustomizations(productId, value) {
  return dispatch => {
    dispatch({ type: SET_SELECTED_CUSTOMIZATIONS, payload: value, productId });
  };
}

export function initializeSelectedCustomizationsAllTypes(productId = '') {
  return (dispatch, getState) => {
    const state = getState();
    const singleProduct = get(state, 'productCatalog.product', {
      isGroup: false,
    });
    const childProducts = get(state, 'productCatalog.group.childProducts', {});
    const groupProduct = get(childProducts, productId, {});
    const product = singleProduct.isGroup ? groupProduct : singleProduct;
    const { customization = {} } = product;

    dispatch({
      type: SET_SELECTED_CUSTOMIZATIONS_ALL_TYPES,
      payload: getSelectedCustomizationsAllTypes(customization),
      productId,
    });
  };
}

function getSelectedCustomizationsAllTypes(customization) {
  const customizationOptions = get(customization, 'customizationOptions', []);

  const monoStyleCount = customizationOptions.filter(
    option => option.legacyType === 'mono style',
  ).length;
  const outputDataCount = customizationOptions.filter(
    option => option.legacyType === 'output data',
  ).length;
  const inhouseMonogram = monoStyleCount === 1 && outputDataCount === 1;
  let firstChoiceType = '';
  if (inhouseMonogram) {
    firstChoiceType = customizationOptions.find(
      option => option.legacyType === 'mono style',
    ).choices[0].type;
  }

  const selectedCutomizationsAllTypes = customizationOptions.map(option =>
    initializeSelectedCustomizationObject(
      option,
      inhouseMonogram,
      firstChoiceType,
    ),
  );

  return selectedCutomizationsAllTypes;
}

function initializeSelectedCustomizationObject(
  option,
  inhouseMonogram,
  firstChoiceType,
) {
  const defaultState = {
    id: option.id,
    isValid: false,
    legacyType: option.legacyType,
    selectedValues: '',
    touched: false,
    savedValues: '',
  };
  if (inhouseMonogram && option.legacyType === 'output data') {
    switch (firstChoiceType) {
      case 'NAME':
      case 'SINGLE_INIT':
        return { ...defaultState, selectedValues: [''], savedValues: [''] };
      case 'THREE_INIT':
      case 'THREE_INIT_FML':
        return {
          ...defaultState,
          selectedValues: ['', '', ''],
          savedValues: ['', '', ''],
        };
      default:
        return { ...defaultState, selectedValues: [''], savedValues: [''] };
    }
  }
  return { ...defaultState };
}

export function setSelectedCustomizationObjectById(
  productId = '',
  selectedCustomizationObject,
) {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_CUSTOMIZATION_OBJECT,
      payload: selectedCustomizationObject,
      productId,
    });
  };
}

export function setSelectedCustomizationsTouchedTrue(productId = '') {
  return dispatch => {
    dispatch({ type: SET_SELECTED_CUSTOMIZATIONS_TOUCHED_TRUE, productId });
  };
}

export function setSelectedCustomizationSavedValues(productId = '') {
  return dispatch => {
    dispatch({ type: SET_SELECTED_CUSTOMIZATIONS_SAVEDVALUES, productId });
  };
}

export function setSelectedCustomizationSelectedValuesFromSavedValues(
  productId = '',
) {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_CUSTOMIZATIONS_SELECTEDVALUES_FROM_SAVEDVALUES,
      productId,
    });
  };
}

function setGranifyProductIfLabelIsColor(
  productOptions,
  selectedColorIndex,
  skus,
) {
  if (productOptions[0].label === 'color') {
    if (selectedColorIndex !== null && selectedColorIndex > -1) {
      const selectedColorValue = productOptions[0].values[
        selectedColorIndex
      ].name.toLowerCase();
      const skuId = !isEmpty(skus)
        ? skus.find(sku => sku.color.name.toLowerCase() === selectedColorValue)
            .id
        : '';
      if (process.browser) {
        skuId !== '' &&
          window &&
          window.Granify &&
          window.Granify('setCurrentProduct', skuId);
      }
    }
  }
}

function setGranifyProductIfLabelIsSize(
  productOptions,
  selectedSizeIndex,
  skus,
) {
  if (productOptions[0].label === 'size') {
    if (selectedSizeIndex !== null && selectedSizeIndex > -1) {
      const selectedSizeValue = productOptions[0].values[
        selectedSizeIndex
      ].name.toLowerCase();
      const skuId = !isEmpty(skus)
        ? skus.find(sku => sku.size.name.toLowerCase() === selectedSizeValue).id
        : '';
      if (process.browser) {
        skuId !== '' &&
          window &&
          window.Granify &&
          window.Granify('setCurrentProduct', skuId);
      }
    }
  }
}

function setGranifyProductIsOptionType(isGranifyExp, isChanel, optionType) {
  return (
    isGranifyExp &&
    !isChanel &&
    (optionType === 'size' || optionType === 'color')
  );
}

function setGranifyProductToWindow(skuId) {
  if (process.browser) {
    skuId !== '' &&
      window &&
      window.Granify &&
      window.Granify('setCurrentProduct', skuId);
  }
}

function getSelectedSizeValue(productOptions, selectedSizeIndex) {
  return !isEmpty(productOptions)
    ? productOptions
        .find(prodOptionObj => prodOptionObj.label === 'size')
        .values[selectedSizeIndex].name.toLowerCase()
    : '';
}

function getSelectedColorValue(productOptions, selectedColorIndex) {
  return !isEmpty(productOptions)
    ? productOptions
        .find(prodOptionObj => prodOptionObj.label === 'color')
        .values[selectedColorIndex].name.toLowerCase()
    : '';
}

function getSelectedSizeIndex(optionType, selectedIndex, state) {
  return optionType === 'size'
    ? selectedIndex
    : get(state, 'productCatalog.product.options.selectedSizeIndex', null);
}

function getSelectedColorIndex(optionType, selectedIndex, state) {
  return optionType === 'color'
    ? selectedIndex
    : get(state, 'productCatalog.product.options.selectedColorIndex', null);
}

function getSkuId(skus, selectedColorValue, selectedSizeValue) {
  return !isEmpty(skus)
    ? skus.find(
        sku =>
          sku.color.name.toLowerCase() === selectedColorValue &&
          sku.size.name.toLowerCase() === selectedSizeValue,
      ).id
    : '';
}

function setGranifyProduct(state, productId, selectedIndex, optionType) {
  const isGranifyExp = get(state, 'toggles.GRANIFY', false);
  const isChanel = get(state, 'productCatalog.product.isChanel', false);
  if (setGranifyProductIsOptionType(isGranifyExp, isChanel, optionType)) {
    const productOptions = get(
      state,
      'productCatalog.product.options.productOptions',
      [],
    );
    const skus = get(state, 'productCatalog.product.skus', []);
    const selectedSizeIndex = getSelectedSizeIndex(
      optionType,
      selectedIndex,
      state,
    );

    const selectedColorIndex = getSelectedColorIndex(
      optionType,
      selectedIndex,
      state,
    );

    if (
      !isEmpty(productOptions) &&
      productOptions.length === 1 &&
      (productOptions[0].label === 'color' ||
        productOptions[0].label === 'size')
    ) {
      setGranifyProductIfLabelIsColor(productOptions, selectedColorIndex, skus);
      setGranifyProductIfLabelIsSize(productOptions, selectedSizeIndex, skus);
    } else if (
      selectedSizeIndex !== null &&
      selectedSizeIndex > -1 &&
      (selectedColorIndex !== null && selectedColorIndex > -1)
    ) {
      const selectedSizeValue = getSelectedSizeValue(
        productOptions,
        selectedSizeIndex,
      );
      const selectedColorValue = getSelectedColorValue(
        productOptions,
        selectedColorIndex,
      );
      const skuId = getSkuId(skus, selectedColorValue, selectedSizeValue);
      setGranifyProductToWindow(skuId);
    }
  }
}

function updateGranifyCart(returnObj, state, skuId) {
  const isGranifyExp = get(state, 'toggles.GRANIFY', false);
  const product = get(state, 'productCatalog.product');
  const isChanel = get(product, 'isChanel', false);
  if (isGranifyExp && !isChanel) {
    const retailPrice = get(product, 'price.retailPrice', 0);
    const price = parseFloat(
      returnObj.cart_change_product_price
        ? returnObj.cart_change_product_price
        : retailPrice,
    );
    if (process.browser && skuId && skuId !== '') {
      window &&
        window.Granify &&
        window.Granify('addToCart', [
          {
            id: `${skuId}`,
            quantity: returnObj.quantity,
            price,
            title: `${get(product, 'name')}`,
          },
        ]);
    }
  }
}

export function setSelectedIndex(productId, selectedIndex, optionType) {
  return (dispatch, getState) => {
    const state = getState();
    const isRelease2 = state.toggles.BG_PHASE_2_RELEASE_2;
    if (optionType === 'size') {
      dispatch({
        type: SET_SELECTED_SIZE_INDEX,
        payload: selectedIndex,
        productId,
      });
    }
    if (optionType === 'color') {
      dispatch({
        type: SET_SELECTED_COLOR_INDEX,
        payload: selectedIndex,
        productId,
      });
      if (isRelease2) {
        dispatch({
          type: SET_SELECTED_SIZE_INDEX,
          payload: -1,
          productId,
        });
      }
    }
    if (optionType === BUCKLE_FINISH) {
      dispatch({
        type: SET_SELECTED_BUCKLE_FINISH_INDEX,
        payload: selectedIndex,
        productId,
      });
    }
    setGranifyProduct(getState(), productId, selectedIndex, optionType);
  };
}

export function setQLSelectedIndex(productId, selectedIndex, optionType) {
  return (dispatch, getState) => {
    const state = getState();
    const isRelease2 = state.toggles.BG_PHASE_2_RELEASE_2;
    if (optionType === 'size') {
      dispatch({
        type: SET_QL_SELECTED_SIZE_INDEX,
        payload: selectedIndex,
        productId,
      });
    }
    if (optionType === 'color') {
      dispatch({
        type: SET_QL_SELECTED_COLOR_INDEX,
        payload: selectedIndex,
        productId,
      });
      if (isRelease2) {
        dispatch({
          type: SET_QL_SELECTED_SIZE_INDEX,
          payload: -1,
          productId,
        });
      }
    }
  };
}

export const setSelectedColorCode = colorCode => dispatch =>
  dispatch({ type: SET_SELECTED_COLOR_CODE, payload: colorCode });

export function setHoveredIndex(productId, hoveredIndex, optionType) {
  return dispatch => {
    if (optionType === 'size') {
      dispatch({
        type: SET_HOVERED_SIZE_INDEX,
        payload: hoveredIndex,
        productId,
      });
    }
    if (optionType === 'color') {
      dispatch({
        type: SET_HOVERED_COLOR_INDEX,
        payload: hoveredIndex,
        productId,
      });
    }
    if (optionType === BUCKLE_FINISH) {
      dispatch({
        type: SET_HOVERED_BUCKLE_FINISH_INDEX,
        payload: hoveredIndex,
        productId,
      });
    }
  };
}

export function increaseQuantity(productId, value) {
  return dispatch => {
    screenReaderAnnouncer(`quantity is ${value + 1}`)(dispatch);
    dispatch({ type: INCREASE_PRODUCT_QUANTITY, payload: value, productId });
  };
}

export function decreaseQuantity(productId, value) {
  return dispatch => {
    screenReaderAnnouncer(`quantity is ${value - 1}`)(dispatch);
    dispatch({ type: DECREASE_PRODUCT_QUANTITY, payload: value, productId });
  };
}

export function updateQuantity(productId, value) {
  return dispatch => {
    window.sessionStorage?.setItem('updatedQuantity', value);
    screenReaderAnnouncer(`quantity is ${value}`)(dispatch);
    dispatch({ type: UPDATE_PRODUCT_QUANTITY, payload: value, productId });
  };
}

export function setDeliveryDate(productId, value) {
  return dispatch =>
    dispatch({ type: SET_DELIVERY_DATE, payload: value, productId });
}

export function zoomProductImage(productId, images, currentIndex) {
  return dispatch =>
    dispatch({
      type: ZOOM_PRODUCT_IMAGE,
      payload: { images, currentIndex },
      productId,
    });
}

export function zoomProductImageQl(productId, images, currentIndex) {
  return dispatch =>
    dispatch({
      type: ZOOM_PRODUCT_IMAGE_QL,
      payload: { images, currentIndex },
      productId,
    });
}

export function setActiveMediaIndex(productId, index) {
  return dispatch =>
    dispatch({ type: SET_ACTIVE_MEDIA_INDEX, payload: index, productId });
}

export function setProductVideo(productId) {
  return dispatch => dispatch({ type: SET_PRODUCT_VIDEO, productId });
}

export function setActivePDPTab(productId, index) {
  return dispatch =>
    dispatch({ type: SET_ACTIVE_PDP_TAB, payload: index, productId });
}

export function setReplenishInterval(productId, value) {
  return dispatch => {
    let newValue = value;

    switch (value) {
      case 1:
        newValue = 30;
        break;
      case 2:
        newValue = 45;
        break;
      case 3:
        newValue = 60;
        break;
      case 4:
        newValue = 90;
        break;
      case 5:
        newValue = 120;
        break;
      default:
        newValue = value;
    }
    dispatch({ type: SET_REPLENISH_INTERVAL, payload: newValue, productId });
  };
}

export function unsetProductVideo(productId) {
  return dispatch => dispatch({ type: UNSET_PRODUCT_VIDEO, productId });
}

const createGetPreviouslyAddedItemsRequest = count => {
  const json = JSON.stringify({
    'RWD.modals.miniCart.MiniCartReq': {
      isRWD: true,
      mobileDevice: true,
      commerceItemCount: count,
    },
  });
  if (Buffer.byteLength(json) !== json.length) {
    throw new Error(`Json string: '${json}' is malformed.`);
  }
  return `$b64$${new Buffer(json, 'binary').toString('base64')}`;
};

export const addToBagErrorTypes = {
  selection: 'selection',
  zero_quantity: 'zero_quantity',
  server_error: 'server_error',
  request_exceeds_inventory: 'request_exceeds_inventory',
};

export const getNarvarABTestGroupID = state => {
  const narvar = get(state, 'abTests.finalSaleExperience', false);
  if (narvar) {
    return 'testGroup1';
  }
  return 'controlGroup';
};

const generateSiCodeSelections = (product, selectedSku, buckleFinish) => {
  const { metadata = {} } = product;

  const cmosDetails = `${metadata.cmosCatalogId}_${metadata.cmosItem}_SKU_COLOR`;
  const selectedColorName = get(selectedSku, 'color.name');
  const skuColorSelection = `${cmosDetails}|${selectedColorName}`;
  let siCodeSelections = `${skuColorSelection}`;
  if (buckleFinish) {
    const buckleFinishInput = `FB1|${buckleFinish}`;
    siCodeSelections = `${skuColorSelection}~${buckleFinishInput}`;
  } else {
    const monoStyle = getCustomizationOption(product, 'mono style');
    const threadColor = getCustomizationOption(product, 'thread color');
    const outputData = getCustomizationOption(product, 'output data');

    const selectedMonoStyleChoice = getSelectedCustomizationChoice(
      product,
      'mono style',
    );
    const selectedThreadColorChoice = getSelectedCustomizationChoice(
      product,
      'thread color',
    );
    const enteredText = getSelectedCustomizationChoice(product, 'output data');

    const monoStyleOptionInput = `${monoStyle.id}|${selectedMonoStyleChoice.value}`;
    const threadColorOptionInput = `${threadColor.id}|${selectedThreadColorChoice.value}`;
    const textOptionInput = `${outputData.id}|${enteredText.join('')}`;

    const customizationOptionInput = `${monoStyleOptionInput}~${threadColorOptionInput}~${textOptionInput}`;
    siCodeSelections = `${skuColorSelection}~${customizationOptionInput}`;
  }
  return siCodeSelections;
};

const generateCustomizationObj = product => {
  const { customization = {} } = product;
  const {
    customizationOptions = [],
    behaviorCode = '',
    additionalCharge = 0,
  } = customization;

  const details = {};
  customizationOptions.forEach(customizationOption => {
    const selectedCustomizationValue = getSelectedCustomizationAllTypesValue(
      product,
      customizationOption.id,
    );
    if (customizationOption.type === 'CHOICE') {
      // eslint-disable-next-line max-len
      const selectedChoice = getSelectedCustomizationAllTypesChoice(
        product,
        customizationOption.id,
      );
      details[customizationOption.id] = selectedChoice;
    } else if (Array.isArray(selectedCustomizationValue)) {
      const arrayValue = selectedCustomizationValue.every(
        value => value.length === 0,
      )
        ? ''
        : selectedCustomizationValue.join('');
      details[customizationOption.id] = arrayValue;
    } else {
      const inputValue = selectedCustomizationValue.length
        ? selectedCustomizationValue
        : ' ';
      details[customizationOption.id] = inputValue;
    }
  });

  const customizationOptionInput = {
    monogramType: behaviorCode,
    price: additionalCharge,
    details,
  };

  return customizationOptionInput;
};

const generateSiCodeSelectionsAllTypes = (product, selectedSku) => {
  const { metadata = {}, customization = {} } = product;
  const { customizationOptions = [] } = customization;
  const { cmosCatalogId = '', cmosItem = '', pimStyle = '' } = metadata;

  const cmosDetails = `${cmosCatalogId}_${cmosItem}_${pimStyle}_SKU_COLOR`;
  const selectedColorName = get(selectedSku, 'color.name', '');
  const skuColorSelection = selectedColorName
    ? `${cmosDetails}|${selectedColorName}`
    : '';

  function shouldAppendTilde(input) {
    return input && !input.endsWith('~');
  }

  let customizationOptionInput = skuColorSelection ? '~' : '';
  customizationOptions.forEach(customizationOption => {
    const selectedCustomizationValue = getSelectedCustomizationAllTypesValue(
      product,
      customizationOption.id,
    );
    customizationOptionInput += shouldAppendTilde(customizationOptionInput)
      ? '~'
      : '';
    if (customizationOption.type === 'CHOICE') {
      const selectedCustomizationChoice = getSelectedCustomizationAllTypesChoice(
        product,
        customizationOption.id,
      );
      customizationOptionInput += `${customizationOption.id}|${selectedCustomizationChoice}`;
    } else if (Array.isArray(selectedCustomizationValue)) {
      const arrayValue = selectedCustomizationValue.every(
        value => value.length === 0,
      )
        ? ' '
        : selectedCustomizationValue.join('');
      customizationOptionInput += `${customizationOption.id}|${arrayValue}`;
    } else {
      const inputValue = selectedCustomizationValue.length
        ? selectedCustomizationValue
        : ' ';
      customizationOptionInput += `${customizationOption.id}|${inputValue}`;
    }
  });
  return `${skuColorSelection}${customizationOptionInput}`;
};

export function addToBag(
  productDetails,
  selectedSku,
  skuMediaUrl,
  storeNumber = '',
  source,
  showLoading = false,
  isFromQL = false,
) {
  return (dispatch, getState) => {
    const state = getState();
    const product =
      source === 'wishlist' || isFromQL
        ? productDetails
        : getProductData(state, productDetails.id);
    const buckleFinish = getBuckleFinishDisplayName(state);
    const DROPSHIP_MONOGRAM = get(state, 'toggles.DROPSHIP_MONOGRAM', false);
    const PDP_TRUEFIT_SIZE_MODEL = get(
      state,
      'toggles.TRUEFIT_SIZE_MODAL',
      false,
    );
    const {
      id: productId,
      quantity,
      replenishInterval,
      deliveryDate,
      isPersonalizationSelected,
      productFlags = {},
    } = product;
    const requestBody = {
      productId,
      quantity: isFromQL ? 1 : quantity,
      replenishInterval,
      storeId: storeNumber, // ATG requires store *numbers* for the `storeId` argument
      skuId: selectedSku.id,
      size: get(selectedSku, 'size.name'),
      color: get(selectedSku, 'color.name'),
      deliveryDate,
      narvarAbTestId: getNarvarABTestGroupID(state),
    };
    if (skuMediaUrl !== '') {
      requestBody['skuMediaUrl'] = skuMediaUrl;
    }
    const isGroup = get(state, 'productCatalog.product.isGroup', false);
    const dtCmosData = get(state, 'toggles.DT_CMOS_DATA', false);
    if (!isGroup && typeof $ !== 'undefined' && PDP_TRUEFIT_SIZE_MODEL) {
      const querySelector = `div#${productId} .tfc-fitrec-result .tfc-popup-click-open`;
      requestBody.isTrueFitEnabled = $(querySelector).length !== 0;
    }

    if (dtCmosData) {
      if (isPersonalizationSelected) {
        requestBody.customization = generateCustomizationObj(product);
        if (productFlags.previewSupported) {
          requestBody.skuMediaUrl = getDynamicImageURLForCustomizedProduct(
            product,
            75,
            94,
          );
        }
      }
      if (buckleFinish) {
        requestBody.siCodeSelections = generateSiCodeSelections(
          product,
          selectedSku,
          buckleFinish,
        );
      }
    } else {
      if (isPersonalizationSelected) {
        requestBody.isMonogramSelected = true;
        if (DROPSHIP_MONOGRAM) {
          requestBody.siCodeSelections = generateSiCodeSelectionsAllTypes(
            product,
            selectedSku,
          );
        } else {
          requestBody.siCodeSelections = generateSiCodeSelections(
            product,
            selectedSku,
            buckleFinish,
          );
        }
        if (productFlags.previewSupported) {
          requestBody.skuMediaUrl = getDynamicImageURLForCustomizedProduct(
            product,
            75,
            94,
          );
        }
      }
      if (buckleFinish) {
        requestBody.isMonogramSelected = true;
        requestBody.siCodeSelections = generateSiCodeSelections(
          product,
          selectedSku,
          buckleFinish,
        );
      }
    }

    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    const isDomestic = get(state, 'locale.countryCode', 'US') === 'US';
    return internationalToggle && !isDomestic
      ? handleInternationalAddToBag(requestBody)(dispatch, getState)
      : handleAddToBag(requestBody, showLoading, isFromQL)(dispatch, getState);
  };
}

export function addGiftToBag(
  productId,
  promoKey,
  color = 'ANR',
  size = 'ANR',
  quantity = 1,
) {
  return (dispatch, getState) => {
    const requestBody = {
      productId,
      quantity,
      promoKey,
      size,
      color,
    };
    handleAddToBag(requestBody)(dispatch, getState);
  };
}

const isSourceQuickLook = state => {
  return get(state, 'productListPage.products.sourceQuickLook', false);
};
const isSourceOutfittingQuickLook = state => {
  return get(
    state,
    'productCatalog.outfitting.QLSummary.sourceQuickLook',
    false,
  );
};

export function completeAddToBag() {
  return (dispatch, getState, isFromQL) => {
    const state = getState();
    if (isSourceOutfittingQuickLook(state) || isFromQL) {
      openAddToBagQLModal()(dispatch);
    } else if (pageUtils.isMobile()) {
      // Suppressing MiniCart for Mobile in Phase2 - BGO-9041
      // openAddToBagMobile()(dispatch, getState);
    } else if (!isSourceQuickLook(state) || !isFromQL) {
      if (pageUtils.isDesktop()) {
        showMiniCart()(dispatch, getState, true);
      }
    } else {
      openAddToBagQLModal()(dispatch);
    }
  };
}

export function openAddToBagQLModal() {
  return dispatch =>
    dispatch(
      openModal({
        type: 'AddToBagQLModal',
        className: 'add-to-bag-ql-modal',
      }),
    );
}

export function openBops(product, selectedSku, skuMediaUrl) {
  return (dispatch, getState) => {
    const state = getState();
    const isBOPSOptimization = get(state, 'toggles.BOPS_OPTIMIZATION', false);
    const modalType = isBOPSOptimization ? 'BopsModalOptimized' : 'BopsModal';
    const classType = isBOPSOptimization
      ? 'bops-modal-optimized'
      : 'bops-modal';
    return openModal({
      type: modalType,
      props: { product, selectedSku, skuMediaUrl },
      className: classType,
      adaProps: {
        labelledby: 'storeSearchLabel',
        describedby: 'storeSearchDesc',
      },
    })(dispatch);
  };
}

export function openReplenishment() {
  return (dispatch, getState) =>
    openModal({
      type: 'ReplenishmentModal',
      url: '/category/product/r_replenishmentFAQ.html',
      className: 'replenishment-modal',
      adaProps: {
        role: '',
      },
    })(dispatch, getState);
}

export function openMonogram(product) {
  return (dispatch, getState) => {
    const state = getState();
    const isDropshipMonogram = get(state, 'toggles.DROPSHIP_MONOGRAM', false);
    const modalType = isDropshipMonogram
      ? 'MonogramModalDropship'
      : 'MonogramModal';
    return openModal({
      type: modalType,
      props: { product },
      className: 'monogram-modal',
      headerText: MONOGRAM_HEADER,
    })(dispatch);
  };
}

export function openProductImageZoom(
  productId,
  images,
  index,
  isCloudinary,
  zoomPdpToggle,
) {
  return dispatch => {
    return openModal({
      type: 'ProductZoomModal',
      props: { images, index, isCloudinary, zoomPdpToggle },
      className: 'product-zoom-modal',
    })(dispatch);
  };
}

export function getSDD(zip, skuIds) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    dispatch({ type: LOADING_SAMEDAYDELIVERY });
    return requestApi
      .get(
        `${NMConfig.API_SAMEDAYDELIVERY}?zip=${zip}&skuIds=${skuIds.join(',')}`,
        { timeout: 5000 },
      )
      .then(({ data }) => {
        dispatch({
          type: RESOLVED_SAMEDAYDELIVERY,
          payload: data.sameDayDelivery || {},
        });
      })
      .catch(() => dispatch({ type: REJECTED_SAMEDAYDELIVERY }));
  };
}

export function getStores(freeFormAddress, skuId, quantity) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state, 10000, 10000);
    requestApi
      .get(
        `${NMConfig.API_STORE}?brandCode=bg&freeFormAddress=${freeFormAddress}&skuId=${skuId}&quantity=${quantity}`,
      )
      .then(response => {
        dispatch({
          type: GET_STORES,
          payload: { stores: response.data, status: 200, showStoresFlag: true },
        });
        const cookieDate = new Date();
        cookieDate.setYear(cookieDate.getFullYear() + 1);
        reactCookie.save('bopsSearchTerm', freeFormAddress, {
          path: '/',
          expires: cookieDate,
        });
      })
      .catch(error => {
        dispatch({
          type: GET_STORES,
          payload: {
            stores: [],
            status:
              error.response.data.upstreamStatusCode || error.response.status,
            showStoresFlag: true,
          },
        });
      });
  };
}

export function clearStores() {
  return dispatch => {
    dispatch({ type: CLEAR_STORES });
  };
}

export function openGwp(gwpProdId, gwpPromoKey) {
  return (dispatch, getState) => {
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: reactCookie.load('JSESSIONID'),
        DYN_USER_ID: reactCookie.load('DYN_USER_ID'),
        TLTSID: reactCookie.load('TLTSID'),
        W2A: reactCookie.load('W2A'),
      }),
    };
    const state = getState();
    const PRODUCT_DETAIL_API_TIMEOUT = get(
      state,
      'apiTimeouts.PRODUCT_DETAIL_API_TIMEOUT',
    );
    const requestApi = httpWithLogging(state, PRODUCT_DETAIL_API_TIMEOUT);

    requestApi
      .get(`${NMConfig.API_PRODUCT_DETAIL_PDP}/${gwpProdId}`, { headers })
      .then(response =>
        openModal({
          type: 'GwpMultiSkuModal',
          className: 'gwp-modal',
          props: { gwpProduct: response.data, gwpPromoKey },
          closeDisabled: false,
        })(dispatch),
      )
      .catch(() => {
        completeAddToBag()(dispatch, getState);
      });
  };
}

export function openAddToBagMobile() {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    const quantityAddedToCart = get(state, 'miniCart.recentlyAddedItemCount');
    requestApi
      .post(NMConfig.API_GET_LAST_BAG_ITEM, {
        bid: 'GetFragmentReq',
        data: createGetPreviouslyAddedItemsRequest(quantityAddedToCart),
      })
      .then(response =>
        openModal({
          type: 'AddToBagMobileModal',
          className: 'add-to-bag-mobile-modal',
          content: get(response, 'data.MiniCartResp.html'),
        })(dispatch),
      );
  };
}

export function showAddToBagError(productId, errorMessage) {
  return dispatch =>
    dispatch({
      type: ADD_TO_BAG_ERROR,
      payload: { errorMessage },
      productId,
    });
}

export function showOutfittingQLAddToBagError(productId, errorMessage) {
  return dispatch =>
    dispatch({
      type: ADD_TO_OUTFITTING_QL_BAG_ERROR,
      payload: { errorMessage },
      productId,
    });
}

export function showBopsError(productId, errorMessage) {
  return dispatch =>
    dispatch({
      type: BOPS_ERROR,
      payload: { errorMessage },
      productId,
    });
}

export function setBopsErrorForReplenishment(productId) {
  return dispatch =>
    dispatch({ type: SET_BOPS_ERROR_FOR_REPLENISHMENT, productId });
}

export function setBopsNoSelectionErrorForReplenishment(productId) {
  return dispatch =>
    dispatch({ type: BOPS_NO_SELECTION_ERR_MSG_FOR_REPLENISHMENT, productId });
}

export function clearError(productId) {
  return dispatch =>
    dispatch({
      type: CLEAR_ERROR,
      productId,
    });
}

export function clearOutfittingQLError(productId) {
  return dispatch =>
    dispatch({
      type: CLEAR_OUTFITTING_QL_ERROR,
      productId,
    });
}

const isPlaExperience = state => {
  const isPlaEnabled = get(state, 'productListingAd.status.enabled', false);
  const ecid = get(state, 'routing.locationBeforeTransitions.query.ecid', '');
  return (
    (ecid === 'NMCS__GooglePLA' || ecid === 'NMCS__BingPLA') && isPlaEnabled
  );
};

const findRecentlyAddedItem = (cartData, skuId) => {
  const productsList =
    cartData?.shoppingCart?.commerceItems?.shipments?.reduce(
      (acc, shipment) => [...acc, ...shipment.items],
      [],
    ) || [];
  return productsList.find(item => item.skuId === skuId) || null;
};

const handleDTCartapi = (
  requestBody,
  showLoading,
  state,
  getState,
  dispatch,
  isFromQL = false,
) => {
  const requestApi = httpWithLogging(state, 20000);
  const { amplifyConfig } = getState();
  const disableATGToggle = get(state, 'toggles.DISABLE_ATG_CALLS', false);
  const isAddToCartV3Api = get(state, 'toggles.ADD_TO_CART_V3_API', false);
  const dtCmosData = get(state, 'toggles.DT_CMOS_DATA', false);

  const replenishInterval = requestBody?.replenishInterval;
  const deliveryDate = requestBody?.deliveryDate;
  const body = {
    ...(requestBody?.deliveryDate && {
      perishableDeliveryByDate: requestBody?.deliveryDate,
    }),
    ...(requestBody?.replenishInterval && {
      replenishmentInterval: String(requestBody?.replenishInterval),
    }),
    ...(requestBody?.customization && {
      customization: requestBody?.customization,
    }),
    productId: requestBody?.productId,
    skuId: requestBody?.skuId,
    quantity: requestBody?.quantity,
    sourceApp: 'BG',
  };

  if (replenishInterval) {
    requestBody.replenishmentInterval = String(replenishInterval);
    delete requestBody.replenishInterval;
  }

  if (deliveryDate) {
    requestBody.perishableDeliveryByDate = deliveryDate;
    delete requestBody.deliveryDate;
  }
  return getUserDataWithGuestIdentityOn(state, true, true, disableATGToggle)
    .then(user => {
      const headers = {
        Authorization: user.idToken,
        accountId: user.ucaId,
      };
      if (isAddToCartV3Api) {
        headers.sourceApp = sourceApp;
      }
      const requestUrl = isAddToCartV3Api
        ? `${NMConfig.API_ADD_TO_CART_DT_V3}`
        : `${NMConfig.API_ADD_TO_CART_DT}`;
      const apiBody = dtCmosData ? body : requestBody;
      return requestApi.post(requestUrl, apiBody, {
        headers,
      });
    })
    .then(res => {
      if (res?.data?.messages?.length > 0) {
        dispatch({ type: REJECTED_ADD_TO_BAG });
        dispatch({ type: APPLE_PAY_ADD_TO_CART_FAILED });
        if (showLoading) {
          dispatch({ type: `${REJECTED_ADD_TO_BAG}_${requestBody.skuId}` });
        }
        const errorMessage =
          res?.data?.messages[0]?.message || generalAddToBagErrorMessage;
        showAddToBagError(requestBody.productId, errorMessage)(dispatch);
        screenReaderAnnouncer(errorMessage)(dispatch);
      } else if (res?.data?.shoppingCart?.totalQuantity > 0) {
        const recentlyAddedItem = findRecentlyAddedItem(
          res?.data,
          requestBody.skuId,
        );
        if (recentlyAddedItem) {
          // generate utag data the same as we have for atg response:
          const utagData = {
            ajax_response_id: 'MiniCartResp',
            request_type: 'link',
            cart_total_items: res?.data?.shoppingCart?.totalQuantity,
            product_cmos_item: [
              recentlyAddedItem?.productDetails?.cmosItem || '',
            ],
            cart_change_product_quantity: [`${requestBody?.quantity}` || ''],
            product_cmos_sku: [recentlyAddedItem?.currentSku?.cmosSkuId || ''],
            product_name: [
              recentlyAddedItem?.productDetails?.displayName || '',
            ],
            cart_change_bops_flag: 'false',
            cart_product_replenish_time: [''],
            nmo_sku_id: [recentlyAddedItem?.skuId || ''],
            cart_change_product_price: [
              recentlyAddedItem?.productDetails?.retailPrice?.toFixed(2) || '',
            ],
            cart_change_product_catalog_id: [
              recentlyAddedItem?.productDetails?.cmosCatalogId || '',
            ],
          };

          dispatch({
            type: RESOLVED_ADD_TO_BAG,
            payload: { skuId: requestBody.skuId },
          });
          if (showLoading) {
            dispatch({
              type: `${RESOLVED_ADD_TO_BAG}_${requestBody.skuId}`,
            });
          }
          updateUcaProfileCountCartItems(
            res?.data?.shoppingCart?.totalQuantity,
          );
          updateMiniCartItemCount(res?.data?.shoppingCart?.totalQuantity)(
            dispatch,
          );
          dispatch({
            type: ADD_PRODUCT_TO_BAG,
            payload: { utagData, productIds: [requestBody.productId] },
            productId: requestBody.productId,
          });
          updateDtMiniCart({
            ...res.data,
            recentlyAddedItem,
          })(dispatch);
          if (pageUtils.isDesktop()) {
            startTimer()(dispatch);
            isFromQL
              ? openAddToBagQLModal()(dispatch)
              : showDtMinicart()(dispatch);
          }
        }
      } else {
        dispatch({ type: REJECTED_ADD_TO_BAG });
        dispatch({ type: APPLE_PAY_ADD_TO_CART_FAILED });
        if (showLoading) {
          dispatch({ type: `${REJECTED_ADD_TO_BAG}_${requestBody.skuId}` });
        }
      }
    })
    .catch(e => {
      dispatch({ type: REJECTED_ADD_TO_BAG });
      dispatch({ type: APPLE_PAY_ADD_TO_CART_FAILED });
      if (showLoading) {
        dispatch({ type: `${REJECTED_ADD_TO_BAG}_${requestBody.skuId}` });
      }
      const errorMessage = generalAddToBagErrorMessage;
      showAddToBagError(requestBody.productId, errorMessage)(dispatch);
      screenReaderAnnouncer(errorMessage)(dispatch);
    });
};

const handleATGCartApi = (
  requestBody,
  showLoading,
  state,
  getState,
  dispatch,
  isFromQL = false,
) => {
  const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
  const apiTimeouts = get(state, 'apiTimeouts', {});
  const requestApi = httpWithLogging(
    state,
    get(apiTimeouts, 'ADD_TO_CART_NODE_CLIENT', NODE_CLIENT_DEFAULT_TIMEOUT),
  );

  const lastRecentlyAddedItemsCount = get(
    state,
    'miniCart.recentlyAddedItemCount',
  );
  const showGWPMultiSkuModal = get(
    state,
    'toggles.SHOW_GWP_MULTI_SKU_MODAL',
    false,
  );
  return requestApi
    .post(`${NMConfig.API_ADD_TO_CART}`, requestBody)
    .then(({ data }) => {
      dispatch({
        type: RESOLVED_ADD_TO_BAG,
        payload: { skuId: requestBody.skuId },
      });
      if (showLoading) {
        dispatch({ type: `${RESOLVED_ADD_TO_BAG}_${requestBody.skuId}` });
      }
      const { ProductPageResp = {} } = data;
      const { addToCartResp = {}, totalNumberOfItems } = ProductPageResp;
      const {
        gwpShowModal = false,
        gwpProdId,
        gwpPromoKey,
        recentlyAddedItems = lastRecentlyAddedItemsCount + 1,
        recentlyAddedProductIds = requestBody.productId,
        showTrueFitSizeModal,
      } = addToCartResp;
      const productIds =
        recentlyAddedProductIds && recentlyAddedProductIds.split(',');
      const utagData = JSON.parse(ProductPageResp.utag__data__ajax);
      utagData.cart_total_items = ProductPageResp.totalNumberOfItems;
      utagData.cart_change_product_name = utagData?.product_name;
      utagData.cart_change_product_id = requestBody.productId;
      utagData.cart_change_product_cmos_sku = utagData?.product_cmos_sku;
      utagData.cart_change_product_cmos_item = utagData?.product_cmos_item;
      utagData.cart_change_nmo_sku_id = utagData?.nmo_sku_id;
      utagData.event_name = 'MiniCartResp';
      utagData.page_name = 'product detail';

      const cartPriceAndReqQuantity = {};
      cartPriceAndReqQuantity.quantity = requestBody.quantity;
      cartPriceAndReqQuantity.cart_change_product_price =
        utagData.cart_change_product_price &&
        Number(utagData.cart_change_product_price[0])
          ? utagData.cart_change_product_price[0]
          : null;
      updateUcaProfileCountCartItems(totalNumberOfItems);
      updateMiniCartItemCount(totalNumberOfItems)(dispatch);
      setRecentlyAddedItemCount(recentlyAddedItems)(dispatch);
      let extraParam;

      let dispatchType;
      if (isPlaExperience(state)) {
        dispatchType = PLA_ADD_PRODUCT_TO_BAG;
      } else if (isSourceQuickLook(state)) {
        dispatchType = QUICK_LOOK_ADD_PRODUCT_TO_BAG;
      } else if (isSourceOutfittingQuickLook(state)) {
        dispatchType = OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG;
        extraParam = {
          color: requestBody.color,
          size: requestBody.size,
          quantity: requestBody.quantity,
          skuId: requestBody.skuId,
        };
      } else {
        dispatchType = ADD_PRODUCT_TO_BAG;
      }

      updateGranifyCart(cartPriceAndReqQuantity, state, requestBody.skuId);

      dispatch({
        type: dispatchType,
        payload: { utagData, productIds, ...extraParam },
        productId: requestBody.productId,
      });

      if (gwpShowModal && showGWPMultiSkuModal) {
        openGwp(gwpProdId, gwpPromoKey)(dispatch, getState);
      } else {
        completeAddToBag()(dispatch, getState, isFromQL);
        if (typeof $ !== 'undefined') {
          const querySelector = `div#${requestBody.productId} .tfc-fitrec-result .tfc-popup-click-open`;
          if (showTrueFitSizeModal && $(querySelector).length !== 0) {
            $(querySelector)[0].click();
          }
        }
      }
    })
    .catch(() => {
      dispatch({ type: REJECTED_ADD_TO_BAG });
      if (showLoading) {
        dispatch({ type: `${REJECTED_ADD_TO_BAG}_${requestBody.skuId}` });
      }
      const errorMessage = generalAddToBagErrorMessage;
      !isSourceOutfittingQuickLook(state)
        ? showAddToBagError(requestBody.productId, errorMessage)(dispatch)
        : showOutfittingQLAddToBagError(requestBody.productId, errorMessage)(
            dispatch,
          );
      screenReaderAnnouncer(errorMessage)(dispatch);
    });
};

export function handleAddToBag(requestBody, showLoading, isFromQL) {
  return (dispatch, getState) => {
    const state = getState();
    const isDTapi = isNewCartCheckoutEnabled(state);
    if (showLoading) {
      dispatch({ type: `${LOADING_ADD_TO_BAG}_${requestBody.skuId}` });
    }
    dispatch({ type: LOADING_ADD_TO_BAG });
    if (isDTapi) {
      return handleDTCartapi(
        requestBody,
        showLoading,
        state,
        getState,
        dispatch,
        isFromQL,
      );
    } else {
      return handleATGCartApi(
        requestBody,
        showLoading,
        state,
        getState,
        dispatch,
        isFromQL,
      );
    }
  };
}

function handleInternationalAddToBag(requestBody) {
  return (dispatch, getState) => {
    const state = getState();
    const countryCode = get(state, 'locale.countryCode', 'US');
    const currencyCode = get(state, 'locale.currencyCode', 'USD');
    const intlCartName = `internationalCart_${countryCode}_${currencyCode}`;
    const localInternationalCart = (() => {
      const localInternationalCartData = localStorage.getItem(intlCartName);
      return localInternationalCartData
        ? JSON.parse(localInternationalCartData)
        : [];
    })();
    const isGroupProduct = get(state, 'productCatalog.product.isGroup', '');
    let inStockKey = '';
    let inStockItem = '';
    let internationalCartData = {};
    if (isGroupProduct) {
      const addedProduct = get(state, 'productCatalog.group.childProducts', '')[
        requestBody.productId
      ];
      inStockKey = findIndex(addedProduct.skus, { id: requestBody.skuId }, {});
      inStockItem = inStockKey !== -1 && addedProduct.skus[inStockKey];
      internationalCartData = {
        productQty: requestBody.quantity,
        productId: requestBody.productId,
        skuId: get(inStockItem, 'metadata.cmosSkuId', ''),
        color: requestBody.color,
        size: requestBody.size,
        name: addedProduct.designer.name,
        description: addedProduct.name,
        productUrl: addedProduct.details.canonicalUrl,
        images: addedProduct.media.main,
        price: addedProduct.price,
        inStock: inStockItem && inStockItem.inStock ? 'In Stock' : '',
        cmosCatalogId: addedProduct.metadata.cmosCatalogId,
        cmosItem: addedProduct.metadata.cmosItem,
        pimStyle: addedProduct.metadata.pimStyle,
        shippingStatusMessage: inStockItem.shippingStatusMessage,
        stockStatusMessage: inStockItem.stockStatusMessage,
        intlParentheticalAmount: get(
          addedProduct,
          'details.intlParentheticalAmount',
          0,
        ),
      };
    } else {
      inStockKey = findIndex(
        get(state, 'productCatalog.product.skus', ''),
        { id: requestBody.skuId },
        {},
      );
      inStockItem =
        inStockKey !== -1 &&
        get(state, 'productCatalog.product.skus', '')[inStockKey];
      internationalCartData = {
        productQty: requestBody.quantity,
        productId: requestBody.productId,
        skuId: get(inStockItem, 'metadata.cmosSkuId', ''),
        color: requestBody.color,
        size: requestBody.size,
        name:
          get(state, 'productCatalog.product.designer.name', '') ||
          get(state, 'productCatalog.product.name', ''),
        description: get(state, 'productCatalog.product.name', ''),
        productUrl: get(
          state,
          'productCatalog.product.details.canonicalUrl',
          '',
        ),
        images: get(state, 'productCatalog.product.media.main', ''),
        price: get(state, 'productCatalog.product.price', ''),
        inStock: inStockItem && inStockItem.inStock ? 'In Stock' : '',
        cmosCatalogId: get(
          state,
          'productCatalog.product.metadata.cmosCatalogId',
          '',
        ),
        cmosItem: get(state, 'productCatalog.product.metadata.cmosItem', ''),
        pimStyle: get(state, 'productCatalog.product.metadata.pimStyle', ''),
        intlParentheticalAmount: get(
          state,
          'productCatalog.product.details.intlParentheticalAmount',
          0,
        ),
      };
    }
    localInternationalCart.push(internationalCartData);
    localStorage.setItem(intlCartName, JSON.stringify(localInternationalCart));
    const totalCartItems = sumBy(localInternationalCart, item =>
      Number(item.productQty),
    );
    updateUcaProfileCountCartItems(totalCartItems);
    updateMiniCartItemCount(totalCartItems)(dispatch);
    showInternationalMiniCart()(dispatch, getState);
  };
}

export function toggleFavorite(productInfo, isFavorite) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state, 10000);
    const wid = get(state, 'utag.userSession.web_id', '');
    const ucid = get(state.user, 'ucid', '');

    function shouldShowFavoriteLimitExceeded(status) {
      return status === FAVORITE_MESSAGE;
    }
    const requestUrl = isFavorite
      ? `${NMConfig.API_REMOVE_FAVORITE}`
      : `${NMConfig.API_ADD_FAVORITE}`;
    if (isFavorite) {
      return requestApi
        .delete(`${requestUrl}/${wid}/${ucid}?favProdIds=${productInfo.id}`)
        .then(successResponse => {
          dispatch({
            type: TOGGLE_FAVORITE,
            payload: successResponse.data.status,
            productId: productInfo.id,
          });
          dispatch({
            type: SET_PRODUCT_DETAIL_FAVORITE_UTAG_DATA,
            payload: {
              eventId: FAVORITE_UTAG_EVENT_NAME,
              itemType: FAVORITE_UTAG_ITEM_TYPE,
              favoriteItemStatus: successResponse.data.status,
              cmosCatalogId: productInfo.cmosCatalogId,
              cmosItem: productInfo.cmosItem,
              pimStyle: productInfo.pimStyle,
              favoriteItem: productInfo.cmosItem,
            },
          });
        })
        .catch(() =>
          dispatch({
            type: TOGGLE_FAVORITE,
            payload: 'error',
            productId: productInfo.id,
          }),
        );
    } else {
      return requestApi
        .put(`${requestUrl}/${wid}/${ucid}?favProdIds=${productInfo.id}`)
        .then(successResponse => {
          dispatch({
            type: TOGGLE_FAVORITE,
            payload: successResponse.data.status,
            productId: productInfo.id,
          });
          dispatch({
            type: SET_PRODUCT_DETAIL_FAVORITE_UTAG_DATA,
            payload: {
              eventId: FAVORITE_UTAG_EVENT_NAME,
              itemType: FAVORITE_UTAG_ITEM_TYPE,
              favoriteItemStatus: successResponse.data.status,
              cmosCatalogId: productInfo.cmosCatalogId,
              cmosItem: productInfo.cmosItem,
              pimStyle: productInfo.pimStyle,
              favoriteItem: productInfo.cmosItem,
            },
          });
          if (shouldShowFavoriteLimitExceeded(successResponse.data.status)) {
            dispatch(
              openModal({
                type: 'ModalWithoutHeader',
                message: FAVORITES_LIMIT_EXCEEDED_MESSAGE,
              }),
            );
          }
        })
        .catch(() =>
          dispatch({
            type: TOGGLE_FAVORITE,
            payload: 'error',
            productId: productInfo.id,
          }),
        );
    }
  };
}

export function setPDPPaginationContext(context) {
  return dispatch => {
    dispatch({
      type: SET_PDP_PAGINATION_CONTEXT,
      payload: context,
    });
  };
}

export function getProductUrlList(
  requestOptions = {},
  successHandler,
  failureHandler,
) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      page = requestOptions.page,
      categoryId = requestOptions.categoryId,
      parentCategoryId = requestOptions.parentCategoryId,
      sortBy = requestOptions.sortBy,
      filterOptions = requestOptions.filterOptions,
      urlsOnly = 'true',
      fetchSize = requestOptions.fetchSize,
      profileId = requestOptions.profileId,
    } = requestOptions;

    const { session } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
        ENABLE_STUB_SERVICE: get(state, 'cookies.enableStubService'),
      }),
    };

    const requestApi = httpWithLogging(state, 10000, 8000);

    function getProductListApiURL(
      categoryId,
      parentCategoryId,
      page,
      sortBy,
      profileId,
    ) {
      const sortByParams = sortBy ? `&sortBy=${sortBy}` : '';
      return `${NMConfig.API_PRODUCT_LIST}?${stringify({
        categoryId,
        parentCategoryId,
        page,
        filterOptions,
        urlsOnly,
        fetchSize,
        profileId,
      })}${sortByParams}`;
    }

    const url = getProductListApiURL(
      categoryId,
      parentCategoryId,
      page,
      sortBy,
      profileId,
    );
    requestApi
      .get(url, { headers })
      .then(successResponse => {
        successHandler(successResponse.data);
      })
      .catch(error => {
        failureHandler(error);
      });
  };
}

export function setActiveStyleBoard(id) {
  return (dispatch, getState) => {
    const state = getState();
    return new Promise(resolve => {
      const recsList = get(
        state,
        'productCatalog.outfitting.outfittingRecs.csbList',
      );
      const minifiedProductResponse = get(
        state,
        'productCatalog.outfitting.minifiedProductResponse',
      );
      if (minifiedProductResponse[id]) {
        dispatch({ type: SET_ACTIVE_STYLE_BOARD, payload: id });
      } else {
        dispatch({ type: SHOW_LOADING });
        getMinifiedProductResponseCall(recsList, id)(dispatch, getState);
      }
      return resolve();
    });
  };
}

export function sourceQuickLook(isEnabled) {
  return dispatch => dispatch({ type: SOURCE_QUICK_LOOK, payload: isEnabled });
}

export function sourceOutfittingQuickLook(isEnabled) {
  return dispatch =>
    dispatch({ type: SOURCE_OUTFITTING_QUICK_LOOK, payload: isEnabled });
}

export function resetQLProduct(product) {
  return dispatch =>
    dispatch({ type: RESET_QUICK_LOOK_PRODUCT_PDP, payload: product });
}

export function showQLModalWindow(productId) {
  const data = {
    productId,
  };

  return (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT });
    const state = getState();
    const { session } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const { promos } = JSON.parse(get(session, 'dt_personalize_data', '{}'));
    const compositeEnabled = get(state, 'toggles.PDP_COMPOSITE_MODE', false);
    const uriPath = compositeEnabled
      ? `${NMConfig.API_PRODUCT_DETAIL_PDP_COMPOSITE}/${productId}`
      : `${NMConfig.API_PRODUCT_DETAIL_PDP}/${productId}`;
    const requestURI =
      promos && promos.length
        ? `${uriPath}?personalizedPromos=${promos}`
        : `${uriPath}`;
    const requestApi = httpWithLogging(state);
    return requestApi
      .get(requestURI, { headers })
      .then(successResponse => {
        dispatch({
          type: RESOLVED_QUICK_LOOK_PRODUCT_PDP,
          payload: { ...successResponse.data },
        });
        const product = successResponse.data || {};
        if (product.isGroup) {
          dispatch({ type: 'RESOLVED_GROUP', payload: successResponse.data });
        }
        if (!pageUtils.isMobile()) {
          dispatch(
            openModal({
              type: 'QLOutfittingSummary',
              props: data,
              className: 'ql-product-summary-pdp ql-optimized',
            }),
          );
        }
      })
      .catch(() => {
        dispatch(openModal({ type: 'ProductListErrorModal' }));
      });
  };
}

export function increasePDPQLQuantity(productId, value) {
  return (dispatch, getState) => {
    const state = getState();
    const PDP_OUTFITTING = get(state, 'toggles.PDP_OUTFITTING', false);
    if (PDP_OUTFITTING) {
      screenReaderAnnouncer(`quantity is ${value + 1}`)(dispatch);
    }
    dispatch({ type: INCREASE_PRODUCT_QL_QUANTITY, payload: value, productId });
  };
}

export function decreasePDPQLQuantity(productId, value) {
  return (dispatch, getState) => {
    const state = getState();
    const PDP_OUTFITTING = get(state, 'toggles.PDP_OUTFITTING', false);
    if (PDP_OUTFITTING) {
      screenReaderAnnouncer(`quantity is ${value - 1}`)(dispatch);
    }
    dispatch({ type: DECREASE_PRODUCT_QL_QUANTITY, payload: value, productId });
  };
}

export function updatePDPQLQuantity(productId, value) {
  return (dispatch, getState) => {
    const state = getState();
    const PDP_OUTFITTING = get(state, 'toggles.PDP_OUTFITTING', false);
    if (PDP_OUTFITTING) {
      screenReaderAnnouncer(`quantity is ${value}`)(dispatch);
    }
    dispatch({ type: UPDATE_PRODUCT_QL_QUANTITY, payload: value, productId });
  };
}

export function openEditWishlist(wishlistData) {
  return dispatch => {
    // const state = getState();
    return openModal({
      type: 'EditWishlistModal',
      props: { wishlistData },
      className: 'edit-wishlist-modal',
    })(dispatch);
  };
}

export function getWearItWithProducts() {
  return (dispatch, getState) => {
    const state = getState();

    const relatedProducts = get(
      state,
      'productCatalog.product.relatedProducts',
      [],
    );

    if (relatedProducts.length === 0) {
      return Promise.reject({});
    }

    const requestParams = `?productIds=${relatedProducts.join(',')}`;

    const compositeEnabled = get(state, 'toggles.PDP_COMPOSITE_MODE', false);
    const requestApi = httpWithLogging(state, 10000);
    let requestURI = compositeEnabled
      ? `${NMConfig.API_MINIFIED_SELLABLE_PRODUCT_DETAIL_PDP_COMPOSITE}${requestParams}`
      : `${NMConfig.API_MINIFIED_SELLABLE_PRODUCT_DETAIL_PDP}${requestParams}`;
    const currencyCode = get(state, 'locale.currencyCode', 'USD');
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      if (currencyCode !== 'USD') {
        let currencyQuery = `?currency=${currencyCode}`;
        if (requestURI.indexOf('?') > -1 || requestURI.indexOf('&') > -1) {
          currencyQuery = `&currency=${currencyCode}`;
        }
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }
    return requestApi.get(requestURI).then(successResponse => {
      const minifiedProductInfo = get(
        successResponse.data,
        'minifiedProductInfo',
        [],
      );

      dispatch({
        type: RESOLVED_WEARITWITH_PRODUCTS,
        payload: {
          data: minifiedProductInfo,
          loaded: true,
        },
      });
    });
  };
}

export const pdpLoadAnalytics = () => {
  return dispatch => {
    dispatch({
      type: PDP_LOAD_ANALYTICS,
    });
  };
};

export function getYmalRecommendation(product, ucaYmalBasedToggle) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_YAML_PRODUCT_RECOMMENDATIONS });
    const state = getState();
    const abTests = get(state, 'abTestsOpt', []);
    const filteredABTests = [];
    if (abTests) {
      Object.keys(abTests).forEach(test => {
        const { variation, analytics } = abTests[test];
        if (
          variation &&
          analytics &&
          test === PDP_YMAL_AB_TEST &&
          (variation === 'b' || variation === 'c')
        ) {
          filteredABTests.push(analytics);
        }
      });
    }
    const abTestString = filteredABTests.join(',');
    const requestApi = httpWithLoggingWithoutCustomHeaders(state, 8000, true);
    const headers = {
      brand: 'BG',
      type: 'ymal',
      group: abTestString,
      productid: product?.id,
    };
    let ucaID = '';
    const ucaValue = reactCookie.load('cstmr');
    if (ucaValue) {
      ucaID = ucaValue?.customerId;
    }
    if (!abTestString || ucaID === '') {
      return '';
    }
    const callString = `${NMConfig.API_PZP_PRODUCTS}/${ucaID}`;
    return requestApi
      .get(callString, { headers })
      .then(successResponse => {
        const pzpYmalData = successResponse?.data?.products || [];
        if (!isEmpty(pzpYmalData)) {
          dispatch({ type: RESOLVED_YAML_PRODUCT_RECOMMENDATIONS });
          getMinifiedProductInfoForStylyze(pzpYmalData, ucaYmalBasedToggle)(
            dispatch,
            getState,
          );
        } else {
          log.error(
            `No recommendations were returned by stylyze for the product ${product?.id}`,
          );
        }
      })
      .catch(() => dispatch({ type: REJECTED_YAML_PRODUCT_RECOMMENDATIONS }));
  };
}

export function getPdpRelatedCategory() {
  return (dispatch, state) => {
    dispatch({ type: LOADING_PDP_RELATED_CATS });
    const requestApi = httpWithLoggingWithoutCustomHeaders(state, 8000, true);
    const location = window.location.origin + window.location.pathname;
    return requestApi
      .get(`${NMConfig.PDP_RELATED_CATS}?url=${location}`)
      .then(({ data }) => {
        const relatedData = data?.related_links || [];
        dispatch({
          type: RESOLVED_PDP_RELATED_CATS,
          payload: relatedData,
        });
      })
      .catch(err => {
        dispatch({ type: REJECTED_PDP_RELATED_CATS });
        log.error(`Could not load Related categories , HTTP Status: ${err}`);
      });
  };
}

export function getPdpBroughtCategory() {
  return (dispatch, state) => {
    dispatch({ type: LOADING_PDP_BROUGHT_CATS });
    const requestApi = httpWithLoggingWithoutCustomHeaders(state, 8000, true);
    const location = window.location.origin + window.location.pathname;
    return requestApi
      .get(`${NMConfig.PDP_BROUGHT_CATS}?url=${location}`)
      .then(({ data }) => {
        const relatedData = data?.related_links || [];
        const broughtList = relatedData.map(item => {
          const stylyzeImgUrl = item?.image_url || '';
          const canonicalUrl = item?.url || '';
          const designerName = item?.brand || '';
          const broughtPrice = item?.price || '';
          let price = '';
          if (item?.original_price) {
            price = {
              currencyCode: '$',
              retailPrice: item?.original_price,
              adornments: [
                {
                  label: 'Original',
                  price: item?.original_price,
                },
                {
                  label: 'NOW',
                  price: item?.price,
                },
              ],
            };
          } else {
            price = { currencyCode: '$', retailPrice: item?.price };
          }
          item.price = price;
          return {
            ...item,
            stylyzeImgUrl,
            canonicalUrl,
            designerName,
            broughtPrice,
          };
        });
        dispatch({
          type: RESOLVED_PDP_BROUGHT_CATS,
          payload: broughtList,
        });
      })
      .catch(err => {
        dispatch({ type: REJECTED_PDP_BROUGHT_CATS });
        log.error(`Could not load Related Products , HTTP Status: ${err}`);
      });
  };
}

export function openTemplateDZoomModal(
  productId,
  images,
  index,
  isCloudinary,
  mobileZoom,
  zoomPdpToggle,
) {
  return dispatch => {
    return openModal({
      type: 'ProductZoomModal',
      props: { images, index, isCloudinary, mobileZoom, zoomPdpToggle },
      className: 'product-zoom-modal',
    })(dispatch);
  };
}

export function checkIfApplePayIsEligible() {
  return (dispatch, state) => {
    dispatch({ type: CHECKING_IF_APPLE_ELIGIBLE });
    const disableATGToggle = get(state, 'toggles.DISABLE_ATG_CALLS', false);
    const requestApi = httpWithLoggingWithoutCustomHeaders(state, 8000, true);
    return getUserDataWithGuestIdentityOn(state, true, true, disableATGToggle)
      .then(user => {
        return requestApi.get(`/dt/applepay/isEligible`, {
          headers: {
            accept: 'application/json',
            sourceApp: 'WB',
            authorization: `Bearer ${user.idToken}`,
            userId: user.ucaId,
          },
        });
      })
      .then(({ data }) => {
        dispatch({ type: RESOLVED_APPLE_ELIGIBLE, payload: data });
      })
      .catch(err => {
        dispatch({ type: REJECTED_APPLE_PAY, error: err });
      });
  };
}

export function getUserData() {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = get(state, 'toggles.DISABLE_ATG_CALLS', false);
    return getUserDataWithGuestIdentityOn(state, true, true, disableATGToggle);
  };
}
