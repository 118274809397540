import React from 'react';
import { YourPointsTexts } from '../constants';
import './yourPoints.scss';

const YourPoints = ({ pointBalance, totalPointsToNextReward }) => {
  let percentage = (pointBalance / 10000) * 100;
  let strikingDistance = totalPointsToNextReward - pointBalance;

  if (percentage < 0) {
    percentage = 0;
  } else if (percentage > 100) {
    percentage = 100;
  }

  if (strikingDistance < 0) {
    strikingDistance = 0;
  }

  return (
    <div className="your-points">
      <div className="your-points__title">
        <h2 className="your-points__title__content">{YourPointsTexts.title}</h2>
      </div>
      <h3 className="your-points__total-points">{pointBalance}</h3>
      <div className="your-points__progress">
        <div
          className="your-points__progress__bar"
          style={{ width: `${percentage}%` }}
        >
          <div className="your-points__progress__bar__percent" />
        </div>
      </div>
      {strikingDistance < 3000 && (
        <div className="your-points__striking-distance">
          <h3 className="your-points__striking-distance__content">
            {strikingDistance} {YourPointsTexts.strikingDistance}
          </h3>
        </div>
      )}
    </div>
  );
};

export default YourPoints;
