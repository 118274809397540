import cookie from 'react-cookie';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import axios from 'axios';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { getGuestTokens, isGuestValid } from 'bgo-common/client/guestauth';

import { SESSION_REFRESH_INTERVAL } from '../common/constants';

import {
  deleteKmsiStatus,
  getKmsiSessionStatus,
  isKmsiSet,
} from './utilities-kmsi';

const CUSTOM_CHALLENGE = 'CUSTOM_CHALLENGE';
export const amplifyErrors = {
  USER_NOT_FOUND_EXCEPTION: 'UserNotFoundException',
  NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
  INVALID_PARAMETER_EXCEPTION: 'InvalidParameterException',
  UNSUPPORTED_CHALLENGE: 'UnsupportedChallenge',
  PASSWORD_RESET_REQUIRED_EXCEPTION: 'PasswordResetRequiredException',
};

export const obtainAtgSessionFromJwtToken = (
  jwtToken,
  justRegistered = false,
  disableATGToggle = false,
) => {
  const headers = {
    Authorization: `Bearer ${jwtToken}`,
    'Content-Type': 'application/json',
  };

  return disableATGToggle
    ? Promise.resolve()
    : axios.post('/migration/profile/login', { justRegistered }, { headers });
};

export const obtainAtgSession = (
  user,
  justRegistered = false,
  disableATGToggle = false,
) => {
  return obtainAtgSessionFromJwtToken(
    user.signInUserSession.idToken.jwtToken,
    justRegistered,
    disableATGToggle,
  );
};

export const updateAtgSessionIfLoggedIn = disableATGToggle => {
  return new Promise(resolve => {
    Auth.currentSession()
      .then(session => {
        obtainAtgSessionFromJwtToken(
          session.getIdToken().getJwtToken(),
          false,
          disableATGToggle,
        )
          .then(() => {
            resolve({
              amplifyLogin: true,
              atgSession: true,
            });
          })
          .catch(() => {
            resolve({
              amplifyLogin: true,
              atgSession: false,
            });
          });
      })
      .catch(() => {
        resolve({
          amplifyLogin: false,
          atgSession: false,
        });
      });
  });
};

export const signOutAndDeleteJSessionIdCookie = () => {
  return Auth.signOut().then(() => {
    document.cookie =
      'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    deleteKmsiStatus();
  });
};

let atgSessionUpdateInterval;

/** *
 * add obtainAtgSession to refreshSession implementation
 */
const superRefreshSession = CognitoUser.prototype.refreshSession;
CognitoUser.prototype.refreshSession = function refreshSession(
  refreshToken,
  callback,
  clientMetadata,
) {
  const self = this;

  const cb = () => {
    if (getKmsiSessionStatus()) {
      obtainAtgSession(self, false);
    }
    return callback(null, self.signInUserSession);
  };
  superRefreshSession.call(self, refreshToken, cb, clientMetadata);
};

export const configureAmplify = (
  config,
  isNewCheckout = false,
  disableATGToggle = false,
) => {
  Amplify.configure({ Auth: config });

  const falseValue = {
    amplifyLogin: false,
    atgSession: false,
  };

  if (isKmsiSet()) {
    if (isNewCheckout) {
      if (!getKmsiSessionStatus()) {
        // KMSI is turned off and browser has been closed
        return signOutAndDeleteJSessionIdCookie().then(() => falseValue);
      }
    } else {
      if (!atgSessionUpdateInterval) {
        atgSessionUpdateInterval = setInterval(() => {
          updateAtgSessionIfLoggedIn(disableATGToggle);
        }, SESSION_REFRESH_INTERVAL);
      }

      if (getKmsiSessionStatus()) {
        // KMSI is turned on or browser hasn't been closed after login
        return updateAtgSessionIfLoggedIn(disableATGToggle);
      } else {
        // KMSI is turned off and browser has been closed
        return signOutAndDeleteJSessionIdCookie().then(() => {
          return falseValue;
        });
      }
    }
  }
  return Promise.resolve(falseValue);
};

export const getIdToken = (config, isNewCheckout, disableATGToggle) => {
  configureAmplify(config, isNewCheckout, disableATGToggle);
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        const token = session.getIdToken().getJwtToken();
        const userPayload = session?.accessToken?.payload;
        resolve({
          idToken: token,
          user: userPayload,
        });
      })
      .catch(e => reject({ error: e }));
  });
};

export const getIdTokenAndUserInfo = (
  config,
  isNewCheckout,
  isAtgSync = true,
  disableATGToggle,
) => {
  if (isAtgSync) {
    configureAmplify(config, isNewCheckout, disableATGToggle);
  } else {
    Amplify.configure({ Auth: config });
  }
  return new Promise((resolve, reject) => {
    const currentSession = Auth.currentSession();
    const userInfo = Auth.currentUserInfo();

    Promise.all([currentSession, userInfo])
      .then(([session, info]) => {
        const token = session.getIdToken().getJwtToken();
        resolve({
          tokenInfo: {
            idToken: token,
          },
          userInfo: info,
        });
      })
      .catch(e => reject({ error: e }));
  });
};

export const getUserDataWithGuestIdentityOn = (
  state,
  isNewCheckout,
  disableATGToggle,
) => {
  return new Promise((resolve, reject) =>
    getIdTokenAndUserInfo(state.config, isNewCheckout, true, disableATGToggle)
      .then(info => {
        const ucaId = info?.userInfo?.attributes?.sub || null;
        const idToken = info?.tokenInfo?.idToken || null;
        resolve({ ucaId, idToken });
      })
      .catch(() => {
        isGuestValid(state)
          .then(() => {
            const { AccessToken, Sub } = getGuestTokens(['AccessToken', 'Sub']);
            resolve({
              ucaId: Sub,
              idToken: AccessToken,
            });
          })
          .catch(e => reject({ error: e }));
      }),
  );
};

export const getUserDataAsGuest = state => {
  return new Promise((resolve, reject) => {
    isGuestValid(state)
      .then(() => {
        const { AccessToken, Sub } = getGuestTokens(['AccessToken', 'Sub']);
        resolve({
          ucaId: Sub,
          idToken: AccessToken,
        });
      })
      .catch(e => reject({ error: e }));
  });
};

export const getUserInfo = (config, isNewCheckout, disableATGToggle) => {
  configureAmplify(config, isNewCheckout, disableATGToggle);
  return Auth.currentUserInfo();
};

export const setUcaProfileCookie = (user, successCb) => {
  const token = user?.signInUserSession?.idToken?.jwtToken || null;
  const webProfileId = user?.attributes?.preferred_username || null;
  const ucaProfileId = user?.attributes?.sub || null;
  const callString = `${NMConfig.API_CART_COUNT}${ucaProfileId}/cart/count`;

  const ucaProfileData = cookie.load('ucaProfileData');
  const cartItemCount = axios
    .get(callString, {
      headers: {
        sourceApp: 'WB',
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => {
      return res.data;
    });

  Promise.allSettled([cartItemCount]).then(result => {
    cookie.save(
      'ucaProfileData',
      {
        ...ucaProfileData,
        firstName: user?.attributes?.given_name, //eslint-disable-line
        securityStatus: 'Authenticated',
        cartItemCount: result[0]?.value || 0,
        customer_email: user?.attributes?.email,
        universal_customer_id: user?.attributes?.sub,
        logged_in_status: true,
        customer_registered: true,
        profile_type: 'customer',
        customer_segment:
          window?.sessionStorage?.getItem('loyaltyData')?.segment || '0',
        countryPreference: ucaProfileData?.countrypreference || 'US',
        currencyPreference: ucaProfileData?.currencyPreference || 'USD',
        localeUrl: ucaProfileData?.localeUrl || '/en-us',
        loyaltyProfileId: user?.attributes?.loyaltyProfileId,
      },
      { path: '/', domain: '.bergdorfgoodman.com' },
    );
    return successCb(user);
  });
};
export const logoutUcaProfileCookie = () => {
  const ucaProfileData = cookie.load('ucaProfileData');
  cookie.save(
    'ucaProfileData',
    {
      ...ucaProfileData,
      firstName: '',
      securityStatus: 'Anonymous',
      cartItemCount: 0,
      customer_email: '',
      universal_customer_id: '',
      logged_in_status: false,
      customer_registered: false,
      profile_type: 'customer',
      customer_segment: '0',
      countryPreference: ucaProfileData?.countrypreference || 'US',
      currencyPreference: ucaProfileData?.currencyPreference || 'USD',
      localeUrl: ucaProfileData?.localeUrl || '/en-us',
    },
    { path: '/', domain: '.bergdorfgoodman.com' },
  );
};

export const amplifyLogin = (
  email,
  password,
  errorMethod = () => {},
  justRegistered,
  successCallback = () => {},
  disableATGToggle,
  ucaProfileCookieToggle = false,
  dtLoginFlow = false,
) => {
  const lowerCaseEmail = email.trim().toLowerCase();
  const successCbandSetCookie = user => {
    if (ucaProfileCookieToggle) {
      setUcaProfileCookie(user, successCallback);
    } else successCallback(user);
  };

  if (dtLoginFlow) {
    Auth.signIn({ username: lowerCaseEmail, password })
      .then(user =>
        obtainAtgSession(user, justRegistered, disableATGToggle).then(() =>
          successCbandSetCookie(user),
        ),
      )
      .catch(({ code, message }) => {
        if (message && message.split('|').length === 3) {
          const messageToDisplay = message.split('|')[1];
          errorMethod(`AccountLockedError|${messageToDisplay}`);

          return;
        }

        // add captcha error message

        errorMethod(code);
      });

    return;
  }

  Auth.signIn(lowerCaseEmail)
    .then(user => {
      if (user.challengeName === CUSTOM_CHALLENGE) {
        Auth.sendCustomChallengeAnswer(user, password)
          .then(user2 => {
            return obtainAtgSession(
              user2,
              justRegistered,
              disableATGToggle,
            ).then(() => successCbandSetCookie(user2));
          })
          .catch(({ code }) => {
            errorMethod(code);
          });
      } else {
        // this shouldn't happen, we expect challengeName to always be CUSTOM_CHALLENGE
        errorMethod(amplifyErrors.UNSUPPORTED_CHALLENGE);
      }
    })
    .catch(({ code }) => {
      if (
        code === amplifyErrors.USER_NOT_FOUND_EXCEPTION ||
        code === amplifyErrors.NOT_AUTHORIZED_EXCEPTION ||
        code === amplifyErrors.INVALID_PARAMETER_EXCEPTION
      ) {
        Auth.signIn(lowerCaseEmail, password)
          .then(user => {
            return obtainAtgSession(
              user,
              justRegistered,
              disableATGToggle,
            ).then(() => successCbandSetCookie(user));
          })
          .catch(() => {
            errorMethod(code);
          });
      } else errorMethod(code);
    });
};

export const amplifySignUp = (
  email,
  password,
  firstName,
  lastName,
  signupErrCallback = () => {},
  loginCallbackError = () => {},
  successCallback = () => {},
  disableATGToggle,
  ucaProfileCookieToggle,
  dtLoginFlow,
  loyaltyData = {},
  phoneNumber = '',
) => {
  const getPhone = () => {
    if (loyaltyData?.loyaltyToggle) {
      return {
        phone_number: `+1${loyaltyData?.phoneNumber.replaceAll('-', '')}`,
      };
    } else if (phoneNumber) {
      return {
        phone_number: `+1${phoneNumber.replaceAll('-', '')}`,
      };
    }

    return {};
  };

  const attributes = {
    given_name: firstName.trim(),
    family_name: lastName.trim(),
    ...getPhone(),
    'custom:temp': password,
    'custom:brand': 'BG',
  };

  const clientMetadata = loyaltyData?.loyaltyToggle
    ? {
        clientMetadata: {
          enrollToLoyalty: JSON.stringify(loyaltyData?.enrollLoyalty),
          birthday: `${loyaltyData.month} ${loyaltyData.day}`,
          // "channel": "values TBD"
          // optional enrollmentSource - scenarios to be defined by business
          // "enrollmentSource": "EMAIL|SMS|CALLCENTER|SURVEY|DIRECTPH|SOCIALMED|DIRECTMAIL|REFERRAL|INVITEONLY|MIGRATION"
        },
      }
    : {};
  Auth.signUp({
    username: email.trim().toLowerCase(),
    password,
    attributes,
    ...clientMetadata,
  })
    .then(() => {
      const justRegistered = true;
      amplifyLogin(
        email,
        password,
        loginCallbackError,
        justRegistered,
        successCallback,
        disableATGToggle,
        ucaProfileCookieToggle,
        dtLoginFlow,
      );
    })
    .catch(({ message }) => {
      signupErrCallback(message);
    });
};

export const validateEmail = email => {
  // simple minimal check: a@b.cc
  const regex = /.+@.+\..{2,}/;
  return !!email && regex.test(email);
};

export const entryValidation = (email, password) => {
  return !!(email && password && validateEmail(email.trim()));
};

export const entryEmailValidation = email => {
  return !!(email && validateEmail(email.trim()));
};

export const entryPasswordValidation = password => {
  return !!password;
};

export const logout = (disableATGToggle = false) => {
  Auth.signOut().then(() => {
    logoutUcaProfileCookie();
    window.location.href = !disableATGToggle
      ? '/profile.service?action=logout'
      : '/';
  });
};

export const silentLogout = (disableATGToggle = false) => {
  return Auth.signOut().then(() => {
    logoutUcaProfileCookie();
    return !disableATGToggle
      ? axios.get('/profile.service?action=logout')
      : Promise.resolve();
  });
};
