import React from 'react';
import { connect } from 'react-redux';
import InCircleLogoLanding from 'assets/images/InCircleLogoLanding.svg';
import WhyInCircle from '../NewBgCreditCardPage/WhyInCircle';
import './index.scss';

const PublicLoyaltyPage = () => {
  return (
    <div className="public-loyalty-page">
      <section className="public-loyalty-page__header">
        <img src={InCircleLogoLanding} alt="InCircle" alr="InCircle" />
      </section>
      <section className="public-loyalty-page__content">
        <WhyInCircle />
      </section>
    </div>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicLoyaltyPage);
