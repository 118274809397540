import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { array, func, object, bool } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  PAGE_ID_MY_ORDERS,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import { withRouter } from 'react-router';
import Spinner from 'shared/components/Spinner/simpleSpinner';
import { useConstructor } from '../../../../utilities/hooks';
import Copy from '../../../common/Copy/Copy';
import { getOrdersForMyAccount } from './actions';
import { getContentForMyAccount, setResolvedPage } from '../../actions';
import { RESOLVED_MY_ORDERS_PAGE } from '../../constants';
import MyAccountMobileBackHeader from '../MyAccountPage/MyAccountBackHeader/MyAccountMobileBackHeader';
import MyOrders from './MyOrders';
import OrderDetails from './OrderDetails';
import './MyOrders.scss';

/**
 * @function NoOrders
 * @param {Object} props.content
 * @returns {React.Element}
 */
const NoOrders = ({ content }) => {
  const MYORDERSDEFAULT = content?.['MYORDERS-DEFAULT'];
  const MYORDERSCTA = content?.['MYORDERS-CTA'];

  return (
    <div className="myorder-info">
      {MYORDERSDEFAULT && (
        <div className="myorder-info__default">
          <Copy content={MYORDERSDEFAULT} />
        </div>
      )}
      {MYORDERSCTA && (
        <div className="myorder-info__cta">
          <Copy content={MYORDERSCTA} />
        </div>
      )}
    </div>
  );
};

/**
 * @function MyOrdersPage
 * @param {Function} props.getContentForMyAccount
 * @param {Function} props.getOrdersForMyAccount
 * @param {Object} props.myAccountContent
 * @param {Object} props.myAccountOrders
 * @param {Function} props.setPageId
 * @param {Function} props.setResolvedPage
 * @returns {React.ReactElement}
 */
const MyOrdersPage = ({
  getContentForMyAccount,
  getOrdersForMyAccount,
  myAccountContent,
  myAccountOrders,
  setPageId,
  setResolvedPage,
  router,
  location,
  isLoading,
}) => {
  const params = new URLSearchParams(location.search);
  const orderId = params.get('orderId');
  const PAGE_NAME = orderId ? 'Order Details' : 'My Orders';

  useConstructor(() => {
    setPageId(PAGE_ID_MY_ORDERS);
  });

  useEffect(() => {
    setResolvedPage(RESOLVED_MY_ORDERS_PAGE);
    getContentForMyAccount();
    getOrdersForMyAccount();
  }, []);

  function onOrderDetailsClick(id) {
    router.push({
      pathname: '/e/myaccount/myorders',
      search: `?orderId=${id}`,
    });
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="myorders-page">
      <MyAccountMobileBackHeader title={PAGE_NAME} />
      <div className="no-myorders">
        <h1 className="my-account-page__main-header desktop-tablet-only">
          {PAGE_NAME}
        </h1>
        {isEmpty(myAccountOrders) && <NoOrders content={myAccountContent} />}
      </div>
      {orderId ? (
        <OrderDetails orderId={orderId} />
      ) : (
        !isEmpty(myAccountOrders) && (
          <MyOrders
            orders={myAccountOrders}
            orderDetailsClick={onOrderDetailsClick}
          />
        )
      )}
    </div>
  );
};

MyOrdersPage.propTypes = {
  getContentForMyAccount: func.isRequired,
  getOrdersForMyAccount: func.isRequired,
  myAccountContent: object.isRequired,
  myAccountOrders: array.isRequired,
  setPageId: func.isRequired,
  setResolvedPage: func.isRequired,
  isLoading: bool.isRequired,
};

const mapStateToProps = state => ({
  myAccountContent: state?.myaccount?.myAccountContent || true,
  myAccountOrders: state?.myaccount?.myAccountOrders || true,
  isLoading:
    state?.api?.orders?.loading || state?.api?.content?.loading || false,
});

const mapDispatchToProps = {
  getOrdersForMyAccount,
  getContentForMyAccount,
  setPageId,
  setResolvedPage,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MyOrdersPage),
);
