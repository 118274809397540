import React, { Component } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { getIdTokenAndUserInfo } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import groupBy from 'lodash/groupBy';
import classnames from 'classnames';
import Spinner from 'shared/components/Spinner/simpleSpinner';
import { Form, Formik } from 'formik';
import {
  PAGE_ID_ORDER_DETAILS,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import { getOrdersDetails, cancelOrder } from './actions';
import {
  getOrderStateFromCode,
  getDeliveryMethod,
  returnStatus,
} from './StatusActions';
import './MyOrders.scss';
import Copy from '../../../common/Copy/Copy';
import Image from '../../../../../../../bgo-ui-pdp/src/client/components/pdp/components/ProductPage/components/Image/image';
import { RESOLVED_ORDER_DETAILS_PAGE } from '../../constants';
import { setResolvedPage } from '../../actions';
import FormikDropDownField from '../../../common/Forms/FormikDropDown/FormikDropDown';
import SubmitButton from '../../../common/Forms/FormButtons/SubmitButton/SubmitButton';

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelItems: {},
      cancelOrders: [],
    };
    props.setPageId(PAGE_ID_ORDER_DETAILS);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.props.getOrdersDetails(this.props.orderId);
    this.props.setResolvedPage(RESOLVED_ORDER_DETAILS_PAGE);
  }

  async navigateTrack(trackUrl) {
    const info = await getIdTokenAndUserInfo(
      this.props.amplifyConfig,
      this.props.isNewCartCheckoutEnabled,
      true,
      this.props.disableATGToggle,
    );
    const ucaId = get(info, 'userInfo.attributes.sub', null);
    const defaultTrackUrl = `https://bergdorfgoodman.narvar.com/bergdorfgoodman/tracking/Fedex?tracking_numbers=null&webId=${ucaId}`;
    window.open(trackUrl || defaultTrackUrl, 'blank');
  }

  async navigateReturn() {
    const { orderDetails } = this.props;
    const info = await getIdTokenAndUserInfo(
      this.props.amplifyConfig,
      this.props.isNewCartCheckoutEnabled,
      true,
      this.props.disableATGToggle,
    );
    const ucaId = get(info, 'userInfo.attributes.sub', null);
    const userEmail = get(info, 'userInfo.attributes.email', null);
    const orderNumber = orderDetails.formattedOrderId;
    const trackUrl = `${NMConfig.START_A_RETURN}&order=${orderNumber}&email=${userEmail}&webId=${ucaId}`;
    window.open(trackUrl, 'blank');
  }

  renderItem(item) {
    const { myAccountContent } = this.props;
    const DELIVERYMETHOD = get(
      myAccountContent,
      'ORDERDETAILS-DELIVERY-METHOD',
    );
    const TRACKORDER = get(myAccountContent, 'TRACK-ORDER-CTA');

    const orderStatusCode = get(item[0], 'serviceLevelCode', null);
    const trackNumber = get(item[0], 'narvarTrackingLink', null);
    const orderDliveryMethod = getDeliveryMethod(orderStatusCode);
    const isCancellable =
      item[0].status === 'CX' || item[0].systemStatus === '9000';
    const isShipped =
      item[0].status === 'VS' || item[0].systemStatus === '7000';
    const itemsInfo = map(item, 'productName').join(', ');

    if (!isCancellable) {
      return (
        <div className="deliveryMethod">
          <div className="itemDetails">
            <div className="deliveryDetails">
              <div className="price-methods">
                <p className="orderDelivery">
                  {' '}
                  <Copy content={DELIVERYMETHOD} />
                </p>
                <p>:&nbsp;</p>
              </div>
              <p>{orderDliveryMethod}</p>
            </div>
            {isShipped && (
              <div className="deliveryDetails">
                <div className="price-methods">
                  <p className="orderDelivery">Items</p>
                  <p>:&nbsp;</p>
                </div>
                <p>{itemsInfo}</p>
              </div>
            )}
          </div>
          {!isCancellable && (
            <div className="order-button-wrapper">
              {TRACKORDER && (
                <div
                  onClick={() => this.navigateTrack(trackNumber)}
                  onKeyPress={() => {}}
                >
                  {' '}
                  <Copy content={TRACKORDER} />{' '}
                </div>
              )}
            </div>
          )}
        </div>
      );
    } else {
      return <span />;
    }
  }

  onCancel(itemId) {
    const { cancelItems } = this.state;
    cancelItems[itemId] = !cancelItems[itemId];
    this.setState({
      cancelItems,
    });
  }

  submitForm(values) {
    this.props.cancelOrder(values);
    const { itemId } = values;
    const { cancelItems, cancelOrders } = this.state;
    cancelItems[itemId] = !cancelItems[itemId];
    this.setState({
      cancelItems,
      cancelOrders: cancelOrders.concat(itemId),
    });
  }

  renderOrder(order) {
    const { myAccountContent } = this.props;
    const TRACKORDER = get(myAccountContent, 'TRACK-ORDER-CTA');
    const ORDERCANCELLED = get(
      myAccountContent,
      'ORDERDETAILS-CANCEL-CONFIRMATION',
    );
    const RETURNTEXT = get(myAccountContent, 'START-RETURN-DEFAULT');
    const RETURNBUTTON = get(myAccountContent, 'START-RETURN-CTA');
    const DELIVERYADDRESS = get(
      myAccountContent,
      'ORDERDETAILS-DELIVERY-ADDRESS',
    );
    const ORDERDETAILSCOLOR = get(myAccountContent, 'ORDERDETAILS-COLOR');
    const ORDERDETAILSDATE = get(myAccountContent, 'ORDERDETAILS-DATE');
    const DELIVERYMETHOD = get(
      myAccountContent,
      'ORDERDETAILS-DELIVERY-METHOD',
    );
    const ORDERDETAILSPAYMENTMETHOD = get(
      myAccountContent,
      'ORDERDETAILS-PAYMENT',
    );
    const ORDERDETAILSQUANTITY = get(myAccountContent, 'ORDERDETAILS-QUANTITY');
    const ORDERDETAILSSHIPPING = get(myAccountContent, 'ORDERDETAILS-SHIPPING');
    const ORDERDETAILSSIZE = get(myAccountContent, 'ORDERDETAILS-SIZE');
    const ORDERDETAILSSUBTOTAL = get(myAccountContent, 'ORDERDETAILS-SUBTOTAL');
    const ORDERDETAILSTAX = get(myAccountContent, 'ORDERDETAILS-TAX');
    const ORDERDETAILSTOTAL = get(myAccountContent, 'ORDERDETAILS-TOTAL');
    const ORDERREQUESTCANCELATION = get(
      myAccountContent,
      'ORDERDETAILS-REQUEST-CANCELLATION',
    );
    const orderDetails = map(order.orderDetails, 'items', []);
    const orderSourceSystem = get(order, 'orderSourceSystem', '');
    const isMaoOrder = orderSourceSystem === 'MA';
    const items = orderDetails.length
      ? orderDetails.reduce((items, item) => [...items, ...item])
      : [];
    const paymentMethod = get(order, 'paymentDetails[0]', {});
    const orderStatus = isMaoOrder
      ? map(items, 'systemStatus')
      : map(items, 'status');
    const orderDates = map(items, 'currentStatusDate');
    const reasons = [
      {
        name: '',
        value: '',
      },
      {
        name: 'Not willing to wait',
        value: 'CXBO',
      },
      {
        name: 'No longer interested',
        value: 'DNW',
      },
    ];
    const isCancellable = getOrderStateFromCode(orderStatus, 1);
    const isReturnable = returnStatus(orderStatus, orderDates);
    const orderStatusCode = get(
      order,
      'orderDetails[0].items[0].serviceLevelCode',
      null,
    );
    const trackNumber = get(
      order,
      'orderDetails[0].items[0].narvarTrackingLink',
      null,
    );
    const orderDliveryMethod = getDeliveryMethod(orderStatusCode);
    const shippingAddress = get(order, 'orderDetails[0].shippingAddress', {});
    const itemData = groupBy(items, item => item.trackingLink);
    const RETURN_STATUSES = [
      'RT',
      'RX',
      'RN',
      'RR',
      'RW',
      'SR',
      'PL',
      'PD',
      '8500',
      '8000',
    ];
    const CANCEL_RETURN_STATUS = ['CX', 'RT', '9000', '8500'];
    const itemShipping = items.reduce(
      (total, item) =>
        total +
        (!RETURN_STATUSES.includes(isMaoOrder ? item.systemStatus : item.status)
          ? Number(item.freight)
          : 0),
      0,
    );
    const itemsInfo = map(items, 'productName').toString();
    const itemStatus = isMaoOrder
      ? get(order, 'orderDetails[0].items[0].systemStatus', null)
      : get(order, 'orderDetails[0].items[0].status', null);
    const { cancelItems, cancelOrders } = this.state;
    const cancelPending = 'Cancellation Pending';
    const subTotal = items.reduce(
      (total, item) =>
        total +
        (!CANCEL_RETURN_STATUS.includes(
          isMaoOrder ? item.systemStatus : item.status,
        )
          ? Number(item.price)
          : 0),
      0,
    );
    const tax = items.reduce(
      (total, item) =>
        total +
        (!CANCEL_RETURN_STATUS.includes(
          isMaoOrder ? item.systemStatus : item.status,
        )
          ? Number(item.tax)
          : 0),
      0,
    );
    const total = subTotal + tax + itemShipping;
    return (
      <div className="orderDetails">
        <div className="myOrderDetails noBorder">
          <p className="deliveryDetails myOrderDate">
            <Copy content={ORDERDETAILSDATE} />: {order.orderDate}
          </p>
          <p>{order.formattedOrderId}</p>
        </div>

        {isCancellable && !this.props.isCancelVisible && (
          <div className="myOrderDetails">
            <div className="cancel">
              <div className="cancelText">
                <p>
                  {isCancellable && ORDERREQUESTCANCELATION && (
                    <Copy content={ORDERREQUESTCANCELATION} />
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
        {Object.keys(itemData).length > 1 ? (
          Object.keys(itemData).map(
            item => itemData[item] && this.renderItem(itemData[item], item),
          )
        ) : (
          <div className="deliveryMethod">
            <div className="itemDetails">
              <div className="deliveryDetails">
                <div className="price-methods">
                  <p className="orderDelivery">
                    {' '}
                    <Copy content={DELIVERYMETHOD} />
                  </p>
                  <p>:&nbsp;</p>
                </div>
                <p>{orderDliveryMethod}</p>
              </div>
              {(itemStatus === 'VS' || itemStatus === '7000') && (
                <div className="deliveryDetails">
                  <div className="price-methods">
                    <p className="orderDelivery">Items</p>
                    <p>:&nbsp;</p>
                  </div>
                  <p>{itemsInfo}</p>
                </div>
              )}
            </div>
            {(itemStatus !== 'CX' || itemStatus !== '9000') && (
              <div className="order-button-wrapper">
                {TRACKORDER && (
                  <div
                    onClick={() => this.navigateTrack(trackNumber)}
                    onKeyPress={() => {}}
                  >
                    {' '}
                    <Copy content={TRACKORDER} />{' '}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {(itemStatus === 'CX' ||
          itemStatus === 'VS' ||
          itemStatus === '9000' ||
          itemStatus === '7000') && (
          <div className="myOrderDetails">
            <div className="cancel">
              <div className="cancelText">
                <p>
                  {!isReturnable
                    ? ORDERCANCELLED && <Copy content={ORDERCANCELLED} />
                    : RETURNTEXT && <Copy content={RETURNTEXT} newTab />}
                </p>
              </div>
              {isReturnable && (
                <div className="order-button-wrapper">
                  {RETURNBUTTON && (
                    <div
                      onClick={() => this.navigateReturn()}
                      onKeyPress={() => {}}
                    >
                      {' '}
                      <Copy content={RETURNBUTTON} />{' '}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="addressDetails">
          <div className="">
            <div className="price-methods">
              <p className="titleDetails">
                <Copy content={DELIVERYADDRESS} />
              </p>
              <p>:</p>
            </div>
            <p>{shippingAddress.line1}</p>
            <p>{`${shippingAddress.city},  ${shippingAddress.stateCode}`}</p>
            <p>{shippingAddress.countryCode}</p>
            <p>{shippingAddress.zipCode}</p>
          </div>
          {Object.keys(paymentMethod) && (
            <div className="order-details-payment">
              <div className="price-methods">
                <p className="titleDetails">
                  <Copy content={ORDERDETAILSPAYMENTMETHOD} />{' '}
                </p>
                <p>:</p>
              </div>
              {paymentMethod.type && this.renderLogo(paymentMethod.type)}
            </div>
          )}
        </div>

        <div className="order-details">
          {items.map(item => (
            <div className="order-item">
              <Image
                className={classnames(
                  'prod-item',
                  CANCEL_RETURN_STATUS.includes(
                    isMaoOrder ? item.systemStatus : item.status,
                  ) && 'returnContainer',
                )}
                src={item.imageUrl}
              />
              {(item.status === 'RT' || item.systemStatus === '8500') && (
                <div className="statusReturned">Returned</div>
              )}
              {(item.status === 'CX' || item.systemStatus === '9000') && (
                <div className="statusReturned">Cancelled</div>
              )}
              <div
                className={classnames(
                  'prod-detail',
                  CANCEL_RETURN_STATUS.includes(
                    isMaoOrder ? item.systemStatus : item.status,
                  ) && 'returnContainer',
                )}
              >
                <p className="prod-brand">{item.brand}</p>
                <p className="prod-name">{item.productName}</p>
                <p className="prod-methods">
                  <Copy content={ORDERDETAILSCOLOR} /> : {item.color}
                </p>
                <p className="prod-methods">
                  <Copy content={ORDERDETAILSSIZE} /> : {item.size}
                </p>
                <p className="prod-methods">
                  <Copy content={ORDERDETAILSQUANTITY} /> : {item.quantity}
                </p>
                <p className="prod-price">${item.price}</p>
                {cancelOrders.includes(item.externalLineItemId) && (
                  <div className="cancelPending"> {cancelPending}</div>
                )}
                {getOrderStateFromCode(
                  isMaoOrder ? item.systemStatus : item.status,
                  1,
                ) &&
                  this.props.isCancelVisible &&
                  !cancelOrders.includes(item.externalLineItemId) && (
                    <div>
                      <a
                        className="cancelLink"
                        onClick={() => this.onCancel(item.externalLineItemId)}
                      >
                        CANCEL
                      </a>
                      {cancelItems[item.externalLineItemId] && (
                        <Formik
                          onSubmit={this.submitForm}
                          initialValues={{
                            reason: '',
                            itemId: item.externalLineItemId,
                            orderId: this.props.cancelOrderV3Toggle
                              ? order.cmosOrderNumber
                              : order.formattedOrderId,
                          }}
                        >
                          {({ values }) => (
                            <Form>
                              <div className="myorders-page_reason-field">
                                <FormikDropDownField
                                  valuesArray={reasons}
                                  id="reason-field"
                                  name="reason"
                                  placeholder="Select a Reason"
                                  value={values.reason}
                                />
                              </div>
                              <div
                                className={classnames(
                                  values.reason === '' && 'divDisabled',
                                )}
                              >
                                <SubmitButton
                                  type="submit"
                                  caption="CANCEL ORDER"
                                  additionalClasses="new-account-register-page__submit-btn"
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      )}
                    </div>
                  )}
              </div>
            </div>
          ))}
        </div>

        <div className="paymentContainer">
          <div className="paymentMethod">
            <div className="price-methods">
              <Copy content={ORDERDETAILSSUBTOTAL} />
              <p className="subTotal">{`( ${items.length} items)`}</p>
            </div>
            {this.props.isSubtotalCalculated ? (
              <p>${subTotal.toFixed(2)}</p>
            ) : (
              <p>${order.subtotal}</p>
            )}
          </div>

          <div className="paymentMethod">
            <p>
              <Copy content={ORDERDETAILSTAX} />{' '}
            </p>
            {this.props.isSubtotalCalculated ? (
              <p>${tax.toFixed(2)}</p>
            ) : (
              <p>${order.totalStateTaxAmount}</p>
            )}
          </div>
          <div className="paymentMethod">
            <p>
              <Copy content={ORDERDETAILSSHIPPING} />
            </p>
            <p>${itemShipping.toFixed(2)}</p>
          </div>
          <div className="paymentTotal">
            <p>
              <Copy content={ORDERDETAILSTOTAL} />
            </p>
            {this.props.isSubtotalCalculated ? (
              <p>${total.toFixed(2)}</p>
            ) : (
              <p>${order.totalOrderAmount}</p>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderLogo(cardType) {
    return (
      <div
        className={`payment-info__card-info__logo-wrapper ${cardType
          .toLowerCase()
          .replace(/'|\s/g, '')}`}
      />
    );
  }

  render() {
    const { orderDetails, myAccountContent, isLoading } = this.props;
    const MYORDERSCONFORMATION = get(myAccountContent, 'MYORDERS-CONFIRMATION');
    return !isLoading ? (
      <React.Fragment>
        <div className="titleOrder">
          {MYORDERSCONFORMATION && <Copy content={MYORDERSCONFORMATION} />}
        </div>
        <div>{this.renderOrder(orderDetails)}</div>
      </React.Fragment>
    ) : (
      <Spinner />
    );
  }
}

OrderDetails.propTypes = {
  orderDetails: PropTypes.object,
  myAccountContent: PropTypes.object,
};

const mapStateToProps = state => ({
  orderDetails: get(state, 'myaccount.orderDetails', true),
  cancelOrders: get(state, 'myaccount.cancelOrders', []),
  myAccountContent: get(state, 'myaccount.myAccountContent', true),
  amplifyConfig: get(state, 'amplifyConfig', null),
  isLoading: get(state, 'myaccount.isLoading', true),
  isCancelVisible: state.toggles.ORDER_CANCEL_BUTTON,
  isNewCartCheckoutEnabled: isNewCartCheckoutEnabled(state),
  isSubtotalCalculated: state.toggles.ORDER_DETAILS_SUBTOTAL,
  disableATGToggle: state.toggles.DISABLE_ATG_CALLS,
  cancelOrderV3Toggle: state.toggles.ORDER_CANCEL_V3,
});

const mapDispatchToProps = {
  getOrdersDetails,
  cancelOrder,
  setResolvedPage,
  setPageId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderDetails);
