import get from 'lodash/get';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import logger from 'server-utils/logger';
import { buildCookieString } from 'universal/utilities-cookies';
import httpWithLogging from 'universal/http-client';
import { parseAbTestValue } from 'client-utils/utilities-abtest';
import { ADOBE_QUERY_PARAMS } from 'server/utilities/adobeDebug';
import { types as AppTypes } from '../components/App/app-actions';

export const SET_TITLE = 'SET_TITLE';
export const SET_META_INFO = 'SET_META_INFO';
export const SET_META_INFO_CTP = 'SET_META_INFO_CTP';
export const SET_META_INFO_PLP = 'SET_META_INFO_PLP';
export const SET_PAGE_ID = 'SET_PAGE_ID';
export const SET_CANONICAL_URL = 'SET_CANONICAL_URL';
export const SET_META_DESCRIPTION = 'SET_META_DESCRIPTION';
export const SET_META_TAG = 'SET_META_TAG';
export const SET_TITLE_OVERRIDE = 'SET_TITLE_OVERRIDE';
export const PAGE_ID_HOME = 'PAGE_ID_HOME';
export const PAGE_ID_PDP = 'PAGE_ID_PDP';
export const PAGE_ID_PLP = 'PAGE_ID_PLP';
export const PAGE_ID_SRP = 'PAGE_ID_SRP';
export const PAGE_ID_PLA = 'PAGE_ID_PLA';
export const PAGE_ID_CAT_TMPL = 'PAGE_ID_CAT_TMPL';
export const PAGE_ID_CONF = 'PAGE_ID_CONF';
export const PAGE_ID_WKY = 'PAGE_ID_WKY';
export const PAGE_ID_FAVORITE = 'PAGE_ID_FAVORITE';
export const PAGE_ID_WISHLIST = 'PAGE_ID_WISHLIST';
export const PAGE_ID_FORGOT_PASSWORD = 'PAGE_ID_FORGOT_PASSWORD';
export const PAGE_ID_CHECK_YOUR_EMAIL = 'PAGE_ID_CHECK_YOUR_EMAIL';
export const PAGE_ID_RESET_PASSWORD = 'PAGE_ID_RESET_PASSWORD';
export const PAGE_ID_RESET_PASSWORD_NEW = 'PAGE_ID_RESET_PASSWORD_NEW';
export const PAGE_ID_RESET_PASSWORD_CONFIRMATION =
  'PAGE_ID_RESET_PASSWORD_CONFIRMATION';
export const PAGE_ID_LOGIN = 'PAGE_ID_LOGIN';
export const PAGE_ID_REGISTRATION = 'PAGE_ID_REGISTRATION';
export const PAGE_ID_BG_CREDIT_CARD = 'PAGE_ID_BG_CREDIT_CARD';
export const PAGE_ID_INTL_CART = 'PAGE_ID_INTL_CART';
export const PAGE_ID_PAYMENT_DETAILS = 'PAGE_ID_PAYMENT_DETAILS';
export const PAGE_ID_ACCOUNT_DETAILS = 'PAGE_ID_ACCOUNT_DETAILS';
export const PAGE_ID_MY_ORDERS = 'PAGE_ID_MY_ORDERS';
export const PAGE_ID_ORDER_DETAILS = 'PAGE_ID_ORDER_DETAILS';
export const PAGE_ID_REWARDS = 'PAGE_ID_REWARDS';
export const PAGE_ID_ADDRESS_BOOK = 'PAGE_ID_ADDRESS_BOOK';
export const PAGE_ID_WISHLIST_NEW = 'PAGE_ID_WISHLIST_NEW';
export const PAGE_ID_BG360 = 'PAGE_ID_BG360';

export const PAGE_VISITED = 'PAGE_VISITED';

export const LOADING_ABTESTOPT = 'LOADING_ABTESTOPT';
export const RESOLVED_ABTESTOPT = 'RESOLVED_ABTESTOPT';
export const REJECTED_ABTESTOPT = 'REJECTED_ABTESTOPT';

export const LOADING_ABTEST = 'LOADING_ABTEST';
export const RESOLVED_ABTEST = 'RESOLVED_ABTEST';
export const REJECTED_ABTEST = 'REJECTED_ABTEST';
export const RESET_ABTEST = 'RESET_ABTEST';

export const LOADING_ABTESTS = 'LOADING_ABTESTS';
export const RESOLVED_ABTESTS = 'RESOLVED_ABTESTS';
export const REJECTED_ABTESTS = 'REJECTED_ABTESTS';

export const SET_TEST_FROM_COOKIE = 'SET_TEST_FROM_COOKIE';
export const SET_TEST_FROM_RESPONSE = 'SET_TEST_FROM_RESPONSE';

export const NARVAR_MBOX_ID = 'NarvarDT';
export const EVERGAGE_MBOX_ID = 'Evergage';
export const BAZAAR_VOICE_MBOX_ID = 'BazaarVoice';
export const FACETED_LEFT_NAV_MBOX_ID = 'FacetedLeftNav';
export const HYBRID_FACETS_MBOX_ID = 'HybridFacets';
export const FETCH_PAGE_SIZE_MBOX_ID = 'MobileSRPFetchSize';
export const CP_PERSONALIZATION_MBOX_ID = 'cppersonalization';
export const NEW_MOBILE_NAV_MBOX_ID = 'NewMobileNavigation';
export const AEM_ABTEST_USERGROUP_MBOX_ID = 'marchMysterySavings';
export const NEW_ASPECT_RATIO_MBOX_ID = 'NEW_ASPECT_RATIO';
export const TOPNAV_TEST_MBOX_ID = 'TopNavABTest2019';
export const EXPOSE_MOBILE_SEARCH_BAR_MBOX_ID = 'EXPOSE_MOBILE_SEARCH_BAR';
export const PRODUCT_SIMPLIFICATION_MBOX_ID = 'PRODUCT_SIMPLIFICATION';
export const PDP_YMAL_AB_TEST = 'bgpdpz0001';
export const MOBILE_WEB_PDP_REDESIN = 'bpdp1';
export const BGFPS = 'bg1';

export const HP_SLOTS_MBOX_ID = [
  'homepageSlot1',
  'homepageSlot2',
  'homepageSlot3',
];
export const HP_CAROUSEL_SLIDES_MBOX_ID = [
  'hpCarouselSlide1',
  'hpCarouselSlide2',
  'hpCarouselSlide3',
];

export const DOM_LOAD_COMPLETE = 'DOM_LOAD_COMPLETE';

export const PLP_PCS_RECOMMEDATION_MBOX_ID = 'ProductCategorySort';

export const EVENING_WEAR_MBOX_ID = 'EveningWearReturnFee';
export const FACETED_LEFT_NAV_TEST = 'facetedLeftNav';
export const HYBRID_FACETS_TEST = 'HybridFacets';
export const PLP_PCS_RECOMMEDATION_TEST = 'pcsCategorySort';
export const FINAL_SALE_MBOX_ID = 'FinalSale';
export const TRUEFIT_GSA_WIDGET = 'TrueFitGSAWidget';
export const TRUEFIT_GSA_WIDGET_OPT_ID = 'tf-gsa';

export const OPTIMIZELY_TEST_OPT_ID = 'plptest';
export const SIMPLIFIED_MINI_CART = 'SimplifiedMiniCart';

export const SHOW_FOCUS_SKIP_TO_MAIN = 'SHOW_FOCUS_SKIP_TO_MAIN';
export const HIDE_FOCUS_SKIP_TO_MAIN = 'HIDE_FOCUS_SKIP_TO_MAIN';
export const PDP_OUTFITTING = 'PDPOutfitting';
export const SET_LSS_ANALYTICS = 'SET_LSS_ANALYTICS';

export function setTitle(title, separator = '') {
  return dispatch => dispatch({ type: SET_TITLE, payload: title, separator });
}

export function setTitleOverride(titleOverride) {
  return dispatch =>
    dispatch({ type: SET_TITLE_OVERRIDE, payload: titleOverride });
}

export function setMetaInfo(metaObj) {
  return dispatch => dispatch({ type: SET_META_INFO, payload: metaObj });
}

export function setMetaDescription(content = '') {
  const metaDescription = `Get free shipping on ${content} styles at Bergdorf Goodman. Shop the latest luxury fashions from top designers.`;
  return dispatch =>
    dispatch({ type: SET_META_DESCRIPTION, payload: { metaDescription } });
}

export function setMetaDescriptionCategory(categoryName = '') {
  const metaDescription = `Shop ${categoryName} at Bergdorf Goodman, where you will find free shipping on the latest in fashion from top designers.`;
  return dispatch =>
    dispatch({ type: SET_META_DESCRIPTION, payload: { metaDescription } });
}

export function setFullMetaDescription(content = '') {
  const metaDescription = `${content}`;
  return dispatch =>
    dispatch({ type: SET_META_DESCRIPTION, payload: { metaDescription } });
}

export function setMetaTags(content = []) {
  return dispatch => dispatch({ type: SET_META_TAG, payload: content });
}

export function setMetaInfoWithHTMLMetaTag(content = '') {
  return dispatch =>
    dispatch({
      type: SET_META_INFO_PLP,
      payload: {
        ctpMetaInfo: content,
      },
    });
}

export function setPageId(pageId) {
  return dispatch => dispatch({ type: SET_PAGE_ID, payload: pageId });
}

export function setPageVisited(payload) {
  return dispatch => dispatch({ type: PAGE_VISITED, page_data: payload });
}

export function domLoadCompleted() {
  return dispatch => dispatch({ type: DOM_LOAD_COMPLETE });
}

export function referralSourceClicked(source, linkType) {
  const nmObj = window.nm;
  if (
    nmObj &&
    nmObj.om &&
    nmObj.om.appendCookie &&
    nmObj.omPreviousPageCookieName
  ) {
    const prevLink = JSON.stringify({
      prev_page_link: source,
      prev_link_type: linkType,
    });
    nmObj.om.appendCookie(nmObj.omPreviousPageCookieName, prevLink, 0, '/');
  } else {
    logger.error('Could not set prev_page_link');
  }
}

export const returnAdobeTargetQuery = (state = {}) => {
  const returnObj = {};
  ADOBE_QUERY_PARAMS.forEach(param => {
    if (state[param]) {
      returnObj[param] = state[param];
    }
  });
  const akamaiEdgescape = state.akamaiEdgescape || {};
  if (akamaiEdgescape.country_code)
    returnObj.country = akamaiEdgescape.country_code;
  if (akamaiEdgescape.city) returnObj.city = akamaiEdgescape.city;
  if (akamaiEdgescape.zip) returnObj.zip = akamaiEdgescape.zip;
  if (akamaiEdgescape.continent)
    returnObj.continent = akamaiEdgescape.continent;
  if (akamaiEdgescape.region_code)
    returnObj.state = akamaiEdgescape.region_code;
  return returnObj;
};

export const returnOptimizelyQuery = (state = {}) => {
  const returnObj = {};
  const akamaiEdgescape = state.akamaiEdgescape || {};
  const UserAgentParams = [
    'isMobile',
    'isTablet',
    'isMobilePhone',
    'isDesktop',
    'browser',
    'version',
    'referer',
  ];
  UserAgentParams.forEach(param => {
    if (state[param]) {
      returnObj[param] = state[param];
    }
  });
  if (akamaiEdgescape.country_code)
    returnObj.country = akamaiEdgescape.country_code;
  if (akamaiEdgescape.city) returnObj.city = akamaiEdgescape.city;
  if (akamaiEdgescape.zip) returnObj.zip = akamaiEdgescape.zip;
  if (akamaiEdgescape.continent)
    returnObj.continent = akamaiEdgescape.continent;
  if (akamaiEdgescape.region_code)
    returnObj.state = akamaiEdgescape.region_code;
  if (state['_optuid']) returnObj.optuid = state['_optuid'];
  return returnObj;
};

export const resolveOptOptions = (opttids = '', forceOpty = '') => {
  if (!forceOpty) return opttids;

  const optVariations = forceOpty.split(',');
  const variations = [];
  for (let x = 0; x < optVariations.length; x++) {
    const variation = optVariations[x];
    const variationArr = variation.split(':');
    if (variationArr.length === 2) {
      variations.push({
        experiment: variationArr[0],
        variation: variationArr[1],
      });
    }
  }

  const optExperiments = opttids.split(',');
  for (let y = 0; y < optExperiments.length; y++) {
    const experiment = optExperiments[y];
    const forcedVariation = find(variations, { experiment });
    if (forcedVariation)
      optExperiments[
        y
      ] = `${forcedVariation.experiment}:${forcedVariation.variation}`;
  }

  return optExperiments.join(',');
};

export function logABTestAssignments(getState, testNames = '') {
  const abTestResponses = get(getState(), 'abTests.responses', []);
  testNames.split(',').forEach(mboxId => {
    const testDetail = find(abTestResponses, { mboxId });
    const id = get(testDetail, 'assignment.tntVal', '');
    const exp = get(testDetail, 'assignment.value.experienceId', '');
    logger.info('abtest batch', {
      platform: 'Target',
      testName: mboxId,
      testId: id,
      experience: exp,
    });
  });
}

export function logOptABTestAssignments(optTests = {}) {
  for (const key of Object.keys(optTests)) {
    logger.info('abtest batch', {
      platform: 'Optimizely',
      testName: key,
      testId: key,
      experience: optTests[key].variation,
    });
  }
}

export function logABTestExperiences(testArray = []) {
  testArray.forEach(test => {
    if (test.abTestName) {
      logger.info('abtest batch', {
        platform: 'Internal',
        testName: test.abTestName,
        testId: test.abTestId,
        experience: `${test.abTestExperience}:${test.abTestCookieValue}`,
      });
    }
  });
}

export function getABTestAssignments(mboxIdsIn, contentAsJson = false) {
  return (dispatch, getState) => {
    const state = getState();
    if (get(state, 'device.isBOT', '') === '1') {
      logger.debug('Ignoring bot request for abtests');
      dispatch({ type: REJECTED_ABTEST });
      return new Promise(resolve => {
        return resolve();
      });
    }
    const { session = {}, toggles = {} } = state;

    let mboxIds = mboxIdsIn;
    if (toggles.MOVE_ABTESTS_TO_APP) {
      const mBoxIdsFromParam = mboxIds.split(',');
      const mBoxIdsToService = [];
      const abTestResponses = get(state, 'abTests.responses', []);

      for (let i = 0; i < mBoxIdsFromParam.length; i++) {
        const mBoxId = find(abTestResponses, { mboxId: mBoxIdsFromParam[i] });
        !mBoxId && mBoxIdsToService.push(mBoxIdsFromParam[i]);
      }
      if (!mBoxIdsToService.length) {
        return new Promise(resolve => {
          return resolve();
        });
      }
      mboxIds = mBoxIdsToService.join(',');
    }

    dispatch({ type: LOADING_ABTEST });
    const { api = {} } = state;
    const { requestContext = {} } = api;
    const { device = {} } = state;
    const { mboxData } = state.abTestSession;
    let mboxValues = [];
    if (mboxData) {
      mboxValues = mboxData.split('|');
    }
    let adobeSessionId = '';
    let adobePCId = '';
    mboxValues.forEach(item => {
      if ((item || '').includes('session')) adobeSessionId = item.split('#')[1];
      if ((item || '').includes('PC')) adobePCId = item.split('#')[1];
    });
    const additionalHeadersForTarget = returnAdobeTargetQuery({
      ...session,
      ...requestContext,
      ...device,
    });
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
      }),
      ...additionalHeadersForTarget,
      pageUrl: get(session, 'url', ''),
      adobeSessionId,
      userAgent: get(api, 'requestContext.USER_AGENT', ''),
      contentAsJson,
    };
    const ABTEST_API_TIMEOUT = get(state, 'apiTimeouts.ABTEST_API_TIMEOUT');
    const requestApi = httpWithLogging(state, ABTEST_API_TIMEOUT);
    let reqString;
    if (adobePCId) {
      reqString = `${NMConfig.API_ABTEST}?mboxIds=${mboxIds}&tntId=${adobePCId}`;
    } else {
      reqString = `${NMConfig.API_ABTEST}?mboxIds=${mboxIds}`;
    }

    return requestApi
      .get(reqString, { headers })
      .then(successResponse => {
        if (successResponse.data.status !== 'success') {
          return dispatch({ type: REJECTED_ABTEST });
        }
        forEach(successResponse.data.assignments, item => {
          const info = {
            ...headers,
          };
          if (item.mboxId === FETCH_PAGE_SIZE_MBOX_ID) {
            if (get(item, 'assignment.value.experienceId') === 'FetchSizeOn') {
              logger.info('SRP ABTest Activated', {
                ...info,
                mobileFetchSize: get(state, 'FETCH_PAGE_SIZE_MBOX_ID', null),
              });
            }
          }
        });
        if (
          get(state, 'toggles.ABTEST_ADD_MBOX', false) &&
          successResponse.data.mboxCookieValue
        ) {
          dispatch({
            type: AppTypes.AB_TEST_SESSION_DATA_RECEIVED,
            mboxCookie: successResponse.data.mboxCookieValue,
          });
        }
        return dispatch({
          type: RESOLVED_ABTEST,
          payload: successResponse.data.assignments,
        });
      })
      .then(response => {
        logABTestAssignments(getState, mboxIds);
        return response;
      })
      .catch(e => {
        logger.error('Exception in abTest Call:', e);
        return dispatch({ type: REJECTED_ABTEST });
      });
  };
}

export function getOptABTestAssignments(opttids) {
  return (dispatch, getState) => {
    const state = getState();
    if (get(state, 'device.isBOT', '') === '1') {
      dispatch({ type: REJECTED_ABTESTOPT });
      return new Promise(resolve => {
        return resolve();
      });
    }
    dispatch({ type: LOADING_ABTESTOPT });
    const { api = {}, session = {}, page = {} } = state;
    const { requestContext = {} } = api;
    const { device = {} } = state;
    const additionalHeadersForOptimizely = returnOptimizelyQuery({
      ...session,
      ...requestContext,
      ...device,
    });
    const optParam = resolveOptOptions(
      opttids,
      get(page, 'location.query.force-opty', ''),
    );
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
      }),
      ...additionalHeadersForOptimizely,
      pageUrl: get(session, 'url', ''),
      userAgent: get(api, 'requestContext.USER_AGENT', ''),
      opttid: optParam,
    };
    const ABTESTOPT_API_TIMEOUT = get(
      state,
      'apiTimeouts.ABTESTOPT_API_TIMEOUT',
    );
    const requestApi = httpWithLogging(state, ABTESTOPT_API_TIMEOUT);
    return requestApi
      .get(NMConfig.API_ABTEST_OPTIMIZELY, { headers })
      .then(successResponse => {
        return dispatch({
          type: RESOLVED_ABTESTOPT,
          payload: successResponse.data,
        });
      })
      .then(response => {
        logOptABTestAssignments(response.payload);
        return response;
      })
      .catch(e => {
        logger.error('Exception in optimizely abTest call:', e);
        return dispatch({ type: REJECTED_ABTESTOPT });
      });
  };
}

export function getABTests(testIds = []) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_ABTESTS });
    const state = getState();
    const { session } = state;
    const { api } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    if (get(api, 'requestContext.TRUE_CLIENT_IP', '')) {
      headers.TRUE_CLIENT_IP = get(api, 'requestContext.TRUE_CLIENT_IP', '');
    }
    const testIdsParam = testIds.map(
      testId => `${testId.abTestName}:${testId.abTestId}`,
    );
    headers.abTestIds = testIdsParam.join(',');
    const ABTEST_API_TIMEOUT = get(state, 'apiTimeouts.ABTEST_API_TIMEOUT');
    const requestApi = httpWithLogging(state, ABTEST_API_TIMEOUT);
    const requestUrl = NMConfig.API_ABTEST_EXPERIENCES;

    return requestApi
      .get(requestUrl, { headers })
      .then(successResponse => {
        parseAbTestValue(testIds, successResponse.data);
        for (let i = 0; i < testIds.length; i++) {
          if (testIds[i].abTestCookieValue) {
            dispatch({ type: SET_TEST_FROM_RESPONSE, payload: testIds[i] });
          }
        }
        return dispatch({ type: RESOLVED_ABTESTS });
      })
      .then(() => {
        logABTestExperiences(testIds);
      })
      .catch(e => {
        logger.error('Exception in internal abTest Call:', e);
        return dispatch({ type: REJECTED_ABTESTS });
      });
  };
}
// eslint-disable-next-line no-unused-vars
export function focusSkipToMainComponent(e) {
  window.scrollTo(0, 0);

  const skipToMainContentElement = document.getElementById('skipToMainContent');

  if (skipToMainContentElement != null) {
    skipToMainContentElement.focus();
    return dispatch =>
      dispatch({
        type: SHOW_FOCUS_SKIP_TO_MAIN,
        payload: { focusSkipToMain: true },
      });
  } else {
    return dispatch =>
      dispatch({
        type: HIDE_FOCUS_SKIP_TO_MAIN,
        payload: { focusSkipToMain: false },
      });
  }
}

export function hideSkipToMainComponent() {
  return dispatch =>
    dispatch({
      type: HIDE_FOCUS_SKIP_TO_MAIN,
      payload: { focusSkipToMain: false },
    });
}

export function setLSSAnalytics(viewType) {
  return dispatch =>
    dispatch({
      type: SET_LSS_ANALYTICS,
      payload: {
        grid_view: '',
        product_view: (viewType === 'N/A' ? '' : viewType).toLowerCase(),
      },
    });
}

export function isMobileWebPdpRedesign(abTestIds) {
  let isMobileWebRedesign = false;
  if (abTestIds) {
    Object.keys(abTestIds).forEach(test => {
      const { variation, analytics } = abTestIds[test];
      if (
        variation &&
        analytics &&
        test === MOBILE_WEB_PDP_REDESIN &&
        variation === 'b'
      ) {
        isMobileWebRedesign = true;
      }
    });
  }
  return isMobileWebRedesign;
}
