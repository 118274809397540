export default function formatDateTime(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Month is zero-based
  const year = date
    .getFullYear()
    .toString()
    .slice(2); // Get last two digits of the year
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format

  const formattedDate = `${month}/${day}/${year} ${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')} ${period}`;

  return formattedDate;
}

export const LoyaltyTierMap = {
  CIRCLE_1: 'Circle One',
  CIRCLE_2: 'Circle Two',
  CIRCLE_3: 'Circle Three',
  CIRCLE_4: 'Circle Four',
  CIRCLE_5: 'Circle Five',
  CIRCLE_6: 'Circle Six',
  PRESIDENT: 'President',
  CHAIRMAN: 'Chairman',
};

// Function to check if a given date is todays date
export const isToday = date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const inputDate = new Date(date);
  inputDate.setHours(0, 0, 0, 0);
  return inputDate.getTime() === today.getTime();
};
