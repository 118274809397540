import { useCallback } from 'react';
import reactCookie from 'react-cookie';
import { useHttp } from './http.hook';
import { APPLE_PAY_SESSION_STORAGE } from '../ApplePay.utils';

export const useApplePayButtonClick = ({
  appleUserData,
  applePayAuthorized,
  productPrice,
  productDetails,
  getUserData,
  perishableDeliveryByDate,
  sendUtagLinkData,
}) => {
  const sessionDataTemp = {
    supportedNetworks: ['amex', 'discover', 'masterCard', 'visa'],
    merchantCapabilities: ['supports3DS'],
    requiredShippingContactFields: ['postalAddress', 'email', 'phone'],
    requiredBillingContactFields: ['postalAddress'],
    currencyCode: 'USD',
    countryCode: 'US',
    lineItems: [
      {
        amount: '0.00',
        label: 'Order Subtotal',
      },
      {
        amount: '0.00',
        label: 'Sales Tax',
      },
      {
        amount: '0.00',
        label: 'Shipping',
      },
      {
        amount: '0.00',
        label: 'Discount',
      },
    ],
    total: {
      amount: `${productPrice}`,
      label: 'Bergdorf Goodman',
    },
  };
  const { request } = useHttp();

  const triggerAddtoCartAnalytics = (product, selectedSku) => {
    if (window.utag_data_dt) {
      window.utag_data_dt = {
        ...window.utag_data_dt,
        buy_now: 'applepay',
        event_name: 'MiniCartResp',
        cart_change_product_name: [product?.name],
        cart_change_product_id: [product?.id],
        cart_change_product_price: [product?.price?.retailPrice],
        cart_change_product_quantity: [product?.quantity],
        cart_change_product_cmos_sku: [selectedSku?.metadata?.cmosSkuId],
        cart_change_product_catalog_id: [product?.metadata?.cmosCatalogId],
        cart_change_product_cmos_item: [product?.metadata?.cmosItem],
        cart_change_nmo_sku_id: [selectedSku?.id],
        cart_change_product_replenish_time: ['0'],
        cart_total_items: window?.utag_data_dt?.cart_total_items + 1,
      };
      sendUtagLinkData({ ...window.utag_data_dt });
    }
  };

  const onApplePayButtonClick = useCallback(
    selectedSku => {
      let headers = {
        accept: 'application/json',
        authorization: appleUserData.Authorization,
        sourceapp: 'WB',
        userid: appleUserData.userId,
      };
      let dt_itemId = '';
      // eslint-disable-next-line no-undef
      const session = new ApplePaySession(1, sessionDataTemp);
      session.onvalidatemerchant = async event => {
        try {
          const { id: productId, quantity } = productDetails;
          const { id: skuId } = selectedSku;
          // get Authorization token & user id
          const userData = await getUserData().then(userData => userData);
          if (userData) {
            headers = {
              accept: 'application/json',
              authorization: `Bearer ${userData.idToken}`,
              sourceapp: 'WB',
              userid: userData.ucaId,
            };
            const validateMerchant = await request(
              `/checkout/applepay/merchant`,
              'POST',
              {
                applePayValidationUrl: event.validationURL,
                productId,
                skuId,
                quantity,
                perishableDeliveryByDate,
              },
              headers,
            );
            dt_itemId = validateMerchant?.dtItemId;
            triggerAddtoCartAnalytics(productDetails, selectedSku);
            session.completeMerchantValidation(validateMerchant.data);
          }
        } catch (error) {
          console.warn(error);
        }
      };

      session.onshippingcontactselected = async event => {
        try {
          const quantity = window.sessionStorage.getItem('updatedQuantity');
          const data = {
            ...event.shippingContact,
            selectedItems: [
              {
                id: dt_itemId,
                quantity: quantity || 1,
              },
            ],
          };
          const shippingContactSelected = await request(
            '/cart/applepay/shipping-contact',
            'POST',
            data,
            headers,
          );

          session.completeShippingContactSelection(
            session.STATUS_SUCCESS,
            shippingContactSelected.shippingOption,
            shippingContactSelected.newTotal,
            shippingContactSelected.newLineItems,
          );
        } catch (error) {
          session.completeShippingContactSelection(
            session.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS,
            null,
            null,
            null,
          );
        }
      };

      session.onshippingmethodselected = async event => {
        window.localStorage.setItem(
          'serviceLevel',
          event.shippingMethod.identifier,
        );

        try {
          const quantity = window.sessionStorage.getItem('updatedQuantity');
          const reData = {
            selectedItems: [
              {
                id: dt_itemId,
                quantity: quantity || 1,
              },
            ],
          };
          const shippingMethodSelected = await request(
            `/cart/applepay/shipping-method?serviceLevel=${event.shippingMethod.identifier}`,
            'POST',
            reData,
            headers,
          );
          session.completeShippingMethodSelection(
            session.STATUS_SUCCESS,
            shippingMethodSelected.newTotal,
            shippingMethodSelected.newLineItems,
          );
        } catch (error) {
          session.completeShippingMethodSelection(
            session.STATUS_FAILURE,
            null,
            null,
          );
        }
      };

      session.onpaymentauthorized = async event => {
        try {
          const walletPaymentInfo = await request(
            '/cart/payments/walletPaymentInfo',
            'POST',
            {
              walletIdentifier: 'ApplePay',
              walletToken: '',
              paymentToken: event.payment,
            },
            headers,
          );
          if (walletPaymentInfo.responseCode === '00000') {
            session.completePayment(session.STATUS_SUCCESS);
            APPLE_PAY_SESSION_STORAGE.paymentEvent.set(event.payment);
            const quantity = window.sessionStorage.getItem('updatedQuantity');
            const nmcheckoutomValue = reactCookie.load('nmcheckoutom');
            const qty =
              nmcheckoutomValue === 'MAO' ? Number(quantity) : quantity;
            window?.sessionStorage?.setItem(
              'ApplePayPDP',
              JSON.stringify({
                id: dt_itemId,
                quantity: qty || 1,
              }),
            );
            applePayAuthorized();
            window.location.href = '/checkout/';
          } else {
            session.completePayment(session.STATUS_FAILURE);
          }
          window.sessionStorage.removeItem('updatedQuantity');
        } catch (error) {
          session.completePayment(session.STATUS_FAILURE);
        }
      };

      session.oncancel = async () => {
        try {
          window.sessionStorage.removeItem('updatedQuantity');
        } catch (error) {
          console.warn(error);
        }
      };

      session.begin();
    },
    [request, appleUserData.userId, appleUserData.Authorization],
  );

  return { onApplePayButtonClick };
};
