import { LOCATION_CHANGE } from 'react-router-redux';
import {
  getTitle,
  getPDPPageTitle,
  getMetaDescriptionPDP,
  getMetaDescriptionGroupPDP,
  getCanonicalUrlPDP,
  getMetaInfoPDP,
  getMetaTitle,
  getMetaDescription,
  getMetaKeywords,
  getMetaTags,
  getRelLinks,
  normalizeCanonicalUrl,
  stripEncodedHtml,
  escapeObject,
} from 'client-utils/utilities-page';
import {
  RESOLVED_PRODUCT,
  RESET_PRODUCT,
  RESOLVED_GROUP,
} from 'pdp/components/ProductPage/actions';
import { types } from 'client/common/components/App/app-actions';
import {
  SET_TITLE,
  SET_META_INFO,
  SET_META_INFO_CTP,
  SET_PAGE_ID,
  SET_CANONICAL_URL,
  DOM_LOAD_COMPLETE,
  SET_META_DESCRIPTION,
  SET_META_TAG,
  SET_META_INFO_PLP,
  SET_TITLE_OVERRIDE,
  SHOW_FOCUS_SKIP_TO_MAIN,
  HIDE_FOCUS_SKIP_TO_MAIN,
} from '../actions/actions-page';

const initialState = {
  title: 'Bergdorf Goodman',
  canonical: '',
  metaDescription: '',
  metaKeywords: '',
  metaTags: [],
  relLinks: [],
  metaSEOOverride: '',
  location: {
    query: {},
  },
  isDomLoadComplete: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOM_LOAD_COMPLETE: {
      return {
        ...state,
        isDomLoadComplete: true,
      };
    }
    case LOCATION_CHANGE: {
      return {
        ...state,
        location: {
          ...action.payload,
        },
      };
    }
    case types.REQUEST_RECEIVED: {
      const { request } = action.payload;
      return {
        ...state,
        location: {
          pathname: stripEncodedHtml(request.path),
          query: escapeObject(request.query), // sanitize request queries
        },
      };
    }
    case SET_TITLE: {
      return {
        ...state,
        title: getTitle(action.payload, action.separator),
      };
    }
    case SET_TITLE_OVERRIDE: {
      return {
        ...state,
        title: action.payload,
      };
    }
    case RESOLVED_GROUP:
      return {
        ...state,
        title: getPDPPageTitle(action.payload),
        metaDescription: getMetaDescriptionGroupPDP(action.payload),
        canonical: getCanonicalUrlPDP(action.payload),
        metaSEOOverride: getMetaInfoPDP(action.payload),
      };
    case RESOLVED_PRODUCT:
      return {
        ...state,
        title: getPDPPageTitle(action.payload),
        metaDescription: getMetaDescriptionPDP(action.payload),
        canonical: getCanonicalUrlPDP(action.payload),
        metaSEOOverride: getMetaInfoPDP(action.payload),
      };
    case RESET_PRODUCT:
      return { ...state, ...initialState };
    case SET_META_INFO:
      return {
        ...state,
        title: getMetaTitle(action.payload),
        metaDescription: getMetaDescription(action.payload),
        metaKeywords: getMetaKeywords(action.payload),
        metaTags: getMetaTags(action.payload),
        relLinks: getRelLinks(action.payload),
        canonical: normalizeCanonicalUrl(action.payload.canonicalUrl),
      };
    case SET_META_INFO_CTP:
      return {
        ...state,
        ctpMetaInfo: action.payload.ctpMetaInfo,
        title: action.payload.metaTitle,
        metaDescription: getMetaDescription(action.payload),
        canonical: normalizeCanonicalUrl(action.payload.canonicalUrl),
      };
    case SET_META_INFO_PLP:
      return {
        ...state,
        ctpMetaInfo: action.payload.ctpMetaInfo,
      };
    case SET_PAGE_ID:
      return {
        ...state,
        pageId: action.payload,
      };
    case SET_CANONICAL_URL:
      return {
        ...state,
        canonical: normalizeCanonicalUrl(action.canonicalUrl),
      };
    case SET_META_DESCRIPTION:
      return {
        ...state,
        ...action.payload,
      };
    case SET_META_TAG:
      return {
        ...state,
        metaTags: action.payload,
      };
    case SHOW_FOCUS_SKIP_TO_MAIN:
      return {
        ...state,
        ...action.payload,
      };
    case HIDE_FOCUS_SKIP_TO_MAIN:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
