import get from 'lodash/get';
import httpWithLogging from 'universal/http-client';
import {
  hideSpinner,
  showSpinner,
} from 'shared/components/Spinner/spinner-actions';
import { openModal, closeModal } from 'shared/components/Modal/actions';
import { getIdToken } from 'client-utils/utilities-amplify';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import {
  LOADING_ADDRESS,
  RESOLVED_ADDRESS,
  REJECTED_ADDRESS,
  SET_ADDRESS_ID,
  LOADING_UPDATE_ADDRESS,
  RESOLVED_UPDATE_ADDRESS,
  REJECTED_UPDATE_ADDRESS,
  LOADING_ADD_ADDRESS,
  RESOLVED_ADD_ADDRESS,
  REJECTED_ADD_ADDRESS,
  CTA_MODAL_TYPE,
  DAB_MODAL_TYPE,
} from '../../constants';

export const openAddressModal = (type, styleName, editDetails) => {
  return dispatch =>
    dispatch(
      openModal({
        type,
        props: { editDetails },
        className: styleName,
      }),
    );
};

export const closeAddressModal = type => {
  return dispatch =>
    dispatch(
      closeModal({
        type,
      }),
    );
};

export function getAddressForMyAccount(afterUpdate = false) {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { session, amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 6000);
    if (afterUpdate) {
      dispatch(showSpinner());
    }
    dispatch({ type: LOADING_ADDRESS });
    getIdToken(amplifyConfig, isNewCartCheckoutEnabled(state), disableATGToggle)
      .then(tokenInfo => {
        const ucaProfileId = tokenInfo?.user?.sub;
        const headers = {
          accountId: disableATGToggle
            ? ucaProfileId
            : get(session, 'DYN_USER_ID', ''),
          authorization: tokenInfo.idToken,
        };
        const callString = disableATGToggle
          ? `${NMConfig.API_MY_PROFILE_ADDRESS}`
          : `${NMConfig.API_MY_ACCOUNT_ADDRESS}`;
        return requestApi.get(callString, { headers });
      })
      .then(res => {
        if (res && res.data) {
          dispatch({
            type: RESOLVED_ADDRESS,
            payload: res.data,
          });
        } else {
          dispatch({ type: REJECTED_ADDRESS });
        }
      })
      .catch(() => dispatch({ type: REJECTED_ADDRESS }))
      .finally(() => {
        if (afterUpdate) {
          dispatch(hideSpinner());
        }
      });
  };
}

export function addMyAccountAddress(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const { session, amplifyConfig } = state;
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const accountId = get(session, 'DYN_USER_ID', '');
    const requestApi = httpWithLogging(state, 20000);
    dispatch(showSpinner());
    dispatch({ type: LOADING_ADD_ADDRESS });
    getIdToken(amplifyConfig, isNewCartCheckoutEnabled(state), disableATGToggle)
      .then(tokenInfo => {
        const ucaProfileId = tokenInfo?.user?.sub;
        const webProfileId =
          tokenInfo?.user?.preferred_username || ucaProfileId;
        const headers = {
          accountId: disableATGToggle ? ucaProfileId : accountId,
          authorization: tokenInfo.idToken,
        };
        const body = {
          ...payload,
          accountId: disableATGToggle ? webProfileId : accountId,
        };
        const callString = disableATGToggle
          ? `${NMConfig.API_MY_PROFILE_ADDRESS}`
          : `${NMConfig.API_MY_ACCOUNT_ADDRESS}`;
        return requestApi.post(callString, body, { headers });
      })
      .then(res => {
        if (res && res.data) {
          dispatch({
            type: RESOLVED_ADD_ADDRESS,
            payload: res.data,
          });
        } else {
          dispatch({ type: REJECTED_ADD_ADDRESS });
        }
      })
      .catch(() => dispatch({ type: REJECTED_ADD_ADDRESS }))
      .finally(() => dispatch(hideSpinner()));
  };
}

export function editMyAccountAddress(
  payload,
  defaultAddressId,
  defaultDelete = false,
) {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { session, myaccount, amplifyConfig } = state;
    const addressId = defaultAddressId || get(myaccount, 'addressId', '');
    const accountId = get(session, 'DYN_USER_ID', '');
    const requestApi = httpWithLogging(state, 20000);
    dispatch(closeAddressModal(CTA_MODAL_TYPE));
    dispatch(showSpinner());
    dispatch({ type: LOADING_UPDATE_ADDRESS });
    getIdToken(amplifyConfig, isNewCartCheckoutEnabled(state), disableATGToggle)
      .then(tokenInfo => {
        const ucaProfileId = tokenInfo?.user?.sub;
        const webProfileId =
          tokenInfo?.user?.preferred_username || ucaProfileId;
        const headers = {
          accountId: disableATGToggle ? ucaProfileId : accountId,
          addressId,
          authorization: tokenInfo.idToken,
        };
        const body = {
          ...payload,
          accountId: disableATGToggle ? webProfileId : accountId,
          addressId,
        };
        const callString = disableATGToggle
          ? `${NMConfig.API_MY_PROFILE_ADDRESS}`
          : `${NMConfig.API_MY_ACCOUNT_ADDRESS}`;
        return requestApi.put(callString, body, { headers });
      })
      .then(res => {
        if (res && res.status === 200) {
          if (defaultDelete) {
            dispatch(deleteMyAccountAddress());
          } else {
            dispatch(getAddressForMyAccount(true));
          }
          dispatch({ type: RESOLVED_UPDATE_ADDRESS });
        } else {
          dispatch({ type: REJECTED_UPDATE_ADDRESS });
        }
      })
      .catch(err => {
        dispatch(
          openModal({
            type: 'ModalWithoutHeader',
            message: err.response.statusText,
            className: 'modal-without-header',
          }),
        );
      })
      .finally(() => dispatch(hideSpinner()));
  };
}

export function deleteMyAccountAddress() {
  return (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { session, myaccount, amplifyConfig } = state;
    const requestApi = httpWithLogging(state, 8000);
    dispatch(closeAddressModal(DAB_MODAL_TYPE));
    dispatch(showSpinner());
    dispatch({ type: LOADING_UPDATE_ADDRESS });
    getIdToken(amplifyConfig, isNewCartCheckoutEnabled(state), disableATGToggle)
      .then(tokenInfo => {
        const ucaProfileId = tokenInfo?.user?.sub;
        const headers = {
          addressId: get(myaccount, 'addressId', ''),
          accountId: disableATGToggle
            ? ucaProfileId
            : get(session, 'DYN_USER_ID', ''),
          authorization: tokenInfo.idToken,
        };
        const callString = disableATGToggle
          ? `${NMConfig.API_MY_PROFILE_ADDRESS}`
          : `${NMConfig.API_MY_ACCOUNT_ADDRESS}`;
        return requestApi.delete(callString, { headers });
      })
      .then(res => {
        if (res) {
          if (res.status === 200) {
            dispatch(getAddressForMyAccount(true));
            dispatch({ type: RESOLVED_UPDATE_ADDRESS });
          }
        } else {
          dispatch({ type: REJECTED_UPDATE_ADDRESS });
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 400) {
          dispatch(
            openModal({
              type: 'ModalWithoutHeader',
              message: `Default or not existing address can't be removed.`,
              className: 'modal-without-header',
            }),
          );
        } else {
          dispatch(
            openModal({
              type: 'ModalWithoutHeader',
              message: err.response.statusText,
              className: 'modal-without-header',
            }),
          );
        }
      })
      .finally(() => dispatch(hideSpinner()));
  };
}

export function setAddressId(addressId) {
  return dispatch => {
    dispatch({
      type: SET_ADDRESS_ID,
      payload: addressId,
    });
  };
}

export function isPostalValid(payload, callback) {
  return async (dispatch, getState) => {
    const state = getState();
    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const { session, amplifyConfig } = state;
    const accountId = get(session, 'DYN_USER_ID', '');
    const requestApi = httpWithLogging(state, 20000);

    dispatch(showSpinner());
    dispatch({ type: LOADING_ADD_ADDRESS });
    await getIdToken(
      amplifyConfig,
      isNewCartCheckoutEnabled(state),
      disableATGToggle,
    )
      .then(tokenInfo => {
        const headers = { accountId, authorization: tokenInfo.idToken };
        const body = { zipCode: payload.postalCode, state: payload.state };
        const callString = `${NMConfig.API_VALIDATE_ZIPCODE}`;

        return requestApi.post(callString, body, { headers });
      })
      .then(response => {
        if (response && response.data) {
          callback(response.data.isZipCodeValid);
        }
      })
      .catch(error => console.log(error))
      .finally(() => dispatch(hideSpinner()));
  };
}
