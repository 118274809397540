const LoyaltyTierMap = {
  CIRCLE_1: 'Circle One',
  CIRCLE_2: 'Circle Two',
  CIRCLE_3: 'Circle Three',
  CIRCLE_4: 'Circle Four',
  CIRCLE_5: 'Circle Five',
  CIRCLE_6: 'Circle Six',
  PRESIDENT: 'President',
  CHAIRMAN: 'Chairman',
};

const LoyaltyTierMapKeys = Object.keys(LoyaltyTierMap);

export const getLoyalityTier = tierCode => {
  if (!tierCode || !LoyaltyTierMapKeys.includes(tierCode)) {
    return 'Circle ';
  }

  return LoyaltyTierMap[tierCode];
};

export const hasLoyalityBenefits = tierCode => {
  if (!tierCode || !LoyaltyTierMapKeys.includes(tierCode)) {
    return false;
  }
  if (tierCode === 'CIRCLE_1') {
    return false;
  }
  return true;
};
