export const ConsulConfigKeys = {
  BOSS_MESSAGE: 'bossMessage',
  INTERNAL_IPS: 'internalIps',
  VENDOR_QTY_RESTRICTIONS_BY_DEPT_CODE: 'vendorQtyRestrictionsByDeptCode',
  API_TIMEOUTS: 'apiTimeouts',
  ONLY_X_LEFT_THRESHOLDS: 'onlyXLeftThresholds',
  ZERO_DOLLAR_PAGE_ALL_BEAUTY_LINK: 'zeroDollarPageAllBeautyLink',
  MAX_PAGE_FOR_ALL: 'maxPageForAll',
  EXPOSE_TOGGLES_ENDPOINT: 'exposeTogglesEndpoint',
  COOKIE_MESSAGE: 'cookieAcceptenceText',
  EU_COUNTRIES: 'europeanUnionCountries',
  AB_TEST_IDS: 'abTestIds',
  SILO_DRAWER_HOVER_INTENT: 'siloDrawerHoverIntent',
  WKY_NOTIFICATION_CARD_TIMEOUT: 'wkyNotificationCardTimeout',
  TOMFORD_PRODUCTS: 'tomfordWatch',
  PRODUCT_PAGE_IMAGE_TIMEOUT: 'productPageImageTimeout',
  PLP_CACHE_CONFIGURATION: 'plpCacheConfiguration',
  MOBILE_CONFIG: 'mobileConfig',
  RETURN_TEXT: 'returnPolicyText',
  DESKTOP_CONFIG: 'desktopConfig',
  OUTFITTING_CONFIGURATION: 'outFittingConfig',
  FACET_DEFAULT_EXPAND: 'facetDeafultExpandList',
  DEFAULT_STORE_VALUES: 'defaultStoreValues',
  PDP_CONFIGURATOR_SETTINGS: 'pdpConfigurator',
  MOBILE_APP_DOWNLOAD_LINK: 'mobileAppDownloadLink',
  MOBILE_APP_UPGRADE_CONFIG: 'mobileAppUpgradeConfig',
  MOBILE_APP_BANNER: 'mobileAppBanner',
  PLP_CONFIGURATOR: 'plpConfigurator',
  CONTENT: 'content',
  CONFIG: 'config',
  EXP_DAYS_FOR_OSP_ASSOCIATE_COOKIE: 'expDaysForOSPAssocCookie',
  BLACKLISTED_CAT_IDS: 'blackListedCatIds',
  BLACKLISTED_TEMPLATES: 'blackListedTemplates',
  BLACKLISTED_TEMPLATES_NO_CONTENT: 'blackListedTemplatesNoContent',
  CONTENTFUL_ACCESS: 'contentfulAccess',
  BOTTOM_BANNER_CATEGORIES: 'bottomBannerCategories',
  AMPLIFY_CONFIG: 'amplifyConfig',
  EMBEDDED_COMPONENT_MAX_AGE: 'embeddedComponentCacheTimeout',
  AFFIRM_CONFIG: 'affirmConfig',
  FREE_SHIPPING_AND_RETURNS_CONFIG: 'freeShippingAndReturnsConfig',
  STYLYZE_CONFIG: 'stylyzeConfig',
  PLP_CUSTOM_H1_TITLE: 'plpCustomH1Titles',
  PDP_SEO_EXCLUDE_DESIGNERS: 'excludePdpSeoDesigners',
  LOYALTY_CHECKBOX_DISABLED_STATE_CODES: 'loyaltyCheckboxDisabledStateCodes',
  LOYALTY_CONFIG: 'loyaltyConfig',
};

export const wkyConsulConfigKeys = {
  WKY_STORE_GEOLOCATION_RADIUS: 'wkyStoreGeoRadius',
};

export const customCookieMapsForLogin = {
  profile_data: 'BGPDATA',
  DYN_USER_ID: 'BGDUID',
  WID: 'BGWID',
};
export const AppNameMapping = {
  c: 'plp',
  p: 'pdp',
  search: 'search',
  'bgo-ui-home': 'home',
};
export const scriptBundleMapping = {
  all: ['client', 'vendor'],
  client: ['client'],
  vendor: ['vendor'],
};

export const EXCLUSIVE_BRANDS = ['chanel', 'celine', 'dior', 'hermes'];
export const DESIGNER_AZ = 'Designers A-Z';
