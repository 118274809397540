export const supportedStatuses = [
  'RN',
  'RR',
  'RT',
  'RW',
  'RX',
  'SR',
  'VS',
  'CX',
  'BO',
];

export const getOrderStateFromCode = (status, statusCode) => {
  const cancelCodes = ['MI', 'BO', 'QU', 'RI', 'FT', 'RP', 'RD', '1000', '1500', '2000'];
  if (statusCode === 1) {
    return cancelCodes.some(r => status.indexOf(r) >= 0);
  }
  return false;
};

export const returnStatus = status => {
  return ['VS', '7000'].some(r => status.indexOf(r) >= 0);
};

export const getDeliveryMethod = status => {
  switch (status) {
    case 'SL0':
      return 'Same day delivery';
    case 'SL1':
      return 'Next Business day';
    case 'SL2':
      return 'Second Business day';
    case 'SL3':
      return 'Free Standard';
    default:
      return '';
  }
};
