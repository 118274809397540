import React, { Fragment, useEffect } from 'react';
import { func, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  PAGE_ID_BG_CREDIT_CARD,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import InCircleLogoBgCreditCard from 'assets/images/InCircleLogoBgCreditCard.svg';
import Spinner from 'shared/components/Spinner/simpleSpinner';
import MyAccountMobileBackHeader from '../../MyAccountPage/MyAccountBackHeader/MyAccountMobileBackHeader';
import WhyInCircle from '../NewBgCreditCardPage/WhyInCircle';
import { getContentForMyAccount, setResolvedPage } from '../../../actions';
import { RESOLVED_BG_CREDIT_CARD_PAGE } from '../../../constants';
import { getTitleText } from '../../../helpers';
import { useConstructor } from '../../../../../utilities/hooks';
import './index.scss';

const BgNonLoyaltyCreditCardPage = ({
  myAccountContent,
  isLoading,
  getContentForMyAccount,
  setPageId,
  setResolvedPage,
}) => {
  const title = getTitleText(
    myAccountContent ? myAccountContent['CREDITCARD-TITLE'] : null,
  );

  useConstructor(() => {
    setPageId(PAGE_ID_BG_CREDIT_CARD);
  });

  useEffect(() => {
    setResolvedPage(RESOLVED_BG_CREDIT_CARD_PAGE);
    getContentForMyAccount();
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <Fragment>
      <MyAccountMobileBackHeader title={title} />
      <div className="bg-non-loyalty-credit-card-page">
        <section className="bg-non-loyalty-credit-card-page__header">
          <img src={InCircleLogoBgCreditCard} alt="InCircle" alr="InCircle" />
        </section>
        <section className="bg-non-loyalty-credit-card-page__content">
          <WhyInCircle isLoggedIn />
        </section>
      </div>
    </Fragment>
  );
};

BgNonLoyaltyCreditCardPage.propTypes = {
  myAccountContent: object.isRequired,
  isLoading: bool.isRequired,
  getContentForMyAccount: func.isRequired,
  setPageId: func.isRequired,
  setResolvedPage: func.isRequired,
};

const mapStateToProps = state => ({
  myAccountContent: get(state, 'myaccount.myAccountContent', true),
  isLoading: state?.api?.content?.loading || false,
});
const mapDispatchToProps = {
  getContentForMyAccount,
  setPageId,
  setResolvedPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BgNonLoyaltyCreditCardPage);
