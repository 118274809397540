import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getLoyaltyProfileId } from '@bgo-ui/common/client/utilities/utilities-loyalty';
import AppWrapper from '../common/app-common';
import AppMyAccountWrapper from './app-my-account-wrapper';
import BgCreditCardPage from '../../myaccount/pages/BgCreditCardPage/BgCreditCardPage';
import NewBgCreditCardPage from '../../myaccount/pages/BgCreditCardPage/NewBgCreditCardPage';
import PublicLoyaltyPage from '../../myaccount/pages/BgCreditCardPage/PublicLoyaltyPage';
import BgNonLoyaltyCreditCardPage from '../../myaccount/pages/BgCreditCardPage/BgNonLoyaltyCreditCardPage';

export function AppBgCreditCardWrapper({
  bgoLoyaltyToggle,
  loyHubToggle,
  user,
  amplifyConfig,
}) {
  const [hasLoyaltyCheckDone, setHasLoyaltyCheckDone] = useState(false);
  const [loyaltyProfileId, setLoyaltyProfileId] = useState('');
  const isLoggedIn = user.name && user.securityStatus === 'Authenticated';

  const isLoyaltyEnabledAccount = async () => {
    if (isLoggedIn && bgoLoyaltyToggle) {
      const id = await getLoyaltyProfileId(user, amplifyConfig);
      setLoyaltyProfileId(id);
    }

    setHasLoyaltyCheckDone(true);
  };

  useEffect(() => {
    isLoyaltyEnabledAccount();
  }, []);

  if (!isLoggedIn && bgoLoyaltyToggle) {
    return (
      <AppWrapper forceFullBleed>
        <PublicLoyaltyPage />
      </AppWrapper>
    );
  }

  if (
    isLoggedIn &&
    bgoLoyaltyToggle &&
    hasLoyaltyCheckDone &&
    !loyaltyProfileId
  ) {
    return (
      <AppMyAccountWrapper>
        <BgNonLoyaltyCreditCardPage />
      </AppMyAccountWrapper>
    );
  }

  return (
    <AppMyAccountWrapper>
      {bgoLoyaltyToggle && loyHubToggle ? (
        <NewBgCreditCardPage />
      ) : (
        <BgCreditCardPage />
      )}
    </AppMyAccountWrapper>
  );
}

const mapStateToProps = state => ({
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  loyHubToggle: state?.toggles?.LOY_HUB || false,
  user: state.user,
  amplifyConfig: state.amplifyConfig,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppBgCreditCardWrapper);
